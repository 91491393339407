import React, {Component, useEffect, useState} from "react";

import {AppContext} from "../../AppContext";
import './MachineMap.css'
import {MapContainer, Marker, TileLayer, Popup, useMapEvents, useMap} from "react-leaflet";
import {Button, Card} from "react-bootstrap";
import axios from "axios";
import DbUser from "../../queries/DbUser";
import {Redirect} from "react-router-dom";
import {GeoSearchControl, OpenStreetMapProvider} from "leaflet-geosearch";
import "leaflet-geosearch/dist/geosearch.css";
import L from "leaflet";



class MachineMap extends Component {

    static contextType = AppContext;

    state = {
        deviceId: this.props.device.id,
        isEditLocationState: false,
        machine: {
            gpsLatitude: 0,
            gpsLongitude: 0,
        },
        message: '',
        showMap: false
    }

    update = (nextState) => {
        this.setState(nextState);
    }

    showMap = () => {
        this.setState({...this.state, showMap: true})
    }

    componentDidMount() {
        DbUser.getLoggedUser(this.context)

        axios.get(`${this.context.hostname}/api/device/findById/${this.props.device.id}`, {
            headers: {"X-Requested-With": "XMLHttpRequest"},
            withCredentials: true,
        }).then(res => {
            if (res.status = 200) {
                let lat=res.data.gpsLatitude;
                let lng=res.data.gpsLongitude
                if (res.data.gpsLatitude==null){lat=0}
                if (res.data.gpsLongitude==null){lng=0}

                this.setState({
                    ...this.state,
                    machine: {
                        ...res.data,
                        gpsLatitude: lat,
                        gpsLongitude: lng,
                    },
                })
            }
        })
            .catch((error) => {
                console.log(error)
            });
    }



    handleOnClickEditLocation = e => {
        if (this.state.isEditLocationState) {
            axios.patch(`${this.context.hostname}/api/device/updateGpsLocation`, {
                    headers: {
                        "X-Requested-With": "XMLHttpRequest",
                        "Content-Type": "application/json",
                    },
                    "deviceId": this.state.deviceId,
                    "latitude": this.state.machine.gpsLatitude,
                    "longitude": this.state.machine.gpsLongitude
                },
                {withCredentials: true}
            ).then(res => {
                if (res.status === 200) {
                    this.setState({
                        ...this.state,
                        isEditLocationState: !this.state.isEditLocationState,
                        message: "Zapisano"
                    })
                }
            })
                .catch((error) => {

                });
        } else {
            this.setState({
                ...this.state,
                isEditLocationState: !this.state.isEditLocationState,
                message: "Wybierz lokalizację na mapie..."
            })
        }
    }


    render() {

        return (
            <>
                {this.context.redirectToLoginPage ? <Redirect to="/login"/> : ''}

                <div style={{width: '100%'}}>
                    <Button
                        style={{marginTop: 10, marginBottom: 10}}
                        variant="outline-secondary"
                        size='sm'
                        className="float-end"
                        onClick={this.handleOnClickEditLocation}>
                        {this.state.isEditLocationState ? "Zapisz" : "Ustal nową lokalizację"}
                    </Button>

                    <Card
                        style={{padding: 3, marginTop: 11, marginBottom: 10, marginRight: 20, border: 'none'}}
                        className="float-end"
                    >{this.state.message}</Card>

                    {
                        this.state.machine.gpsLatitude === 0 || this.state.machine.gpsLongitude === 0 ? <Card
                            style={{padding: 3, marginTop: 11, marginBottom: 10, marginRight: 20}}
                            className="float-start"
                        >brak ustalonej lokalizacji</Card> : <>
                            <Card
                                style={{padding: 3, marginTop: 11, marginBottom: 10, marginRight: 20}}
                                className="float-start"
                            >szerokość: {this.state.machine.gpsLatitude} </Card>
                            <Card
                                style={{padding: 3, marginTop: 11, marginBottom: 10, marginRight: 20}}
                                className="float-start"
                            >długość: {this.state.machine.gpsLongitude}</Card></>}

                </div>

                <Card style={{marginTop: 10, width: '100%'}}>
                    {this.state.showMap ?
                    <MapContainer
                        center={startPosition}
                        zoom={6}
                        scrollWheelZoom={true}
                    >
                        <SearchField/>

                        <TileLayer
                            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                        {this.state.isEditLocationState ?
                            <AddMarkerToClick updateParent={this.update} state={this.state}/>
                            :
                            <>
                                <Marker
                                    position={[this.state.machine.gpsLatitude, this.state.machine.gpsLongitude]}
                                    interactive={false}
                                    icon={machineMarker}
                                />
                            </>}


                    </MapContainer>
                        : false}
                </Card>
            </>


        )
    }
}


export default MachineMap;


const startPosition = [51.055207338584964, 19.1057076459545]


function AddMarkerToClick(props) {
    const [position, setPosition] = useState({latitude: props.state.machine.gpsLatitude, longitude: props.state.machine.gpsLongitude});

    const map = useMapEvents({
        click(event) {
            const {lat, lng} = event.latlng;
            setPosition({
                latitude: lat,
                longitude: lng,
            });
            props.updateParent({...props.state, machine: {gpsLatitude: lat, gpsLongitude: lng}})
        },
    });

    return (
        position.latitude !== 0 ? (
            <Marker
                position={[position.latitude, position.longitude]}
                interactive={false}
                icon={machineMarker}
            />
        ) : null);
}

const SearchField = ({apiKey}) => {
    const provider = new OpenStreetMapProvider({});

    const searchControl = new GeoSearchControl({
        provider: provider,
        searchLabel: 'Wpisz adres aby wyszukać...',
        style: 'bar',
    });

    const map = useMap();
    useEffect(() => {
        // map.addControl(searchControl);
        // return () => map.removeControl(searchControl);
    }, []);

    return null;
};


const machineMarker = L.icon({
    iconUrl: require("../../../src/static/machine_pin.svg"),
    iconSize: [40, 40],
    iconAnchor: [20, 40],
    popupAnchor: [0, -40],
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null
});