import React, {createContext, useState} from 'react';

export const AppContext = createContext();

const AppProvider = ({children}) => {
    const [userName, setUserName] = useState('');
    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');
    const [isUserLogged, setIsUserLogged] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [vacationDays, setVacationDays] = useState(0);
    const [data, setData] = useState({
        provinces: [
            "",
            "dolnośląskie",
            "kujawsko-pomorskie",
            "lubelskie",
            "lubuskie",
            "łódzkie",
            "małopolskie",
            "mazowieckie",
            "opolskie",
            "podkarpackie",
            "podlaskie",
            "pomorskie",
            "śląskie",
            "świętokrzyskie",
            "warmińsko-mazurskie",
            "wielkopolskie",
            "zachodniopomorskie"
        ],
        deviceStatus: [
            {weight: 1, value: "PRODUCTION", name: "Produkcja", colorBackground: "#423F3E", colorText: "#FFFFFF"},
            {weight: 2, value: "TRANSPORT", name: "Transport", colorBackground: "#001E6C", colorText: "#FFFFFF"},
            {weight: 3, value: "INSTALLATION", name: "Instalacja", colorBackground: "#812e8d", colorText: "#ffffff"},
            {weight: 4, value: "OK", name: "Ok", colorBackground: "#1e7e34", colorText: "#FFFFFF"},
            {weight: 5, value: "IN_REPAIR", name: "W naprawie", colorBackground: "#117a8b", colorText: "#FFFFFF"},
            {weight: 6, value: "FAULT", name: "Usterka", colorBackground: "#CDF3A2", colorText: "#000000"},
            {weight: 7, value: "BROKEN", name: "Awaria", colorBackground: "#d39e00", colorText: "#000000"},
            {weight: 8, value: "DANGER", name: "Pilna awaria", colorBackground: "#dc3545", colorText: "#FFFFFF"},
        ],
        serviceTypes: [
            {enum: "PRZEGLAD_OKRESOWY", name: "Przegląd okresowy"},
            {enum: "NAPRAWA", name: "Naprawa"},
            {enum: "SZKOLENIE_OPERATOROW", name: "Szkolenie operatorów"},
            {enum: "INSTALACJA", name: "Instalacja"},
            {enum: "PROTOKOL_PRZEDMONTAZOWY", name: "Protokół przedmontażowy"},
            {enum: "MONTAZ", name: "Protokół montażu"}
        ],
        paymentType: [
            {enum: "PLATNA", name: "Płatna"},
            {enum: "GWARANCYJNA", name: "Gwarancyjna"},
            {enum: "DO_DECYZJI_GWARANTA", name: "Do decyzji gwaranta"},
        ]
    });
    const [machine, setMachine] = useState({hotels: []});
    const [hostname] = useState('https://maszyny-polskie.com.pl:8443');
    // const [hostname] = useState('http://localhost:8080')
    const [redirectToLoginPage, setRedirectToLoginPage] = useState(false)
    const [paginationProperties, setPaginationProperties] = useState({
        sizePerPageList: [10, 20, 30, 50, 100]
    })
    const [machineListState, setMachineListState] = useState({
        data: [],
        page: 1,
        sizePerPage: 10,
        searchInput: '',
        id: '',
        status: [],
        customer: '',
        category: [],
        serialNumber: '',
        sourcePower: '',
        dateFrom: '',
        dateTo: '',
        warranty: '',
        street: '',
        zipCode: '',
        city: '',
        province: [],
        statuses: [],
        categories: [],

        isFilterHidden: false,
        custom: true,
        totalSize: 0,
        dataSize: 0,
        paginationSize: 1,
    })
    const [statistics, setStatistics] = useState({
        searchDateFrom: '', searchDateTo: ''
    });
    const [refresh, setRefresh] = useState({
        map: 0,
        uploadingMapImage: false
    })

    const toggleUserNameState = (value) => setUserName(value);
    const toggleLoginState = (value) => setLogin(value);
    const togglePasswordState = (value) => setPassword(value);
    const toggleAdminState = (value) => setIsAdmin(value);
    const toggleLoggedState = (value) => setIsUserLogged(value);
    const toggleFirstNameState = (value) => setFirstName(value);
    const toggleLastNameState = (value) => setLastName(value);
    const toggleVacationDays = (value) => setVacationDays(value);
    const toggleDataState = (value) => setData(value);
    const toggleMachineState = (value) => setMachine(value);
    const toggleRedirectToLoginPage = (value) => setRedirectToLoginPage(value);
    const togglePaginationProperties = (value) => setPaginationProperties(value);
    const toggleMachineListState = (value) => setMachineListState(value);
    const toggleStatisticsState = (value) => setStatistics(value);
    const toggleRefreshState = (value) => setRefresh(value);

    return (
        <AppContext.Provider value={{
            userName, toggleUserNameState,
            login, toggleLoginState,
            password, togglePasswordState,
            isUserLogged, toggleLoggedState,
            isAdmin, toggleAdminState,
            firstName, toggleFirstNameState,
            lastName, toggleLastNameState,
            vacationDays, toggleVacationDays,
            hostname,
            data, toggleDataState,
            machine, toggleMachineState,
            redirectToLoginPage, toggleRedirectToLoginPage,
            paginationProperties, togglePaginationProperties,
            machineListState, toggleMachineListState,
            statistics, toggleStatisticsState,
            refresh, toggleRefreshState
        }}>
            {children}
        </AppContext.Provider>
    )

};

export default AppProvider;

