import React, {Component} from 'react';
import Card from "react-bootstrap/Card";
import {Badge, Button, Col, Form, OverlayTrigger, Tooltip} from "react-bootstrap";
import axios from "axios";
import {AppContext} from "../../AppContext";
import DbUser from "../../queries/DbUser";
import {Redirect} from "react-router-dom";
import ToolkitProvider, {Search} from "react-bootstrap-table2-toolkit";
import Utils from "../../utils/Utils";
import Row from "react-bootstrap/Row";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";


const defaultSorted = [{
    dataField: 'fullNumber',
    order: 'desc'
}];

const {SearchBar} = Search;

const searchProps = {
    delay: 800,
    placeholder: "wpisz aby wyszukać",
};

const sizePerPageRenderer = ({
                                 options,
                                 currSizePerPage,
                                 onSizePerPageChange
                             }) => (
    <div className="btn-group" role="group">
        {
            options.map((option) => {
                const isSelect = currSizePerPage === `${option.page}`;
                return (
                    <button style={{fontSize: 12}}
                            key={option.text}
                            type="button"
                            onClick={() => onSizePerPageChange(option.page)}
                            className={`btn ${isSelect ? 'btn-secondary' : 'btn-warning'}`}
                    >
                        {option.text}
                    </button>
                );
            })
        }
    </div>
);

const options = {
    sizePerPageRenderer,
    sizePerPageList: [{
        text: '15', value: 15
    }, {
        text: '30', value: 30
    }, {
        text: '50', value: 50
    }, {
        text: '100', value: 100
    }]
};

class MachinePeriodicInspection extends Component {

    static contextType = AppContext;

    state = {
        data: {
            device: {
                warrantyLength: 0,
                isPeriodicInspectionEnabled: true
            },
            periodicInspections: [],
            nextInspection: '',
            lastInspection: '',
            daysToNextInspection: '',
            daysToEndWarranty: '',
            lastPeriod: '',
            lastWarrantyInspectionDone: false,

        }
    }


    componentDidMount() {
        DbUser.getLoggedUser(this.context)

        axios.get(`${this.context.hostname}/api/device/periodicInspection/${this.props.machine.id}`, {
            headers: {"X-Requested-With": "XMLHttpRequest"},
            withCredentials: true,
        }).then(res => {
            if (res.status = 200) {
                this.setState({
                    data: res.data,
                })
            }
        })
            .catch((error) => {
                console.log(error)
            });
    }

    switchIsPeriodicInspectionEnabled = e => {

        axios.patch(`${this.context.hostname}/api/device/isPeriodicInspectionEnabled`, {
                headers: {
                    "X-Requested-With": "XMLHttpRequest",
                    "Content-Type": "application/json",
                },
                "id": this.state.data.device.id,
                "isPeriodicInspectionEnabled": !this.state.data.device.isPeriodicInspectionEnabled,
            },
            {withCredentials: true}
        ).then(res => {
            if (res.status === 200) {
                this.setState({
                    ...this.state,
                    data: {
                        ...this.state.data,
                        device: {
                            ...this.state.data.device,
                            isPeriodicInspectionEnabled: !this.state.data.device.isPeriodicInspectionEnabled
                        }
                    }
                })
            }
        })
            .catch((error) => {
                console.log({error})
                this.setState({validatorMessage: "Błąd!"})
            });
    }


    render() {

        const columns = [
            {
                dataField: 'id',
                text: 'id',
                sort: true,
                style: {width: '4%', verticalAlign: 'middle'},
                headerAlign: 'center',
                align: 'center'
            },
            {
                dataField: 'fullNumber',
                text: 'Numer',
                sort: true,
                align: 'center',
                headerAlign: 'center',
                style: {width: '8%', minWidth: 70, verticalAlign: 'middle'},
                sortFunc: (a, b, order, dataField, rowA, rowB) => {
                    const a1 = Number(rowA.year + String(rowA.month).padStart(2, '0') + String(rowA.number).padStart(10, '0') + (rowA.numberExtension != null ? String(rowA.numberExtension.charCodeAt(0)).padStart(6, '0') : '000000'))
                    const a2 = Number(rowB.year + String(rowB.month).padStart(2, '0') + String(rowB.number).padStart(10, '0') + (rowB.numberExtension != null ? String(rowB.numberExtension.charCodeAt(0)).padStart(6, '0') : '000000'))
                    if (order === 'asc') {
                        return a1 - a2;
                    } else {
                        return a2 - a1;
                    }
                },
            }, {dataField: 'number', hidden: true},
            {dataField: 'numberExtension', hidden: true},
            {dataField: 'month', hidden: true},
            {dataField: 'year', hidden: true},
            {
                dataField: 'date',
                text: 'Data',
                sort: true,
                align: 'center',
                headerAlign: 'center',
                style: {width: '7%', minWidth: 80, verticalAlign: 'middle'},
                formatter: cell => `${cell != null ? cell.substring(0, 10) : false}`,
                sortFunc: (a, b, order, dataField, rowA, rowB) => {
                    const date1 = Date.parse(rowA.date);
                    const date2 = Date.parse(rowB.date)
                    if (order === 'asc') {
                        return date1 - date2;
                    } else {
                        return date2 - date1;
                    }
                },
            }, {
                dataField: 'devices',
                text: 'Maszyny',
                sort: true,
                headerAlign: 'center',
                align: 'center',
                style: {width: '12%', minWidth: 100, verticalAlign: 'middle'},
                formatter: devicesFormatter
            },
            {
                dataField: 'type',
                text: 'Typ',
                sort: true,
                headerAlign: 'center',
                align: 'center',
                style: {width: '6%', verticalAlign: 'middle'},
                formatter: typeFormatter
            },
            {
                dataField: 'paymentType',
                text: 'Koszt',
                sort: true,
                headerAlign: 'center',
                align: 'center',
                style: {width: '6%', verticalAlign: 'middle'}
            },
            {
                dataField: 'faultDescription',
                text: 'Opis usterki',
                sort: true,
                headerAlign: 'center',
                align: 'center',
                style: {width: '18%', minWidth: 200, verticalAlign: 'middle'},
                formatter: cell => <>
                    {cell.length > 80 ?
                        <OverlayTrigger
                            key='top'
                            placement='left'
                            overlay={
                                <Tooltip id={`tooltip-top`}>
                                    {cell}
                                </Tooltip>
                            }
                        >
                            <div variant="secondary">{cell.substring(0, 80)}...</div>
                        </OverlayTrigger> : <>{cell}</>}
                </>,

            }, {
                dataField: 'user',
                text: 'Pracownik',
                sort: true,
                headerAlign: 'center',
                align: 'center',
                style: {width: '10%', verticalAlign: 'middle'}
            }, {
                dataField: 'state',
                text: 'Stan',
                sort: true,
                headerAlign: 'center',
                align: 'center',
                style: {width: '8%', verticalAlign: 'middle'}
            }];

        function typeFormatter(cell, row, rowIndex, formatExtraData) {
            let types = "";
            if (cell != null) {
                let t = [];
                t = Utils.getServiceTypesNamesAsArray(cell)
                t.map((type, i) =>
                    types = <>{types}{type === 'PRZEGLĄD OKRESOWY' && row.period > 0 ? <>{type.replace('PRZEGLĄD OKRESOWY', 'PRZEGLĄD OKRESOWY (' + row.period + 'm)')} </> :
                        <>{type}</>}{i < t.length - 1 ? ',' : ''}<br/></>
                )
            }
            return types;
        }

        function devicesFormatter(cell, row, rowIndex, formatExtraData) {
            let devices = "";
            if (cell != null) {

                cell.map(d =>
                    devices = <>{devices}({d.id}) {d.name}<br/></>
                )
            }
            return devices;
        }


        return (
            <>
                {this.context.redirectToLoginPage ? <Redirect to="/login"/> : ''}

                <Form>
                    <Form.Check
                        style={{marginLeft: 5, marginBottom: 5}}
                        checked={this.state.data.device.isPeriodicInspectionEnabled}
                        type="switch"
                        id="isPeriodicInspectionEnabled"
                        label="Czy maszyna objęta przeglądami okresowymi?"
                        onClick={this.switchIsPeriodicInspectionEnabled}
                    />
                </Form>

                {this.state.data.device.isPeriodicInspectionEnabled ?
                    <Card>
                        <Card.Header>Podsumowanie
                            <Button
                                size="sm"
                                variant="outline-info"
                                className="float-end"
                                onClick={() => this.componentDidMount()}>odśwież</Button></Card.Header>
                        <Card.Body>
                            <Card.Title>

                                {this.state.data.nextInspection !== null
                                && !this.state.data.lastWarrantyInspectionDone
                                && this.state.data.device.warrantyLength > 0 ?
                                    <>Następny
                                        przegląd: {this.state.data.nextInspection} {this.state.data.daysToNextInspection < 0 ? false : <>(za {this.state.data.daysToNextInspection} dni)
                                        </>}
                                    </>
                                    : false}

                                {this.state.data.nextInspection === null
                                && !this.state.data.lastWarrantyInspectionDone
                                    ?
                                    <>Maszyna
                                        nie przekazana</>
                                    : false}

                                {this.state.data.daysToNextInspection < 0
                                && !this.state.data.lastWarrantyInspectionDone
                                && this.state.data.device.warrantyLength > 0 ?
                                    <b style={{color: 'red'}}> ({-this.state.data.daysToNextInspection} dni po terminie
                                        przeglądu)</b>
                                    : false}

                                {this.state.data.lastWarrantyInspectionDone
                                && this.state.data.device.warrantyLength > 0 ?
                                    <b style={{color: 'green'}}> Końcowy przegląd gwarancyjny został wykonany.
                                    </b> : false}

                                {this.state.data.device.warrantyLength <= 0 && this.state.data.nextInspection !== null ? 'Maszyna bez gwarancji' : false}
                            </Card.Title>
                            <Card.Text>
                                {this.state.data.nextInspection !== null ? <> Ostatni
                                    przegląd: {this.state.data.lastInspection}<br/>
                                    Do końca
                                    gwarancji: {this.state.data.daysToEndWarranty <= 0 ? "brak gwarancji" : <>{this.state.data.daysToEndWarranty} dni</>}</> : false}
                            </Card.Text>
                        </Card.Body>
                    </Card>
                    : false}

                <div style={{fontSize: 12}}>
                    <ToolkitProvider
                        bootstrap3
                        keyField="id"
                        data={this.state.data.periodicInspections}
                        columns={columns}
                        defaultSorted={defaultSorted}
                        bordered={false}
                        noDataIndication=""
                        search>
                        {
                            props => (
                                <div style={{fontSize: 12}}>
                                    <Row>
                                        <Col>
                                            <SearchBar
                                                {...props.searchProps}
                                                placeholder="Wpisz aby wyszukać..."
                                                srText=''
                                                style={{
                                                    marginTop: 12,
                                                    paddingBottom: 5,
                                                    paddingLeft: 12,
                                                    marginBottom: 0,
                                                }}
                                            /></Col>
                                        <Col>

                                        </Col>
                                    </Row>
                                    <hr/>
                                    <BootstrapTable
                                        bootstrap3
                                        keyField="id"
                                        data={this.state.data.periodicInspections}
                                        columns={columns}
                                        defaultSorted={defaultSorted}
                                        bordered={false}
                                        noDataIndication=""
                                        pagination={paginationFactory(options)}
                                        {...props.baseProps}
                                        style={{fontSize: 5}}
                                        wrapperClasses="table-responsive"
                                    />
                                </div>
                            )
                        }
                    </ToolkitProvider>
                </div>
            </>
        );
    }
}

export default MachinePeriodicInspection;
