import React, {Component} from "react";
import {Alert, Button, Dropdown, Form, Modal, OverlayTrigger, Tooltip} from "react-bootstrap";
import {Link, Redirect} from "react-router-dom";
import '../../App.css';
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import {AppContext} from "../../AppContext";
import axios from "axios";
import DbUser from "../../queries/DbUser";

const CustomerMachines = (value) => {
    return (
        value.machines.map(
            machine =>
                <Card style={{margin: '3px'}}>

                    <Card.Body style={{padding: '5px', margin: '3px'}}>
                        <Link to={`/machineEdit?id=${machine.id}`}>Nazwa: {machine.name}</Link><br/>
                        Moc źródła: {machine.sourcePower}<br/>
                        Kontrahent: {machine.customer.shortName}<br/>
                        Kategoria: {machine.category.name}<br/>
                        S/N: {machine.serialNumber}<br/>
                    </Card.Body>
                </Card>
        )
    );
}

class CategoryEdit extends Component {

    static contextType = AppContext;

    state = {
        id: new URLSearchParams(this.props.location.search).get("id"),
        categoryName: new URLSearchParams(this.props.location.search).get("name"),
        customerMachines: [],
        category: {serviceReviewOperationsList: []},
        currentOperation: {id: 0, name: '', fields: []},
        exit: false,
        delete: false,
        showDeleteModal: false,
        redirect: '',
        validatorMessage: '',
        showOperationModal: false,
        addEditModalTitle: 'Edycja czynności serwisowej',
        showImportOperationsModal: false,
        categories: [],
        importCategory: '',
        newOperationAlert: ''
    }

    componentDidMount() {
        DbUser.getLoggedUser(this.context)

        axios.get(`${this.context.hostname}/api/device/findByCategory/${this.state.id}`, {
                headers: {
                    "X-Requested-With": "XMLHttpRequest",
                    "Content-Type": "application/json",
                }, withCredentials: true
            },
            {withCredentials: true}
        ).then(res => {
            if (res.status === 200) {
                this.setState({
                    customerMachines: res.data,
                    isDataLoaded: true,
                    showImportOperationsModal: false,
                    showOperationModal: false,
                    validatorMessage: '',

                })
            }
        })
            .catch((error) => {
                console.log({error})
            });

        axios.get(`${this.context.hostname}/api/category/findById/${this.state.id}`, {
                headers: {
                    "X-Requested-With": "XMLHttpRequest",
                    "Content-Type": "application/json",
                }, withCredentials: true
            },
            {withCredentials: true}
        ).then(res => {
            if (res.status === 200) {
                let serviceReviewOperationsList = JSON.parse(res.data.serviceReviewOperationsList)

                let list = [];

                serviceReviewOperationsList.map((item, index) => {

                    let position = item.toString()
                    let name = position.match(".*\\s@dodatkowePola").toString().replaceAll("@dodatkowePola", "");
                    let fieldsString = position.match("@dodatkowePola{.*}", "").join("").toString()
                        .replaceAll("@dodatkowePola{", "").replaceAll("}", "");
                    let fields = fieldsString.split(";")
                    if (fields.at(0) === '') {
                        fields = []
                    }
                    list.push({index, name, fields})
                })

                this.setState({
                    category: {...res.data, "serviceReviewOperationsList": list},
                })
            }
        })
            .catch((error) => {
                console.log({error})
            });

        axios.get(`${this.context.hostname}/api/category/findAll`, {
                headers: {
                    "X-Requested-With": "XMLHttpRequest",
                    "Content-Type": "application/json",
                }, withCredentials: true
            },
            {withCredentials: true}
        ).then(res => {
            if (res.status === 200) {
                this.setState({
                    categories: res.data,
                    importCategory: res.data[0].name
                })
            }
        })
            .catch((error) => {
                console.log({error})
            });
    }

    handleChange = (e) => {
        const name = e.target.value;
        this.setState(
            {...this.state, categoryName: name}
        )
    }
    handleSubmit = (e) => {
        e.preventDefault()
        const value = e.target.categoryName.value;
        if (value.length < 3) {
            this.setState({
                ...this.state,
                validatorMessage: 'Kategoria musi mieć minimum 3 znaki'
            })
        } else {
            this.submitToAPI(this.state.id, this.state.categoryName)
            this.setState({
                ...this.state,
                validatorMessage: ''
            })
        }
    }

    submitToAPI = (id, categoryName) => {
        axios.put(`${this.context.hostname}/api/category/update`, {
                headers: {
                    "X-Requested-With": "XMLHttpRequest",
                    "Content-Type": "application/json",
                },
                "id": id,
                "name": categoryName,
            },
            {withCredentials: true}
        ).then(res => {
            if (res.status === 200) {
                this.setState({...this.state, exit: true,})
            }
        })
            .catch((error) => {
                console.log({error})
                let validatorMessage = 'Nieznany błąd.';
                if (error.response.status === 409) {
                    validatorMessage = 'Podana kategoria już istnieje!'
                }
                this.setState({...this.state, validatorMessage: validatorMessage})

            });
    }

    handleOnClickDelete = () => {
        this.setState({
            showDeleteModal: true
        })
    }

    handleDeleteOnClick = () => {
        this.setState({
            delete: true,
            showDeleteModal: false,
        });

        axios.delete(`${this.context.hostname}/api/category/delete/${this.state.id}`, {
            headers: {"X-Requested-With": "XMLHttpRequest"},
            withCredentials: true,
        }).then(res => {
            if (res.status === 200) {
                this.setState({exit: true,})
            }
        })
            .catch((error) => {
                console.log({error})
                let validatorMessage = 'Nieznany błąd.';
                if (error.response.status === 403) {
                    validatorMessage = 'Brak uprawnień do tej operacji.'
                }
                this.setState({...this.state, validatorMessage: validatorMessage})
            });
    }

    handleBackOnClick = (e) => {
        this.setState({redirect: '/categoryList'})
    }

    handleOnClickOperation = (e) => {
        let currentOperation = {}
        this.state.category.serviceReviewOperationsList.map((value, index) => {
                if (index == e.target.id) {
                    currentOperation = value
                }
            }
        )
        this.setState({
            ...this.state,
            showOperationModal: true,
            currentOperation,
            validatorMessage: '',
            addEditModalTitle: 'Edycja czynności serwisowej'
        })
    }

    handleAddOperation = e => {
        this.setState({
            ...this.state,
            showOperationModal: true,
            validatorMessage: '',
            addEditModalTitle: 'Dodawanie czynności serwisowej',
            currentOperation: {id: -1, name: '', fields: []},
        })
    }

    deleteField = e => {
        const index = e.target.getAttribute('index')
        let fields = this.state.currentOperation.fields.filter((value, i) => {
            if (index != i) return value
        })
        this.setState({...this.state, currentOperation: {...this.state.currentOperation, fields}})
    }

    addFieldButtonOnClick = e => {
        let fields = [...this.state.currentOperation.fields]
        fields.push("+")
        this.setState({...this.state, currentOperation: {...this.state.currentOperation, fields}})
    }

    handleChangeFieldName = e => {
        const i = e.target.getAttribute('index')
        let v = e.target.value.replaceAll("+", "").replaceAll(";", "")
        let fields = [...this.state.currentOperation.fields]
        let fields2 = fields.map((value, index) => {
            if (index == i) {
                let unit = value.split("+")
                return v + "+" + unit[1]
            } else {
                return value
            }
        })
        this.setState({...this.state, currentOperation: {...this.state.currentOperation, fields: fields2}})
    }

    handleChangeFieldUnit = e => {
        const i = e.target.getAttribute('index')
        let v = e.target.value.replaceAll("+", "").replaceAll(";", "")
        let fields = [...this.state.currentOperation.fields]
        let fields2 = fields.map((value, index) => {
            if (index == i) {
                let unit = value.split("+")
                return unit[0] + "+" + v
            } else {
                return value
            }
        })
        this.setState({...this.state, currentOperation: {...this.state.currentOperation, fields: fields2}})
    }

    saveOperations = e => {
        if (this.state.currentOperation.name.includes("@dodatkowePola")) {
            this.setState({
                ...this.state,
                newOperationAlert: 'Noedozwolone wyrażenie "@dodatkowePola" w nazwie.'
            })
            return
        }

        let operations = this.state.category.serviceReviewOperationsList.map(value => value)
        let serviceReviewOperationsList = []
        if (this.state.currentOperation.id === -1) {
            operations.push({
                id: operations.length,
                name: this.state.currentOperation.name,
                fields: this.state.currentOperation.fields
            })
            serviceReviewOperationsList = operations
        } else {
            serviceReviewOperationsList = operations.map((value, index) => {
                if (this.state.currentOperation.index == index) {
                    return this.state.currentOperation
                } else {
                    return value

                }
            })
        }
        let operationsDB = this.parseCategoriesToDB(serviceReviewOperationsList);
        this.updateServiceReviewOperationsList(operationsDB)
    }


    parseCategoriesToDB(serviceReviewOperationsList) {
        let operationsDB = []
        serviceReviewOperationsList.forEach(value => {
            let fields = ''
            value.fields.forEach((field, index) => {
                fields = fields + field
                if (index < value.fields.length - 1) {
                    fields = fields + ";"
                }
            })
            operationsDB.push(value.name.trim().replaceAll("\n", " ") + "\n@dodatkowePola{" + (fields == undefined ? "" : fields) + "}")
        })
        return JSON.stringify(operationsDB);
    }

    updateServiceReviewOperationsList = (operations) => {
        axios.put(`${this.context.hostname}/api/category/update`, {
                headers: {
                    "X-Requested-With": "XMLHttpRequest",
                    "Content-Type": "application/json",
                },
                "id": this.state.id,
                "name": this.state.categoryName,
                "serviceReviewOperationsList": operations == undefined ? '[]' : operations
            },
            {withCredentials: true}
        ).then(res => {
            if (res.status === 200) {
                this.componentDidMount()
            }
        })
            .catch((error) => {
                console.log({error})
                let validatorMessage = 'Nieznany błąd.';
                if (error.response.status === 409) {
                    validatorMessage = 'Podana kategoria już istnieje!'
                } else if (error.response.status === 403) {
                    validatorMessage = 'Brak uprawnień!'
                }
                this.setState({
                    ...this.state,
                    validatorMessage: validatorMessage,
                    showOperationModal: false,
                    showImportOperationsModal: false
                })
            });
    }

    handleChangeOperationName = e => {
        this.setState({...this.state, currentOperation: {...this.state.currentOperation, name: e.target.value}})
    }

    handleDeleteOperation = e => {
        let operations = this.state.category.serviceReviewOperationsList.filter((value, index) => {
            if (e.target.id != index) {
                return value
            }
        })
        this.updateServiceReviewOperationsList(this.parseCategoriesToDB(operations))
    }

    moveUpOperation = e => {
        let operation = this.state.category.serviceReviewOperationsList.at(e.target.id)
        let operations = []
        for (let i = 0; i < this.state.category.serviceReviewOperationsList.length; i++) {
            if (i == e.target.id - 1) {
                const c = this.state.category.serviceReviewOperationsList.at(i)
                operations.push(operation)
                operations.push(c)
                i++
            } else {
                operations.push(this.state.category.serviceReviewOperationsList.at(i))
            }
        }
        this.updateServiceReviewOperationsList(this.parseCategoriesToDB(operations))
    }

    moveDownOperation = e => {
        let operations = []
        for (let i = 0; i < this.state.category.serviceReviewOperationsList.length; i++) {
            if (i == e.target.id && i < this.state.category.serviceReviewOperationsList.length - 1) {
                operations.push(this.state.category.serviceReviewOperationsList.at(i + 1))
                operations.push(this.state.category.serviceReviewOperationsList.at(i))
                i++
            } else {
                operations.push(this.state.category.serviceReviewOperationsList.at(i))
            }
        }
        this.updateServiceReviewOperationsList(this.parseCategoriesToDB(operations))
    }

    importOperations = e => {
        this.setState({...this.state, showImportOperationsModal: true, validatorMessage: ''})
    }

    importOperationsSave = e => {
        let category = this.state.categories.find(value => value.name == this.state.importCategory)
        this.updateServiceReviewOperationsList(category.serviceReviewOperationsList === undefined ? [] : category.serviceReviewOperationsList)
    }

    handleChangeImportSelect = e => {
        const value = e.target.value;
        const name = e.target.name;
        this.setState(
            {
                [name]: value,
            }
        )
    }

    render() {

        if (this.state.exit === true) {
            return (<Redirect to="/categoryList"/>
            )
        }

        return (

            <div className="fragment">
                {this.context.redirectToLoginPage ? <Redirect to="/login"/> : ''}
                {this.state.redirect != '' ? <Redirect to={this.state.redirect}/> : ''}
                <h3>
                    <Button variant="light" onClick={this.handleBackOnClick}>
                        <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-arrow-return-left"
                             fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd"
                                  d="M14.5 1.5a.5.5 0 0 1 .5.5v4.8a2.5 2.5 0 0 1-2.5 2.5H2.707l3.347 3.346a.5.5 0 0 1-.708.708l-4.2-4.2a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 8.3H12.5A1.5 1.5 0 0 0 14 6.8V2a.5.5 0 0 1 .5-.5z"/>
                        </svg>
                    </Button>
                    &nbsp;Edycja kategorii
                </h3>
                <Row>
                    <Col sm={5}>
                        <Form onSubmit={this.handleSubmit}>
                            <Form.Group controlId="formBasicEmail">
                                <Form.Label>Nazwa kategorii (id: {this.state.id})
                                    <Button onClick={this.handleOnClickDelete}
                                            size="sm"
                                            variant="outline-dark"
                                            style={{marginLeft: "5px"}}>
                                        Usuń</Button>
                                </Form.Label>
                                <Form.Control
                                    style={{marginTop: 10, marginBottom: 10}}
                                    type="text" name="categoryName"
                                    value={this.state.categoryName}
                                    placeholder="Wpisz kategorie..."
                                    onChange={this.handleChange}
                                />
                                <Form.Text className="validatorMessage">
                                    {this.state.validatorMessage != '' ?
                                        <Alert variant='danger'>
                                            {this.state.validatorMessage}
                                        </Alert> : false
                                    }
                                </Form.Text>
                            </Form.Group>
                            <Button
                                variant="outline-dark"
                                type="submit"
                                size="sm"
                                style={{marginRight: "5px"}}>
                                Zapisz
                            </Button>
                            <Button
                                size="sm"
                                variant="outline-dark"
                                onClick={() => this.setState({redirect: '/categoryList'})}>
                                Anuluj
                            </Button>
                        </Form>
                    </Col>
                    <Col>
                        <br/>
                        <Tabs
                            id="controlled-tab-example">
                            <Tab eventKey="machines" title="Maszyny">
                                <CustomerMachines machines={this.state.customerMachines}/>
                            </Tab>
                            <Tab eventKey="serviceReviewOperationsList" title="Czynności serwisowe">


                                <Card style={{margin: '3px'}} border="white">
                                    <Card.Body style={{padding: '5px', margin: '3px'}}>
                                        <Button
                                            className="float-end"
                                            variant="outline-dark"
                                            onClick={this.handleAddOperation}
                                        >+</Button>


                                        <OverlayTrigger
                                            key='top'
                                            placement='left'
                                            overlay={
                                                <Tooltip id={`tooltip-top`}>
                                                    Import listy czynności serwisowych z innej wybranej kategorii
                                                </Tooltip>
                                            }
                                        >
                                            <Button
                                                style={{marginRight: 20}}
                                                // className="float-end"
                                                variant="outline-dark"
                                                onClick={this.importOperations}>
                                                <i className="bi bi-box-arrow-in-down"></i>
                                            </Button>
                                        </OverlayTrigger>
                                    </Card.Body>
                                </Card>
                                {this.state.category.serviceReviewOperationsList.map((item, index) =>


                                    <Card style={{margin: '3px'}}>
                                        <Card.Body value={index}
                                                   style={{padding: '5px', margin: '3px'}}>
                                            <Row>
                                                <Col>
                                                    <Button
                                                        style={{
                                                            textAlign: 'left',
                                                            backgroundColor: 'white',
                                                            border: 'none',
                                                            // width: '92%',
                                                            width: '100%',
                                                            display: "inline-block",
                                                            marginRight: 30,
                                                            paddingRight: 30
                                                        }}
                                                        variant="light"
                                                        size="sm"
                                                        onClick={this.handleOnClickOperation}
                                                        name={item.name}
                                                        id={index}
                                                    >{index + 1}. {item.name}
                                                        {item.fields.map((field, index) =>
                                                            <>
                                                                {index === item.fields.length ? false : <br/>}
                                                                {field.replace("+", " _______ ")}
                                                            </>
                                                        )}
                                                    </Button>
                                                </Col>

                                                <Col sm={1} style={{width: 70}}>
                                                    <Row>
                                                        <Col style={{padding: 0}}>
                                                            <i className="bi-chevron-down clickableFont" id={index}
                                                               onClick={this.moveDownOperation}></i>
                                                        </Col>
                                                        <Col style={{padding: 0}}>
                                                            <i className="bi bi-chevron-up clickableFont" id={index}
                                                               onClick={this.moveUpOperation}>
                                                            </i>
                                                        </Col>
                                                        <Col style={{padding: 0}}>
                                                            <i className="bbi bi-x-square  clickableFont" id={index}
                                                               onClick={this.handleDeleteOperation}/>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                )}
                            </Tab>
                        </Tabs>
                    </Col>
                </Row>

                <Modal show={this.state.showDeleteModal} onHide={() => this.setState({showDeleteModal: false})}>
                    <Modal.Header closeButton>
                        <Modal.Title>Potwierdzenie</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Czy napewno chcesz usunąć: {this.state.categoryName}</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => this.setState({showDeleteModal: false})}>
                            Anuluj
                        </Button>
                        <Button variant="primary" onClick={this.handleDeleteOnClick}>
                            Usuń
                        </Button>
                    </Modal.Footer>
                </Modal>

                {/*MODAL CURRENT OPERATION*/}
                <Modal show={this.state.showOperationModal}
                       onHide={() => this.setState({showOperationModal: false})}>
                    <Modal.Header closeButton>
                        <Modal.Title>{this.state.addEditModalTitle}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.state.newOperationAlert ?
                            <Alert variant='danger'>{this.state.newOperationAlert}</Alert> : false}
                        Nazwa
                        <Form.Control
                            type="text" name="operationName"
                            value={this.state.currentOperation.name}
                            placeholder="Wpisz nazwę..."
                            onChange={this.handleChangeOperationName}
                        />
                        {this.state.currentOperation.fields.map((value, index) =>
                            <>

                                <Row style={{margin: 5}}>
                                    <Col>
                                        <Form.Control
                                            type="text" name="fieldName"
                                            index={index}
                                            value={value.split("+").at(0)}
                                            placeholder="Nazwa"
                                            onChange={this.handleChangeFieldName}
                                        />
                                    </Col>
                                    <Col>
                                        <Form.Control
                                            disabled={true}
                                            type="text"
                                            placeholder="wartość"
                                        />
                                    </Col>
                                    <Col>
                                        <Form.Control
                                            type="text"
                                            index={index}
                                            value={value.split("+").at(1)}
                                            placeholder="j.m."
                                            onChange={this.handleChangeFieldUnit}
                                        />
                                    </Col>
                                    <Col style={{verticalAlign: 'middle'}} md="auto">
                                        <i className="bi bi-trash float-end clickableFont"
                                           index={index}
                                           style={{marginTop: 7, fontSize: 18, verticalAlign: 'middle'}}
                                           onClick={this.deleteField}/>
                                    </Col>
                                </Row>
                            </>)}

                        <Row style={{margin: 18}}>
                            <Button variant="outline-secondary" onClick={this.addFieldButtonOnClick}>
                                <i style={{fontSize: 22}} className="bi bi-plus"></i></Button>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary"
                                onClick={() => this.setState({showOperationModal: false})}>
                            Anuluj
                        </Button>
                        <Button variant="primary" onClick={this.saveOperations}>
                            Zapisz
                        </Button>
                    </Modal.Footer>
                </Modal>

                {/*COPY OPERATIONS FROM OTHER CATEGORY*/}
                <Modal show={this.state.showImportOperationsModal}
                       onHide={() => this.setState({showImportOperationsModal: false})}>
                    <Modal.Header closeButton>
                        <Modal.Title>Import czynności serwisowych z innej kategorii</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Wybierz kategorię z której chcesz skopiować listę
                        <Form.Control as="select" name="importCategory"
                                      value={this.state.importCategory}
                                      onChange={this.handleChangeImportSelect}>
                            {this.state.categories.map(value =>
                                <option id={value.id}>{value.name}</option>
                            )}
                        </Form.Control>
                        <br/>
                        <Alert variant='warning'>
                            Uwaga! Po tej operacji obecna lista zostanie usunięta
                        </Alert>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => this.setState({showImportOperationsModal: false})}>
                            Anuluj
                        </Button>
                        <Button variant="primary" onClick={this.importOperationsSave}>
                            Importuj
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}

export default CategoryEdit;