import React, {Component} from "react";
import {Button, Container, Dropdown, Form} from "react-bootstrap";
import {Redirect} from "react-router-dom";
import '../../App.css';
import './MachineEdit.css';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {DropdownButton} from "react-bootstrap/DropdownButton";
import {AppContext} from "../../AppContext";
import axios from "axios";
import LayoutUtil from "../../utils/LayoutUtil";
import DbUser from "../../queries/DbUser";


class MachineAdd extends Component {

    static contextType = AppContext;

    state = {
        data: {
            name: '',
            category: '',
            customer: '',
            machine: {
                name: '',
                category: {},
                customer: '',
                serialNumber: '',
                sourcePower: '',
                transferDate: '',
                warranty: '',
                warrantyLength: '',
                streetAddress: '',
                zipCode: '',
                city: '',
                province: '',
                status: 'Produkcja',
            }
        },
        customers: [],
        categories: [],
        devicesNames: [],
        exit: false,
        delete: false,
        showDeleteModal: false,
        redirect: '',
        isNewDeviceTextFieldActive: false,
        styleDropdown: { width: 120, backgroundColor: 'white', borderColor: 'white'}
    }

    componentDidMount() {
        DbUser.getLoggedUser(this.context)

        //Domyślny status dla nowej maszyny
        const styleDropdown = LayoutUtil.getStatusStyle(this.context, "Produkcja")

        axios.get(`${this.context.hostname}/api/device/findAllDevicesNames`, {
            headers: {"X-Requested-With": "XMLHttpRequest"},
            withCredentials: true,
        }).then(res => {
            if (res.status = 200) {
                this.setState({
                    ...this.state,
                    devicesNames: res.data,
                    isDataLoaded: true,
                    styleDropdown: styleDropdown
                })
            }
        })
            .catch((error) => {
                console.log(error)
            });

        axios.get(`${this.context.hostname}/api/customer/findAll`, {
            headers: {"X-Requested-With": "XMLHttpRequest"},
            withCredentials: true,
        }).then(res => {
            if (res.status = 200) {
                this.setState({
                    customers: [{shortName: 'Wybierz...'}, ...res.data],
                    isDataLoaded: true
                })
            }
        })
            .catch((error) => {
                console.log(error)
            });

        axios.get(`${this.context.hostname}/api/category/findAll`, {
            headers: {"X-Requested-With": "XMLHttpRequest"},
            withCredentials: true,
        }).then(res => {
            if (res.status = 200) {
                this.setState({
                    categories: [{name: 'Wybierz...'}, ...res.data],
                    isDataLoaded: true
                })
            }
        })
            .catch((error) => {
                console.log(error)
            });
    }

    handleChange = (e) => {
        let value = e.target.value;
        const name = e.target.name;
        let id = '';
        if (name === 'category' || name == 'customer') {
            id = e.target.children[e.target.selectedIndex].id;
        }
        if (name === 'warrantyLength' && value<0){
            value = '';
        }

        this.setState(prevState => (
            {

                data: {
                    ...prevState.data,
                    [name]: id,
                    machine: {
                        ...prevState.data.machine,
                        [name]: value
                    }
                }
            }
        ))
    }

    handleSubmit = (e) => {
        e.preventDefault()
        const value = e.target.name.value;
        if (value.length < 3) {
            this.setState({
                validatorMessage: 'Nazwa musi mieć minimum 3 znaki'
            })
        } else {
            this.submitToAPI(
                this.state.data.machine.name,
                this.state.data.category,
                this.state.data.customer,
                this.state.data.machine.serialNumber,
                this.state.data.machine.sourcePower,
                this.state.data.machine.transferDate,
                this.state.data.machine.warrantyLength,
                this.state.data.machine.warranty,
                this.state.data.machine.streetAddress,
                this.state.data.machine.zipCode,
                this.state.data.machine.city,
                this.state.data.machine.province,
                this.state.data.machine.status)
            this.setState({
                machine: {},
                validatorMessage: ''
            })
        }
    }

    submitToAPI = (name,
                   category,
                   customer,
                   serialNumber,
                   sourcePower,
                   transferDate,
                   warrantyLength,
                   warranty,
                   streetAddress,
                   zipCode,
                   city,
                   province,
                   status) => {

        axios.post(`${this.context.hostname}/api/device/insert`, {
                headers: {
                    "X-Requested-With": "XMLHttpRequest",
                    "Content-Type": "application/json",
                },
                "name": name,
                "category": {id: category},
                "customer": {id: customer},
                "serialNumber": serialNumber,
                "sourcePower": sourcePower,
                "transferDate": transferDate,
                "warrantyLength": warrantyLength,
                "warranty": warranty,
                "streetAddress": streetAddress,
                "zipCode": zipCode,
                "city": city,
                "province": province,
                "status": status
            },
            {withCredentials: true}
        ).then(res => {
            if (res.status === 200) {
                console.log(res)
                this.setState({exit: true,})
            }
        })
            .catch((error) => {
                console.log({error})
                this.setState({validatorMessage: "Błąd!"})
            });
    }

    handleBackOnClick = () => {
        this.setState({redirect: '/machineList'})
    }

    handleChangeStatus = (e) => {

        let style = LayoutUtil.getStatusStyle(this.context, e.target.name);
        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                machine: {
                    ...this.state.machine,
                    status: e.target.name
                }
            },
            styleDropdown: style
        })
    }

    render() {

        if (this.state.exit === true) {
            return (<Redirect to="/machineList"/>
            )
        }

        return (

            <div className="fragment">
                {this.context.redirectToLoginPage ? <Redirect to="/login"/> : ''}
                {this.state.redirect != '' ? <Redirect to={this.state.redirect}/> : ''}
                <Row>
                    <Col sm={5}>
                        <h3><Button variant="light" onClick={this.handleBackOnClick}>
                            <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-arrow-return-left"
                                 fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd"
                                      d="M14.5 1.5a.5.5 0 0 1 .5.5v4.8a2.5 2.5 0 0 1-2.5 2.5H2.707l3.347 3.346a.5.5 0 0 1-.708.708l-4.2-4.2a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 8.3H12.5A1.5 1.5 0 0 0 14 6.8V2a.5.5 0 0 1 .5-.5z"/>
                            </svg>
                        </Button>
                            &nbsp;Dodawanie nowej maszyny
                        </h3>
                        <Form.Label>Status
                        </Form.Label>
                        <Dropdown style={{width: 100}}>

                            <Dropdown.Toggle style={this.state.styleDropdown} id="dropdown-basic">
                                {this.state.data.machine.status}
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                {this.context.data.deviceStatus.map(status =>
                                    <Dropdown.Item onClick={this.handleChangeStatus}
                                                   name={status.name}>{status.name}</Dropdown.Item>
                                )}
                            </Dropdown.Menu>
                        </Dropdown>
                        <Form onSubmit={this.handleSubmit}>
                            <Form.Label>Nazwa
                            </Form.Label>

                            <Form.Group controlId="formBasicEmail">
                                <Row>
                                    <Col>
                                        {!this.state.isNewDeviceTextFieldActive ?
                                            <Form.Control as="select" name="name"
                                                          value={this.state.data.machine.name}
                                                          onChange={this.handleChange}>
                                                {this.state.devicesNames.map(name =>
                                                    <option id={name}>{name}</option>
                                                )}
                                            </Form.Control> :
                                            <Form.Control
                                                type="text" name="name"
                                                value={this.state.data.machine.name}
                                                placeholder="Wpisz nową nazwę maszyny..."
                                                onChange={this.handleChange}
                                            />
                                        }
                                    </Col>
                                    <Col xs={1} style={{marginLeft: 16, width: 40}}>
                                        {!this.state.isNewDeviceTextFieldActive ?
                                            <Button className="float-end pull-right"
                                                    variant="outline-dark"
                                                    style={{width: 40}}
                                                    onClick={() => this.setState({
                                                        ...this.state,
                                                        isNewDeviceTextFieldActive: !this.state.isNewDeviceTextFieldActive,
                                                        data: {
                                                            ...this.state.data,
                                                            machine: {...this.state.data.machine, name: ''}
                                                        }
                                                    })}
                                            >+</Button> :
                                            <Button style={{marginLeft: 16, width: 40}} className="float-end"
                                                    variant="outline-dark" onClick={() => this.setState({
                                                ...this.state,
                                                isNewDeviceTextFieldActive: !this.state.isNewDeviceTextFieldActive,
                                            })}>
                                                ☰
                                            </Button>}
                                    </Col>
                                </Row>

                                <Form.Label>Kategoria
                                </Form.Label>
                                <Form.Control as="select" name="category"
                                              value={this.state.data.machine.category}
                                              onChange={this.handleChange}>
                                    {this.state.categories.map(category =>
                                        <option id={category.id}>{category.name}</option>
                                    )}
                                </Form.Control>
                                <Form.Label>Kontrahent
                                </Form.Label>
                                <Form.Control as="select" name="customer"
                                              value={this.state.data.machine.customer}
                                              onChange={this.handleChange}>
                                    {this.state.customers.map(customer =>
                                        <option id={customer.id}>{customer.shortName}&nbsp;
                                            {customer.streetAddress}&nbsp;
                                            {customer.zipCode}&nbsp;
                                            {customer.city}&nbsp;
                                            {customer.nip}</option>
                                    )}
                                </Form.Control>
                                <Form.Label>Numer seryjny
                                </Form.Label>
                                <Form.Control
                                    type="text" name="serialNumber"
                                    value={this.state.data.machine.serialNumber}
                                    placeholder="Wpisz..."
                                    onChange={this.handleChange}
                                />
                                <Form.Label>Moc źródła
                                </Form.Label>
                                <Form.Control
                                    type="text" name="sourcePower"
                                    value={this.state.data.machine.sourcePower}
                                    placeholder="Wpisz..."
                                    onChange={this.handleChange}
                                />
                                <Form.Label>Data przekazania
                                </Form.Label>
                                <Form.Control
                                    type="date" name="transferDate"
                                    value={this.state.data.machine.transferDate}
                                    placeholder="Wpisz..."
                                    onChange={this.handleChange}
                                />
                                {/*<Form.Label>Gwarancja*/}
                                {/*</Form.Label>*/}
                                {/*<Form.Control*/}
                                {/*    type="text" name="warranty"*/}
                                {/*    value={this.state.data.machine.warranty}*/}
                                {/*    placeholder="Wpisz..."*/}
                                {/*    onChange={this.handleChange}*/}
                                {/*/>*/}
                                <Row className="g-2">
                                    <Col md>
                                        <Form.Label>Gwarancja [miesiące]
                                        </Form.Label>
                                        <Form.Control
                                            type="number" name="warrantyLength"
                                            value={this.state.data.machine.warrantyLength}
                                            placeholder="Wpisz..."
                                            onChange={this.handleChange}
                                        />
                                    </Col>

                                    <Col md>
                                        <Form.Label>Rodzaj gwarancji
                                        </Form.Label>
                                        <Form.Control
                                            type="text" name="warranty"
                                            value={this.state.data.machine.warranty}
                                            placeholder="Wpisz..."
                                            onChange={this.handleChange}
                                        />
                                    </Col>
                                </Row>
                                <hr/>
                                <Form.Label>Ulica
                                </Form.Label>
                                <Form.Control
                                    type="text" name="streetAddress"
                                    value={this.state.data.machine.streetAddress}
                                    placeholder="Wpisz..."
                                    onChange={this.handleChange}
                                />
                                <Form.Label>Kod pocztowy
                                </Form.Label>
                                <Form.Control
                                    type="text" name="zipCode"
                                    value={this.state.data.machine.zipCode}
                                    placeholder="Wpisz..."
                                    onChange={this.handleChange}
                                />
                                <Form.Label>Miasto
                                </Form.Label>
                                <Form.Control
                                    type="text" name="city"
                                    value={this.state.data.machine.city}
                                    placeholder="Wpisz..."
                                    onChange={this.handleChange}
                                />
                                <Form.Label>Województwo
                                </Form.Label>
                                <Form.Control as="select" name="province"
                                              value={this.state.province}
                                              onChange={this.handleChange}>
                                    {this.context.data.provinces.map(province =>
                                        <option id={province}>{province}</option>
                                    )}
                                </Form.Control>
                                <Form.Text className="validatorMessage">
                                    {this.state.validatorMessage}
                                </Form.Text>
                            </Form.Group>
                            <Button
                                variant="outline-dark"
                                type="submit"
                                size="sm"
                                style={{marginRight: "5px"}}>
                                Zapisz
                            </Button>
                            <Button
                                size="sm"
                                variant="outline-dark"
                                onClick={() => this.setState({redirect: '/machineList'})}>
                                Anuluj
                            </Button>
                        </Form>
                    </Col>
                    <Col>

                    </Col>
                </Row>
            </div>
        )
    }
}

export default MachineAdd;
