import React, {Component} from "react";
import {Spinner, Button, Modal, ButtonGroup} from "react-bootstrap";
import '../../App.css';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, {Search} from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import {Redirect} from "react-router-dom";
import {AppContext} from "../../AppContext";
import axios from "axios";
import DbUser from "../../queries/DbUser";


const defaultSorted = [{
    dataField: 'fullNumber',
    order: 'desc'
}];

const {SearchBar} = Search;

const searchProps = {
    delay: 800,
    placeholder: "wpisz aby wyszukać",
};

const sizePerPageRenderer = ({
                                 options,
                                 currSizePerPage,
                                 onSizePerPageChange
                             }) => (
    <div className="btn-group" role="group">
        {
            options.map((option) => {
                const isSelect = currSizePerPage === `${option.page}`;
                return (
                    <button
                        key={option.text}
                        type="button"
                        onClick={() => onSizePerPageChange(option.page)}
                        className={`btn ${isSelect ? 'btn-secondary' : 'btn-warning'}`}
                    >
                        {option.text}
                    </button>
                );
            })
        }
    </div>
);

const options = {
    sizePerPageRenderer
};

class ServicesList extends Component {

    static contextType = AppContext;

    state = {
        data: [],
        isDataLoaded: false,
        edit: false,
        redirect: '',
        showDeviceChoice: false,
        idDevice: '',
        devices: []
    }

    componentDidMount() {
        DbUser.getLoggedUser(this.context)

        axios.get(`${this.context.hostname}/api/service/findAllOrder`, {
            headers: {"X-Requested-With": "XMLHttpRequest"},
            withCredentials: true,
        }).then(res => {
            if (res.status = 200) {
                this.setState({
                    ...this.state,
                    data: res.data,
                    isDataLoaded: true
                })
            }
        })
            .catch((error) => {
                console.log(error)
            });
    }

    handleBackOnClick = () => {
        this.setState({...this.state, redirect: '/home'})
    }

    handleOnClickMachineInModal = (e) => {
        console.log(e.target)
        const link = `/machineEdit?id=${e.target.id}&prev=servicesList&customerId=${e.target.name}&activeTab=service`
        this.setState({...this.state, redirect: link})
    }

    render() {

        const onClickEvent = {

            onClick: (e, column, columnIndex, row, rowIndex) => {
                if (row.devices.length === 1) {
                    const link = `/machineEdit?id=${row.devices[0].id}&prev=servicesList&customerId=${row.devices[0].customer.id}&activeTab=service`
                    this.setState({...this.state, redirect: link})
                } else if (row.devices.length > 1) {
                    this.setState({...this.state, showDeviceChoice: true, devices: row.devices})
                }
            }
        }

        const columns = [{
            dataField: 'id',
            text: 'id',
            sort: true,
            events: onClickEvent,
            style: {width: '4%'}

        }, {
            dataField: 'fullNumber',
            text: 'Numer',
            sort: true,
            events: onClickEvent,
            align: 'center',
            headerAlign: 'center',
            style: {width: '8%'},
            sortFunc: (a, b, order, dataField, rowA, rowB) => {
                const a1 = Number(rowA.year + String(rowA.month).padStart(2, '0') + String(rowA.number).padStart(10, '0') + (rowA.numberExtension != null ? String(rowA.numberExtension.charCodeAt(0)).padStart(6, '0') : '000000'))
                const a2 = Number(rowB.year + String(rowB.month).padStart(2, '0') + String(rowB.number).padStart(10, '0') + (rowB.numberExtension != null ? String(rowB.numberExtension.charCodeAt(0)).padStart(6, '0') : '000000'))
                if (order === 'asc') {
                    return a1 - a2;
                } else {
                    return a2 - a1;
                }
            },
        }, {
            dataField: 'number',
            hidden: true
        }, {
            dataField: 'numberExtension',
            hidden: true
        }, {
            dataField: 'month',
            hidden: true
        }, {
            dataField: 'year',
            hidden: true
        }, {
            dataField: 'date',
            text: 'Data',
            sort: true,
            events: onClickEvent,
            align: 'center',
            formatter: cell => `${cell != null ? cell.substring(0, 10) : false}`,
            headerAlign: 'center',
            style: {width: '8%'},
            sortFunc: (a, b, order, dataField, rowA, rowB) => {
                const date1 = Date.parse(rowA.date);
                const date2 = Date.parse(rowB.date)
                if (order === 'asc') {
                    return date1 - date2;
                } else {
                    return date2 - date1;
                }
            },
        }, {
            dataField: 'customer.shortName',
            text: 'Nazwa kontrahenta',
            sort: true,
            events: onClickEvent,
            style: {width: '12%'}
        }, {
            dataField: 'devices',
            text: 'Nazwa maszyny',
            sort: true,
            events: onClickEvent,
            // formatter: cell => `${cell!= null ? cell.map(d => <>({d.id}) {d.name}</>) : false}`,
            formatter: devicesFormatter,
            style: {width: '12%'}
        }, {
            dataField: 'paymentType',
            text: 'Koszt',
            sort: true,
            events: onClickEvent,
            style: {width: '18%'}
        }, {
            dataField: 'faultDescription',
            text: 'Opis usterki',
            sort: true,
            events: onClickEvent,
            style: {width: '20%'}
        }, {
            dataField: 'user',
            text: 'user',
            sort: true,
            events: onClickEvent,
            style: {width: '10%'}
        }, {
            dataField: 'state',
            text: 'Stan',
            sort: true,
            events: onClickEvent,
            style: {width: '8%'}
        }];

        function devicesFormatter(cell, row, rowIndex, formatExtraData) {
            console.log(cell)
            let devices = "";
            if (cell != null) {
                cell.map(d =>
                    devices = <>{devices}({d.id}) {d.name}<br/></>
                )

            }
            return devices;

        }


        return (
            <div className="fragment">

                {this.context.redirectToLoginPage ? <Redirect to="/login"/> : ''}
                {this.state.redirect != '' ? <Redirect to={this.state.redirect}/> : ''}
                <ToolkitProvider
                    bootstrap3
                    keyField="id"
                    data={this.state.data}
                    columns={columns}
                    defaultSorted={defaultSorted}
                    bordered={false}
                    noDataIndication=""
                    search>
                    {
                        props => (
                            <div>
                                <h3>
                                    <Button variant="light" onClick={this.handleBackOnClick}>
                                        <svg width="1em" height="1em" viewBox="0 0 16 16"
                                             className="bi bi-arrow-return-left"
                                             fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd"
                                                  d="M14.5 1.5a.5.5 0 0 1 .5.5v4.8a2.5 2.5 0 0 1-2.5 2.5H2.707l3.347 3.346a.5.5 0 0 1-.708.708l-4.2-4.2a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 8.3H12.5A1.5 1.5 0 0 0 14 6.8V2a.5.5 0 0 1 .5-.5z"/>
                                        </svg>
                                    </Button>
                                    &nbsp;Lista zdarzeń serwisowych
                                </h3>

                                <SearchBar
                                    {...props.searchProps}
                                    placeholder="Wpisz aby wyszukać..."
                                />
                                <hr/>
                                <BootstrapTable
                                    bootstrap3
                                    keyField="id"
                                    data={this.state.data}
                                    columns={columns}
                                    defaultSorted={defaultSorted}
                                    bordered={false}
                                    noDataIndication=""
                                    pagination={paginationFactory(options)}
                                    {...props.baseProps}
                                />
                            </div>
                        )
                    }
                </ToolkitProvider>
                {!this.state.isDataLoaded &&
                <div className={"container"} style={{textAlign: "center"}}><Spinner animation="border"/></div>}

                <Modal
                    size="sm"
                    show={this.state.showDeviceChoice}
                    onHide={() => this.setState({...this.state, showDeviceChoice: false})}
                    aria-labelledby="example-modal-sizes-title-sm"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="example-modal-sizes-title-sm">
                            Wybierz maszynę.
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="d-grid gap-2">
                            {/*<ButtonGroup vertical>*/}
                            {this.state.devices.map(device =>
                                <Button
                                    name={device.customer.id}
                                    variant="outline-secondary"
                                    id={device.id}
                                    onClick={this.handleOnClickMachineInModal}
                                >({device.id}){device.name}</Button>
                            )}
                            {/*</ButtonGroup>*/}
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        )
    }
}

export default ServicesList;

function Padder(len, pad) {
    if (len === undefined) {
        len = 1;
    } else if (pad === undefined) {
        pad = '0';
    }

    var pads = '';
    while (pads.length < len) {
        pads += pad;
    }

    this.pad = function (what) {
        var s = what.toString();
        return pads.substring(0, pads.length - s.length) + s;
    };
}
