import React, {Component} from 'react';
import {AppContext} from "../../AppContext";
import {Button, Col, Form, Nav, Row, Tab, Table} from "react-bootstrap";
import Card from "react-bootstrap/Card";
import {Redirect} from "react-router-dom";
import DbUser from "../../queries/DbUser";
import Services from "./tabs/Services";


class Statistics extends Component {

    static contextType = AppContext;

    state = {}

    componentDidMount() {
        DbUser.getLoggedUser(this.context)


    }

    constructor(props) {
        super(props);
        this.numberOfUserServices = React.createRef();
    }


    handleChange = (e) => {
        let value = e.target.value;
        let name = e.target.name;
        let dateFrom = this.context.statistics.searchDateFrom;
        let dateTo = this.context.statistics.searchDateTo;
        this.context.toggleStatisticsState(
            {
                ...this.context.statistics,
                [name]: value,
            }
        )

        if (name === 'searchDateFrom') {
            dateFrom = value
        }
        if (name === 'searchDateTo') {
            dateTo = value
        }


        this.numberOfUserServices.current.getData(dateFrom, dateTo)
    }



    render() {
        return (
            <div>
                {this.context.redirectToLoginPage ? <Redirect to="/login"/> : ''}

                <Card style={{marginTop: 125, marginLeft: 50, marginRight: 50, padding: 9, marginBottom: 150}}>
                    <Tab.Container id="left-tabs-example" defaultActiveKey="services" onSelect={this.handleSelect}>
                        <Row>
                            <Col sm={2}>
                                <Nav variant="pills" className="flex-column" style={{cursor: 'pointer'}}>
                                    <Nav.Item>
                                        <Nav.Link eventKey="services">Zdarzenia serwisowe</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </Col>
                            <Col sm={10}>
                                <Card style={{margin: '3px'}}>
                                    <Card.Body style={{padding: '5px', margin: '3px', fontSize: 12}}>

                                        <Row style={{marginLeft: 6}}>

                                            <Col style={{padding: 0, maxWidth: 200}}>

                                                <Form.Label style={{marginTop: 0, marginBottom: 5}}>Data od</Form.Label>
                                                <Form.Control size="sm"
                                                              style={{marginTop: 4, width: 170}}
                                                              type='date' value={this.context.statistics.searchDateFrom}
                                                              name='searchDateFrom'
                                                              onChange={this.handleChange}/>
                                            </Col>
                                            <Col style={{padding: 0, maxWidth: 200}}>
                                                <Form.Label style={{marginTop: 0, marginBottom: 5}}>Data do</Form.Label>
                                                <Form.Control size="sm"
                                                              style={{marginTop: 4, width: 170}}
                                                              type='date'
                                                              value={this.context.statistics.searchDateTo}
                                                              name='searchDateTo'
                                                              onChange={this.handleChange}/>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                                <hr/>
                                <Tab.Content>
                                    <Tab.Pane eventKey="services">


                                                <Services ref={this.numberOfUserServices}/>

                                    </Tab.Pane>
                                </Tab.Content>
                            </Col>
                        </Row>
                    </Tab.Container>
                </Card>

            </div>
        );
    }
}

export default Statistics;