import React, {Component} from 'react';
import {AppContext} from "../AppContext";

class Utils extends Component {


    static contextType = AppContext;

    static getExtensionFromFilename(filename) {
        var fileExtensionPattern = /\.([0-9a-z]+)(?=[?#])|(\.)(?:[\w]+)$/gmi
        return filename.match(fileExtensionPattern)[0];
    }

    static getServiceTypeFromEnumValue(enumValue) {
        if (enumValue === 'INSTALACJA') return 'INSTALACJA'
        if (enumValue === 'SZKOLENIE_OPERATOROW') return 'SZKOLENIE OPERATORÓW'
        if (enumValue === 'PRZEGLAD_OKRESOWY') return 'PRZEGLĄD OKRESOWY'
        if (enumValue === 'NAPRAWA') return 'NAPRAWA'
        if (enumValue === 'PROTOKOL_PRZEDMONTAZOWY') return 'PROTOKÓŁ PRZEDMONTAŻOWY'
        if (enumValue === 'MONTAZ') return 'PROTOKÓŁ MONTAŻU'
    }

    //Zamienia string z wartościami enum z DB na Array z nazwami pełnymi
    static getServiceTypesNamesAsArray(string){
        let array = JSON.parse(string.replace('[','["').replace(']','"]').replaceAll(',','","').replaceAll(" ", ""));
        console.log(array)
        return array.map(type => this.getServiceTypeFromEnumValue(type))
    }


    render() {
        return (
            <div>

            </div>
        );
    }
}

export default Utils;


