import React, {Component} from "react";
import {AppContext} from "../../AppContext";
import {Alert, Button, Card, CardGroup, Dropdown, Figure, Form, Image, Modal} from "react-bootstrap";
import axios from "axios";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import DbUser from "../../queries/DbUser";
import {Redirect} from "react-router-dom";


class MachineNamePlates extends Component {

    static contextType = AppContext;

    state = {
        id: 0,
        isShowedModalNewFile: false,
        selectedFile: null,
        files: [],
        currentDescription: '',
        currentType: 'MACHINE_NAMEPLATE',
        currentFilename: '',
        currentFile: null,
        errorNoSelectedFile: false,
        isShowedModalPreviewImage: false,
        currentImageUrl: '',
        isShowedModalEditDescription: false,
        errorMessage: '',
        errorEditMessage: ''
    }

    componentDidMount() {
        DbUser.getLoggedUser(this.context)

        axios.get(`${this.context.hostname}/api/file/findByDeviceAndType/${this.props.idDevice}/MACHINE_NAMEPLATE`, {
            headers: {"X-Requested-With": "XMLHttpRequest"},
            withCredentials: true,
        }).then(res => {
            this.setState({
                files: res.data,
                isDataLoaded: true,
                errorMessage: '',
                errorEditMessage: ''
            })
        })
            .catch((error) => {
                console.log(error)
            });
    }


    handleOnClickNewNamePlate = () => {
        this.setState({
            isShowedModalNewFile: true,
            selectedFile: null,
            errorNoSelectedFile: false,
            currentDescription: ''
        })
    }

    upload = () => {


        var formData = new FormData()

        if (this.state.selectedFile !== null) {
            var input = document.querySelector('input[type="file"]')
            formData.append('file', input.files[0], this.state.currentFilename)

            formData.set("fileJson", "{device: {id:" + this.props.idDevice + "}, type:'MACHINE_NAMEPLATE',"
                + "filename:'" + this.state.currentFilename + "',description:'" + this.state.currentDescription + "'}")
            axios.post(`${this.context.hostname}/api/file/add`, formData, {
                    headers: {
                        "X-Requested-With": "XMLHttpRequest",
                        "Content-Type": "application/json",
                    },
                    withCredentials: true,
                }
            ).then(res => {
                if (res.status === 200) {
                    this.componentDidMount()
                    this.setState({
                        isShowedModalNewFile: false
                    })
                }
            })
                .catch((error) => {
                    console.log({error})
                });
        } else {
            this.setState({...this.state, errorNoSelectedFile: true})
        }
    };

    handleOnChangeNewFile = (e) => {

        this.setState({
            selectedFile: e.target.files[0],
            currentFilename: e.target.files[0].name

        });
    }

    handleChange = (e) => {
        this.setState({[e.target.name]: e.target.value})
    }

    handleShowImageOnClick = (e, data) => {
        this.setState({
            ...this.state,
            isShowedModalPreviewImage: true,
            currentImageUrl: e.target.src,
            currentDescription: e.target.name
        })

    }

    handleOnClickDeleteFile = e => {
        let filename = e.target.name
        let fileId = e.target.id

        axios.delete(`${this.context.hostname}/api/file/delete/${fileId}`, {
            headers: {"X-Requested-With": "XMLHttpRequest"},
            withCredentials: true,
        }).then(res => {
            if (res.status === 200) {
                this.componentDidMount()
            }
        })
            .catch((error) => {
                console.log({error})
                let errorMessage = 'Nieznany błąd.';
                if (error.response.status === 403) {
                    errorMessage = 'Brak uprawnień do tej operacji.'
                }
                this.setState({...this.state, errorMessage: errorMessage})
            });
    }

    handleOnClickEditDescription = e => {
        let fileId = e.target.id;
        this.setState({
            ...this.state,
            isShowedModalEditDescription: true,
            currentDescription: e.target.name,
            id: fileId,
            errorEditMessage: ''
        })
    }

    handleChangeFileDescription = e => {

            axios.patch(`${this.context.hostname}/api/file/updateDescription`, {
                    headers: {
                        "X-Requested-With": "XMLHttpRequest",
                        "Content-Type": "application/json",
                    },
                    "id": this.state.id,
                    "description": this.state.currentDescription
                },
                {withCredentials: true}
            ).then(res => {
                if (res.status === 200) {
                    console.log(res)
                    this.componentDidMount()
                    this.setState({isShowedModalEditDescription: false})
                }
            })
                .catch((error) => {
                    console.log({error})
                    let errorMessage = 'Nieznany błąd.';
                    if (error.response.status === 403) {
                        errorMessage = 'Brak uprawnień do tej operacji.'
                    }
                    this.setState({...this.state, errorEditMessage: errorMessage})
                });

    }

    render() {

        return (
            <div>
                {this.context.redirectToLoginPage ? <Redirect to="/login"/> : ''}

                <Card style={{margin: '3px'}} border="white">
                    <Card.Body style={{padding: '5px', margin: '3px'}}>
                        <Button
                            className="float-end"
                            variant="outline-dark"
                            onClick={this.handleOnClickNewNamePlate}
                        >+</Button>
                    </Card.Body>

                </Card>
                {this.state.errorMessage != '' ?
                    <Alert variant='danger' style={{marginTop: 10}}>
                        {this.state.errorMessage}
                    </Alert> : false
                }
                <Row style={{paddingTop: 0, paddingLeft: 14, paddingRight: 14}}>

                    {this.state.files.map(nameplate =>
                        <Col className="row justify-content-md-center cards"
                             style={{paddingTop: 0}}>

                            <Card style={{height: '250px', width: '240px', marginTop: '5px', paddingLeft: 0}}

                            >
                                <div>
                                    <Image name={nameplate.filename}
                                           className='imageClick'
                                           onClick={this.handleShowImageOnClick}
                                           style={{height: '160px', maxWidth: '240px'}}
                                           src={`${this.context.hostname}/api/storage/downloadFile?fileName=${nameplate.filename}`}
                                           name={nameplate.description}
                                           rounded/>
                                </div>
                                <Card.Body>
                                    <Card.Text>
                                        <Dropdown size="sm">
                                            <Dropdown.Toggle id="dropdown-basic" size="sm" variant="light"
                                                             className='float-end' style={{backgroundColor: 'white'}}>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu size="sm">
                                                <Dropdown.Item size="sm" id={nameplate.id}
                                                               onClick={this.handleOnClickDeleteFile}>Usuń</Dropdown.Item>
                                                <Dropdown.Item size="sm" id={nameplate.id} name={nameplate.description}
                                                               onClick={this.handleOnClickEditDescription}>Edytuj
                                                    opis</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>

                                        {nameplate.description}
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    )}
                </Row>


                {/**********************MODAL ADD NAMEPLATE********************************/}
                <Modal show={this.state.isShowedModalNewFile}
                       onHide={() => this.setState({isShowedModalNewFile: false})}>
                    <Modal.Header closeButton>
                        <Modal.Title>Nowy plik</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>


                        {this.state.errorNoSelectedFile === true ?
                            <Alert variant='danger'>
                                Nie wybrano pliku!
                            </Alert> : false}

                        <Form.Control
                            type="text"
                            as="textarea"
                            rows={3}
                            placeholder="Wprowadź opis."
                            value={this.state.currentDescription}
                            name='currentDescription'
                            onChange={this.handleChange}
                        />
                        <br/>
                        <Form.Control
                            type="file"
                            accept="image/*"
                            onChange={this.handleOnChangeNewFile}
                        />

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => this.setState({isShowedModalNewFile: false})}>
                            Anuluj
                        </Button>
                        <Button variant="primary" onClick={this.upload}>
                            Dodaj
                        </Button>
                    </Modal.Footer>
                </Modal>

                {/*************************MODAL PREVIEW IMAGE********************************/}

                <Modal
                    show={this.state.isShowedModalPreviewImage}
                    onHide={() => this.setState({...this.state, isShowedModalPreviewImage: false})
                    }
                    fullscreen
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="example-custom-modal-styling-title">
                            {this.state.currentDescription}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='d-flex justify-content-center'>

                            <Image
                                style={{maxWidth: '100%'}}
                                src={`${this.state.currentImageUrl}`}
                                rounded/>
                        </div>
                    </Modal.Body>

                </Modal>

                {/*************************** MODAL EDIT DESCRIPTION ***********************************/}
                <Modal show={this.state.isShowedModalEditDescription}
                       onHide={() => this.setState({isShowedModalEditDescription: false})}>
                    <Modal.Header closeButton>
                        <Modal.Title>Edycja opisu</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.state.errorEditMessage != '' ?
                            <Alert variant='danger' style={{marginTop: 10}}>
                                {this.state.errorEditMessage}
                            </Alert> : false
                        }
                        <Form.Control
                            type="text"
                            as="textarea"
                            rows={3}
                            placeholder="Wprowadź opis."
                            value={this.state.currentDescription}
                            name='currentDescription'
                            onChange={this.handleChange}
                        />

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary"
                                onClick={() => this.setState({isShowedModalEditDescription: false})}>
                            Anuluj
                        </Button>
                        <Button variant="primary" onClick={this.handleChangeFileDescription}>
                            Zapisz
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

export default MachineNamePlates;
