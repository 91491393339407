import React, {Component} from 'react';
import {Alert, Button, Card, Col, Form, Modal, Row, Spinner} from "react-bootstrap";
import {AppContext} from "../../AppContext";
import {Redirect} from "react-router-dom";
import ToolkitProvider, {Search} from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import DbUser from "../../queries/DbUser";
import axios from "axios";


const defaultSorted = [{
    dataField: 'id',
    order: 'desc'
}];

const {SearchBar} = Search;

const searchProps = {
    delay: 800,
    placeholder: "wpisz aby wyszukać",
};

const sizePerPageRenderer = ({
                                 options,
                                 currSizePerPage,
                                 onSizePerPageChange
                             }) => (
    <div className="btn-group" role="group">
        {
            options.map((option) => {
                const isSelect = currSizePerPage === `${option.page}`;
                return (
                    <button
                        key={option.text}
                        type="button"
                        onClick={() => onSizePerPageChange(option.page)}
                        className={`btn ${isSelect ? 'btn-secondary' : 'btn-warning'}`}
                    >
                        {option.text}
                    </button>
                );
            })
        }
    </div>
);

const options = {
    sizePerPageRenderer
};


class MachineTodo extends Component {

    static contextType = AppContext;

    state = {
        data: [],
        isDataLoaded: false,
        edit: false,
        redirect: '',
        isShowedModalEditTodo: false,
        currentToDo: {
            id: '',
            toDoStatus: '',
            description: '',
            date: '',
            userCreated: {fullName: ''},
            service: {
                id: '',
                date: '',
                customer: {
                    fullName: '',
                    streetAddress: '',
                    zipCode: '',
                    city: '',
                    devices: []
                },
                toDoStatus: 'TODO',
                devices: []
            }
        },
        isShowAll: false,
        errorMessage: '',
        showAddToDo: false,
        editModalState: ''
    }

    componentDidMount() {
        DbUser.getLoggedUser(this.context)
        this.getTodoList(this.state.isShowAll ? 'ALL' : 'TODO')
    }

    getTodoList(status) {
        const res = axios.get(`${this.context.hostname}/api/todo/get?toDoStatus=${status}`, {
            headers: {"X-Requested-With": "XMLHttpRequest"},
            withCredentials: true,
        }).then(res => {
            if (res.status = 200) {
                this.setState({
                    ...this.state,
                    data: res.data,
                    isDataLoaded: true
                })
            }
        })
            .catch((error) => {
                console.log(error)
            });
    }


    handleBackOnClick = () => {
        this.setState({redirect: '/home'})
    }

    handleChangeTodoStatus = (e) => {
        this.setState({
            ...this.state,
        })

        this.setState({
            ...this.state,
            currentToDo: {
                ...this.state.currentToDo,
                toDoStatus: e.target.checked ? 'DONE' : 'TODO',
                isCheckedDone: e.target.checked
            }
        })
    }

    handleChangeTodo = (e) => {
        this.setState({
            ...this.state,
            currentToDo: {
                ...this.state.currentToDo,
                description: e.target.value
            }
        })
    }

    handleSaveOnClick = () => {
        if (this.state.editModalState === 'EDIT_SERVICE') {
            axios.patch(`${this.context.hostname}/api/service/changeTodoStatus`, {
                    headers: {
                        "X-Requested-With": "XMLHttpRequest",
                        "Content-Type": "application/json",
                    },
                    "id": this.state.currentToDo.id,
                    "toDoStatus": this.state.currentToDo.toDoStatus,
                    "todo": this.state.currentToDo.description
                },
                {withCredentials: true}
            ).then(res => {
                if (res.status === 200) {
                    this.componentDidMount()
                    this.setState({isShowedModalEditTodo: false})
                }
            })
                .catch((error) => {
                    console.log({error})
                    let errorMessage = 'Nieznany błąd.';
                    if (error.response.status === 403) {
                        errorMessage = 'Brak uprawnień do tej operacji.'
                    }
                    this.setState({...this.state, errorMessage: errorMessage})
                });

        } else {

            axios.post(`${this.context.hostname}/api/todo/save`, {
                    headers: {
                        "X-Requested-With": "XMLHttpRequest",
                        "Content-Type": "application/json",
                    },
                    "id": this.state.currentToDo.id,
                    "toDoStatus": this.state.currentToDo.toDoStatus,
                    "description": this.state.currentToDo.description,
                    "date": new Date().toISOString()
                },
                {withCredentials: true}
            ).then(res => {
                if (res.status === 200) {
                    this.componentDidMount()
                    this.setState({isShowedModalEditTodo: false})
                }
            })
                .catch((error) => {
                    console.log({error})
                    let errorMessage = 'Nieznany błąd.';
                    if (error.response.status === 403) {
                        errorMessage = 'Brak uprawnień do tej operacji.'
                    }
                    this.setState({...this.state, errorMessage: errorMessage})
                });
        }
    }

    handleShowAll = e => {
        const checked = e.target.checked
        this.setState({...this.state, isShowAll: checked})

        if (checked) {
            this.getTodoList('ALL')
        } else {
            this.getTodoList('TODO')
        }
    }

    handleDeleteToDo = (e) => {
        if (this.state.currentToDo.service === null) {
            axios.delete(`${this.context.hostname}/api/todo/delete?id=${this.state.currentToDo.id}`, {
                headers: {"X-Requested-With": "XMLHttpRequest"},
                withCredentials: true,
            }).then(res => {
                if (res.status === 200) {
                    this.componentDidMount()
                    this.setState({isShowedModalEditTodo: false, errorMessage: ''})
                }
            })
                .catch((error) => {
                    console.log({error})
                    let errorMessage = 'Nieznany błąd.';
                    if (error.response.status === 403 || error.response.status === 401) {
                        errorMessage = 'Brak uprawnień do tej operacji.'
                    }
                    this.setState({...this.state, errorMessage: errorMessage})
                });
        }
    }

    render() {

        const onClickEvent = {
            onClick: (e, column, columnIndex, row, rowIndex) => {
                let status;
                if (row.service === null) status = 'EDIT_TODO'; else status = 'EDIT_SERVICE'


                this.setState({
                    ...this.state,
                    isShowedModalEditTodo: true, editModalState: status,
                    currentToDo: {...row, isCheckedDone: row.toDoStatus === 'DONE'}
                })
            }
        }

        const columns = [{
            dataField: 'id',
            text: 'Nr',
            sort: true,
            headerAlign: 'center',
            events: onClickEvent,
            style: {width: '3%'}

        }, {
            dataField: 'date',
            text: 'Data',
            sort: true,
            headerAlign: 'center',
            formatter: cell => `${cell != null ? cell.substring(0, 10) : false}`,
            events: onClickEvent,
            style: {width: '6%'}

        }, {
            dataField: 'service.id',
            headerAlign: 'center',
            text: 'Serwis',
            sort: true,
            events: onClickEvent,
            formatter: cell => `${cell != null ? '🗹' : '☐'}`,
            style: {width: '6%'},
            align: 'center',
        }, {
            dataField: 'service.customer',
            headerAlign: 'center',
            text: 'Kontrahent / Adres maszyny',
            sort: true,
            events: onClickEvent,
            style: {width: '16%'},
            filterValue: cell => cell != null ? `${cell.shortName} ${cell.streetAddress} ${cell.zipCode} ${cell.city}` : '',
            formatter: cell => cell != null ? <>{cell.shortName}<br/>{cell.streetAddress}, {cell.zipCode} {cell.city}</> : ''
        }, {
            dataField: 'service.devices',
            text: 'Nazwa maszyny',
            sort: true,
            headerAlign: 'center',
            align: 'center',
            events: onClickEvent,
            formatter: devicesFormatter,
            style: {width: '11%'},
        }, {
            dataField: 'description',
            headerAlign: 'center',
            text: 'Opis',
            sort: true,
            formatter: todoFormatter,
            events: onClickEvent,
        }, {
            dataField: 'toDoStatus',
            headerAlign: 'center',
            align: 'center',
            text: 'Status',
            sort: true,
            events: onClickEvent,
            style: {width: '6%'},
            filterValue: cell => `${cell}`,

            formatter: checkboxFormatter,
            formatExtraData: {
                context: this.context,
            }
        }];

        function devicesFormatter(cell, row, rowIndex, formatExtraData) {
            let devices = "";
            if (cell != null) {
                cell.map(d =>
                    devices = <>{devices}({d.id}) {d.name}<br/></>
                )
            }
            return devices;
        }

        function checkboxFormatter(cell, row, rowIndex, formatExtraData) {
            return (<>
                    {row.toDoStatus === 'DONE' ? <>✅</> : <>☐</>}
                </>
            );
        }

        function todoFormatter(cell, row, rowIndex, formatExtraData) {
            let value = cell;
            if (cell != null && cell != undefined && cell.length > 300) {
                value = cell.substring(0, 299) + "..."
            }
            return value;
        }

        return (
            <div className="fragment">
                {this.context.redirectToLoginPage ? <Redirect to="/login"/> : ''}
                {this.state.redirect != '' ? <Redirect to={this.state.redirect}/> : ''}
                <ToolkitProvider
                    bootstrap3
                    keyField="id"
                    data={this.state.data}
                    columns={columns}
                    defaultSorted={defaultSorted}
                    bordered={false}
                    noDataIndication=""
                    search
                >
                    {
                        props => (
                            <div>
                                <h3>
                                    <Button variant="light" onClick={this.handleBackOnClick}>
                                        <svg width="1em" height="1em" viewBox="0 0 16 16"
                                             className="bi bi-arrow-return-left"
                                             fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd"
                                                  d="M14.5 1.5a.5.5 0 0 1 .5.5v4.8a2.5 2.5 0 0 1-2.5 2.5H2.707l3.347 3.346a.5.5 0 0 1-.708.708l-4.2-4.2a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 8.3H12.5A1.5 1.5 0 0 0 14 6.8V2a.5.5 0 0 1 .5-.5z"/>
                                        </svg>
                                    </Button>
                                    &nbsp;Zadania do wykonania
                                    {this.context.isAdmin ?
                                        <Button
                                            className="float-end"
                                            variant="outline-dark"
                                            onClick={() => this.setState({
                                                ...this.state,
                                                isShowedModalEditTodo: true,
                                                editModalState: 'ADD',
                                                currentToDo: {
                                                    id: '',
                                                    toDoStatus: 'TODO',
                                                    description: '',
                                                    date: '',
                                                    userCreated: {fullName: ''},
                                                    service: {
                                                        id: '',
                                                        date: '',
                                                        customer: {
                                                            fullName: '',
                                                            streetAddress: '',
                                                            zipCode: '',
                                                            city: '',
                                                            devices: []
                                                        },
                                                        toDoStatus: 'TODO',
                                                        devices: []
                                                    }
                                                },
                                            })}
                                        >
                                            +
                                        </Button>
                                        : false}
                                </h3>

                                <SearchBar
                                    {...props.searchProps}
                                    srText=''
                                    placeholder="Wpisz aby wyszukać..."
                                />

                                <Form.Check
                                    checked={this.state.isShowAll}
                                    type="checkbox"
                                    label='Pokaż zakończone'
                                    onClick={this.handleShowAll}
                                />
                                <hr/>
                                <BootstrapTable
                                    bootstrap3
                                    keyField="id"
                                    data={this.state.data}
                                    columns={columns}
                                    defaultSorted={defaultSorted}
                                    bordered={false}
                                    noDataIndication=""
                                    pagination={paginationFactory(options)}
                                    {...props.baseProps}
                                />
                            </div>
                        )
                    }
                </ToolkitProvider>
                {!this.state.isDataLoaded &&
                <div className={"container"} style={{textAlign: "center"}}><Spinner animation="border"/></div>}


                {/*-------------------------------------------EDIT MODAL------------------------------------*/}
                <Modal show={this.state.isShowedModalEditTodo}
                       onHide={() => this.setState({isShowedModalEditTodo: false})}>
                    <Modal.Header closeButton>
                        <Modal.Title>Zadanie do wykonania</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.state.errorMessage != '' ?
                            <Alert variant='danger'>
                                {this.state.errorMessage}
                            </Alert> : false
                        }
                        <Card>
                            <Card.Header as="h5">
                                <Row>
                                    <Col>
                                        {this.state.currentToDo.userCreated !== null ?
                                            this.state.currentToDo.userCreated.fullName
                                            : this.state.currentToDo.service.userCreated.fullName
                                        }
                                    </Col>
                                    <Col>
                                        {this.state.editModalState === 'EDIT_SERVICE' ?
                                            <div
                                                className='float-end'>{this.state.currentToDo.date.substring(0, 10)}</div>
                                            :
                                            <div className='float-end'>{new Date().toISOString().slice(0, 10)}</div>
                                        }
                                    </Col>
                                </Row>
                            </Card.Header>
                            <Card.Body>
                                {this.state.currentToDo.service !== null && this.state.currentToDo.service.id !== '' ?
                                    <Card.Title>
                                        <>{this.state.currentToDo.service.devices.map(
                                            value => <>({value.id}) {value.name}</>
                                        )}<br/>
                                            {this.state.currentToDo.service.customer.fullName} </>
                                    </Card.Title> : false}
                                <Card.Text>
                                    {(this.state.currentToDo.service !== null && this.state.currentToDo.service.id !== '') ?
                                        <>
                                            {this.state.currentToDo.service.date.substring(0, 10)}<br/>
                                            {this.state.currentToDo.service.customer.streetAddress}<br/>
                                            {this.state.currentToDo.service.customer.zipCode}&nbsp;
                                            {this.state.currentToDo.service.customer.city}<br/>
                                            Nr serwisu: {this.state.currentToDo.id}
                                        </> : false}
                                </Card.Text>
                                <Form.Control
                                    disabled={!this.context.isAdmin}
                                    type="text"
                                    as="textarea"
                                    rows={7}
                                    onChange={this.handleChangeTodo}
                                    style={{whiteSpace: 'pre-line'}}
                                    value={this.state.currentToDo.description}
                                    name='todo'
                                />
                                <br/>
                                <Form.Check
                                    disabled={!this.context.isAdmin}
                                    className='float-end'
                                    type="checkbox"
                                    label='zakończone'
                                    checked={this.state.currentToDo.isCheckedDone}
                                    onChange={this.handleChangeTodoStatus}
                                />

                            </Card.Body>
                        </Card>
                    </Modal.Body>
                    <Modal.Footer>
                        {this.state.currentToDo.service === null && this.context.isAdmin?
                        <Button variant="secondary" className="mr-auto" onClick={this.handleDeleteToDo}>
                            Usuń
                        </Button> : false}
                        <Button variant="secondary" onClick={() => this.setState({isShowedModalEditTodo: false})}>
                            Anuluj
                        </Button>
                        {this.context.isAdmin ?
                            <Button variant="primary" onClick={this.handleSaveOnClick}>
                                Zapisz
                            </Button> : false}
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

export default MachineTodo;