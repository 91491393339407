import React, {Component} from "react";
import Card from "react-bootstrap/Card";
import {Dropdown, Button, Form, Modal, Alert} from "react-bootstrap";
import {AppContext} from "../../AppContext";
import axios from "axios";
import DbUser from "../../queries/DbUser";
import {Redirect} from "react-router-dom";


class MachineFiles extends Component {

    static contextType = AppContext;

    state = {
        isShowedModalNewFile: false,
        selectedFile: null,
        files: [],
        currentDescription: '',
        currentType: 'MACHINE_FILE',
        currentFilename: '',
        currentFile: null,
        errorNoSelectedFile: false,
        errorMessage: ''
    }


    componentDidMount() {
        DbUser.getLoggedUser(this.context)

        axios.get(`${this.context.hostname}/api/file/findByDeviceAndType/${this.props.idDevice}/MACHINE_FILE`, {
            headers: {"X-Requested-With": "XMLHttpRequest"},
            withCredentials: true,
        }).then(res => {
            this.setState({
                files: res.data,
                isDataLoaded: true,
                errorMessage: ''
            })
        })
            .catch((error) => {
                console.log(error)
            });
    }

    handleOnClickFile = e => {

        const fileName = e.target.value;

        axios.get(`${this.context.hostname}/api/storage/downloadFile?fileName=${encodeURI(e.target.value)}`, {
            headers: {"X-Requested-With": "XMLHttpRequest"},
            responseType: 'blob',
            withCredentials: true,
        }).then(res => {
            if (res.status = 200) {
                var url = window.URL.createObjectURL(res.data);
                var a = document.createElement('a');
                a.href = url;
                a.download = (fileName);
                document.body.appendChild(a);
                a.click();
                a.remove();
            }
        })
            .catch((error) => {
                console.log(error)
            });
    }

    handleOnClickAddNewFile = () => {
        this.setState({isShowedModalNewFile: true, selectedFile: null, errorNoSelectedFile: false, currentDescription: ''})
    }

    handleOnChangeNewFile = (e) => {

        this.setState({
            selectedFile: e.target.files[0],
            currentFilename: e.target.files[0].name

        });
    }

    upload = () => {


            var formData = new FormData()

            if (this.state.selectedFile !== null) {
                var input = document.querySelector('input[type="file"]')
                formData.append('file', input.files[0], this.state.currentFilename)

                formData.set("fileJson", "{device: {id:" + this.props.idDevice + "}, type:'MACHINE_FILE',"
                    + "filename:'" + this.state.currentFilename + "',description:'" + this.state.currentDescription + "'}")
                axios.post(`${this.context.hostname}/api/file/add`, formData, {
                        headers: {
                            "X-Requested-With": "XMLHttpRequest",
                            "Content-Type": "application/json",
                        },
                        withCredentials: true,
                    }
                ).then(res => {
                    if (res.status === 200) {
                        this.componentDidMount()
                        this.setState({
                            isShowedModalNewFile: false
                        })
                    }
                })
                    .catch((error) => {
                        console.log({error})
                    });
            } else {
                this.setState({...this.state, errorNoSelectedFile: true})
            }
    };

    handleChange = (e) => {
        this.setState({[e.target.name]: e.target.value})
    }

    handleOnClickDeleteFile = e => {
        let filename = e.target.name
        let fileId = e.target.id

        axios.delete(`${this.context.hostname}/api/file/delete/${fileId}`, {
            headers: {"X-Requested-With": "XMLHttpRequest"},
            withCredentials: true,
        }).then(res => {
            if (res.status === 200) {
                this.componentDidMount()
            }
        })
            .catch((error) => {
                console.log({error})
                let errorMessage = 'Nieznany błąd.';
                if (error.response.status === 403) {
                    errorMessage = 'Brak uprawnień do tej operacji.'
                }
                this.setState({...this.state, errorMessage: errorMessage})
            });
    }

    render() {
        return (
            <div>
                {this.context.redirectToLoginPage ? <Redirect to="/login"/> : ''}

                <Modal show={this.state.isShowedModalNewFile}
                       onHide={() => this.setState({isShowedModalNewFile: false})}>
                    <Modal.Header closeButton>
                        <Modal.Title>Nowy plik</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>


                        {this.state.errorNoSelectedFile === true ?
                            <Alert variant='danger'>
                                Nie wybrano pliku!
                            </Alert> : false}

                        <Form.Control
                            type="text"
                            as="textarea"
                            rows={3}
                            placeholder="Wprowadź opis."
                            value={this.state.currentDescription}
                            name='currentDescription'
                            onChange={this.handleChange}
                        />
                        <br/>
                        <Form.Control
                            type="file"
                            onChange={this.handleOnChangeNewFile}
                        />

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => this.setState({isShowedModalNewFile: false})}>
                            Anuluj
                        </Button>
                        <Button variant="primary" onClick={this.upload}>
                            Dodaj
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Card style={{margin: '3px'}} border="white">
                    <Card.Body style={{padding: '5px', margin: '3px'}}>
                        <Button
                            className="float-end"
                            variant="outline-dark"
                            onClick={this.handleOnClickAddNewFile}
                        >+</Button>
                    </Card.Body>

                </Card>
                {this.state.errorMessage != '' ?
                    <Alert variant='danger' style={{marginTop: 10}}>
                        {this.state.errorMessage}
                    </Alert> : false
                }
                {this.state.files.map(
                    file =>
                        <Card style={{margin: '3px'}}>
                            <Card.Body style={{padding: '5px', margin: '3px'}}>

                                <Dropdown size="sm">
                                    <Dropdown.Toggle id="dropdown-basic" size="sm" variant="light"
                                                     className='float-end' style={{backgroundColor: 'white'}}>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu size="sm">
                                        <Dropdown.Item id={file.id} name={file.filename} size="sm"
                                                       onClick={this.handleOnClickDeleteFile}>Usuń</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                                {file.description}
                                <div><Button variant="link" size="sm"
                                             onClick={this.handleOnClickFile}
                                             value={file.filename}>{file.filename}</Button><br/>
                                </div>
                            </Card.Body>
                        </Card>
                )}
            </div>

        )
    }
}

function Padder(len, pad) {
    if (len === undefined) {
        len = 1;
    } else if (pad === undefined) {
        pad = '0';
    }

    var pads = '';
    while (pads.length < len) {
        pads += pad;
    }

    this.pad = function (what) {
        var s = what.toString();
        return pads.substring(0, pads.length - s.length) + s;
    };
}

export default MachineFiles;
