import React, {Component} from 'react';
import {AppContext} from "../../../AppContext";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {Search} from "react-bootstrap-table2-toolkit";
import DbUser from "../../../queries/DbUser";
import axios from "axios";
import {Alert, Spinner} from "react-bootstrap";
import Card from "react-bootstrap/Card";

class Services extends Component {

    static contextType = AppContext;


    state = {
        servicesUsers: [],
        alert: '',
        isLoading: false
    }

    componentDidMount() {
        DbUser.getLoggedUser(this.context)

        this.getData(this.context.statistics.searchDateFrom, this.context.statistics.searchDateTo)

    }


    getData(dateFrom, dateTo) {
        this.setState({...this.state, isLoading: true, alert: ''})
        if (dateFrom != '') dateFrom = dateFrom + 'T00:00'
        if (dateTo != '') dateTo = dateTo + 'T00:00'

        const res = axios.get(`${this.context.hostname}/api/statistics/servicesUsers?dateFrom=${dateFrom}&dateTo=${dateTo}`, {
            headers: {"X-Requested-With": "XMLHttpRequest"},
            withCredentials: true,
        }).then(res => {
            if (res.status = 200) {
                this.setState({
                    servicesUsers: res.data,
                    isLoading: false,
                    alert: ''
                })
            }
        })
            .catch((error) => {
                console.log(error)

                this.setState({
                    ...this.state,
                    servicesUsers: [],
                    alert: 'Błąd przy pobieraniu danych',
                    isLoading: false
                })
            });
    }

    countAllServices() {
        let number = 0
        this.state.servicesUsers.forEach(s =>
        number = number + s.countUserServices)
        return number
    }

    countSignedServices() {
        let number = 0
        this.state.servicesUsers.forEach(s =>
            number = number + s.countUserSignedServices)
        return number
    }


    render() {

        const onClickEvent = {

            onClick: (e, column, columnIndex, row, rowIndex) => {
                // const link = `/categoryEdit?id=${row.id}&name=${row.name.replaceAll("&", "%26")}`
                // this.setState({redirect: link})
            }
        }

        const columns = [{
            dataField: 'userId',
            text: 'Id pracownika',
            sort: true,
            align: 'center',
            headerAlign: 'center',
            events: onClickEvent
        }, {
            dataField: 'lastName',
            text: 'Nazwisko',
            sort: true,
            headerAlign: 'center',
            events: onClickEvent
        }, {
            dataField: 'firstName',
            text: 'Imię',
            sort: true,
            headerAlign: 'center',
            events: onClickEvent
        }, {
            dataField: 'countUserServices',
            text: 'Ilość wystawionych protokołów serwisowych',
            sort: true,
            align: 'center',
            headerAlign: 'center',
            events: onClickEvent
        }, {
            dataField: 'countUserSignedServices',
            text: 'Ilość podpisanych protokołów serwisowych',
            sort: true,
            align: 'center',
            headerAlign: 'center',
            events: onClickEvent
        }];


        return (

            <>

                {this.state.alert !== '' ?
                    <Alert variant='danger'>{this.state.alert}</Alert> : false}

                <Card>
                    <Card.Header as="h6">Liczba wystawionych protokołów serwisowych sumarycznie
                        &nbsp;{this.state.isLoading ? <Spinner animation="border" size="sm" /> : false}
                    </Card.Header>
                    <Card.Body style={{fontSize: 13}}>
                        Ilość wystawionych protokołów serwisowych: {this.countAllServices()}<br/>
                        Ilość podpisanych protokołów serwisowych: {this.countSignedServices()}
                    </Card.Body>
                </Card>
                <Card style={{marginTop: 10}}>
                    <Card.Header as="h6">Liczba wystawionych protokołów serwisowych z podziałem
                        na pracowników
                        &nbsp;{this.state.isLoading ? <Spinner animation="border" size="sm" /> : false}
                    </Card.Header>
                    <Card.Body>
                        <div style={{fontSize: 13}}>
                            <ToolkitProvider
                                bootstrap3
                                keyField="id"
                                data={this.state.servicesUsers}
                                columns={columns}
                                defaultSorted={defaultSorted}
                                bordered={false}
                                noDataIndication=""
                                search>
                                {
                                    props => (
                                        <div>

                                            {/*<SearchBar*/}
                                            {/*    {...props.searchProps}*/}
                                            {/*    placeholder="Wpisz aby wyszukać..."*/}
                                            {/*/>*/}
                                            <hr/>
                                            <BootstrapTable
                                                bootstrap3
                                                keyField="id"
                                                data={this.state.servicesUsers}
                                                columns={columns}
                                                defaultSorted={defaultSorted}
                                                bordered={false}
                                                noDataIndication=""
                                                pagination={paginationFactory(options)}
                                                {...props.baseProps}
                                                style={{fontSize: 5}}
                                                wrapperClasses="table-responsive"

                                            />
                                        </div>
                                    )
                                }
                            </ToolkitProvider>
                        </div>
                    </Card.Body>
                </Card>
            </>
        );
    }
}

export default Services;


const defaultSorted = [{
    dataField: 'id',
    order: 'desc'
}];

const {SearchBar} = Search;

const searchProps = {
    delay: 800,
    placeholder: "wpisz aby wyszukać",
};

const sizePerPageRenderer = ({
                                 options,
                                 currSizePerPage,
                                 onSizePerPageChange
                             }) => (
    <div className="btn-group" role="group">
        {
            options.map((option) => {
                const isSelect = currSizePerPage === `${option.page}`;
                return (
                    <button
                        style={{fontSize: 10}}
                        key={option.text}
                        type="button"
                        onClick={() => onSizePerPageChange(option.page)}
                        className={`btn ${isSelect ? 'btn-secondary' : 'btn-warning'}`}
                    >
                        {option.text}
                    </button>
                );
            })
        }
    </div>
);

const options = {
    sizePerPageRenderer
};