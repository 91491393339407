import React, {Component} from "react";
import {Alert, Button, Form, Modal} from "react-bootstrap";
import {Redirect} from "react-router-dom";
import '../../App.css';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {AppContext} from "../../AppContext";
import axios from "axios";
import DbUser from "../../queries/DbUser";

class UserAdd extends Component {

    static contextType = AppContext;

    state = {
        id: '',
        userName: '',
        password: '',
        email: '',
        firstName: '',
        lastName: '',
        country: '',
        isActive: true,
        isAdmin: false,
        vacationDays: 0,

        validatorMessage: '',
        validatorAlertVariant: 'danger',
        exit: false,
        redirect: '',
        generatePassword: true
    }

    componentDidMount() {
        DbUser.getLoggedUser(this.context)
    }

    handleChange = (e) => {
        let value = e.target.value;
        let name = e.target.name;
        if (value === 'on') {
            value = e.target.checked;
        }
        if (name === 'generatePassword') {
            value = e.target.checked;
        }
        this.setState(
            {
                [name]: value,
            }
        )
    }

    handleSubmit = (e) => {

        e.preventDefault()
        const value = e.target.userName.value;
        if (value.length < 3) {
            this.setState({
                validatorMessage: 'Stanowisko musi mieć min 3 znaki.'
            })
        } else {
            this.submitToAPI(
                this.state.userName,
                this.state.password,
                this.state.email,
                this.state.firstName,
                this.state.lastName,
                this.state.country,
                this.state.isActive,
                this.state.isAdmin,
                this.state.vacationDays)
            this.setState({
                shortName: '',
                validatorMessage: ''
            })
        }
    }

    submitToAPI = (userName, password, email, firstName, lastName, country, isActive, isAdmin, vacationDays) => {
        let role;
        if (isAdmin) {
            role = {id: 1, role: 'ADMIN'}
        } else {
            role = {id: 2, role: 'USER'}
        }

        axios.post(`${this.context.hostname}/api/user/insert`, {
                headers: {
                    "X-Requested-With": "XMLHttpRequest",
                    "Content-Type": "application/json",
                },
                "active": isActive,
                "country": country,
                "email": email,
                "firstName": firstName,
                "lastName": lastName,
                "password": password,
                "roles": [role],
                "userName": userName,
                "vacationDays": vacationDays
            },
            {withCredentials: true}
        ).then(res => {
            if (res.status === 200) {
                console.log(res)
                // this.setState({exit: true,})
                this.setState({
                    validatorMessage: 'Dodano pomyślnie użytkownika. Na podany adres email zostało wysłane hasło do konta.',
                    validatorAlertVariant: 'info',
                    id: '',
                    userName: '',
                    password: '',
                    email: '',
                    firstName: '',
                    lastName: '',
                    country: '',
                    isActive: true,
                    isAdmin: false,
                    vacationDays: 0,
                })
            }
        })
            .catch((error) => {
                console.log({error})
                let validatorMessage = 'Nieznany błąd.';
                if (error.response.status === 409) {
                    validatorMessage = 'Podany email już istnieje w bazie danych.'
                }
                this.setState({
                    ...this.state, validatorMessage,
                    validatorAlertVariant: 'danger'
                })

            });
    }

    handleDeleteOnClick = () => {
        this.setState({
            delete: true,
            showDeleteModal: false,
        });

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'email': `${this.context.login}`,
                'password': `${this.context.password}`
            },
        };
        fetch(`${this.context.hostname}/api/customer/delete?id=${this.state.id}`, requestOptions)
            .then(responseData => {
                if (responseData.status === 200) {
                    this.setState({exit: true,})
                } else {
                    this.setState({
                        validatorMessage: "Błąd! Sprawdź czy z kontrahentem są powiązane zależności (maszyny itp.). Musisz je najpierw usunąć!",
                        validatorAlertVariant: 'danger'
                    })

                }
                ;
            })
    }

    handleBackOnClick = () => {
        this.setState({redirect: '/userList'})
    }

    render() {

        if (this.state.exit === true) {
            return (<Redirect to="/userList"/>
            )
        }

        return (

            <div className="fragment">
                {this.context.redirectToLoginPage ? <Redirect to="/login"/> : ''}
                {this.state.redirect != '' ? <Redirect to={this.state.redirect}/> : ''}
                <Row>
                    <Col sm={5}>
                        <h3>
                            <Button variant="light" onClick={this.handleBackOnClick}>
                                <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-arrow-return-left"
                                     fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd"
                                          d="M14.5 1.5a.5.5 0 0 1 .5.5v4.8a2.5 2.5 0 0 1-2.5 2.5H2.707l3.347 3.346a.5.5 0 0 1-.708.708l-4.2-4.2a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 8.3H12.5A1.5 1.5 0 0 0 14 6.8V2a.5.5 0 0 1 .5-.5z"/>
                                </svg>
                            </Button>
                            &nbsp;Dodawanie pracownika
                        </h3>
                        <Form onSubmit={this.handleSubmit}>
                            <Form.Check type="checkbox"
                                        label="Aktywny"
                                        name="isActive"
                                        checked={this.state.isActive}
                                        onChange={this.handleChange}
                                        className='float float-end'/>
                            <Form.Check type="checkbox"
                                        label="Admin&nbsp;&nbsp;"
                                        name="isAdmin"
                                        onChange={this.handleChange}
                                        className='float float-end'/>

                            <Form.Group controlId="formBasicEmail">
                                <Form.Label>Stanowisko
                                </Form.Label>
                                <Form.Control
                                    type="text" name="userName"
                                    value={this.state.userName}
                                    placeholder="Wpisz..."
                                    onChange={this.handleChange}
                                />
                                <Form.Label>Imię
                                </Form.Label>
                                <Form.Control
                                    type="text" name="firstName"
                                    value={this.state.firstName}
                                    placeholder="Wpisz..."
                                    onChange={this.handleChange}
                                />
                                <Form.Label>Nazwisko
                                </Form.Label>
                                <Form.Control
                                    type="text" name="lastName"
                                    value={this.state.lastName}
                                    placeholder="Wpisz..."
                                    onChange={this.handleChange}
                                />
                                <Form.Label>Email
                                </Form.Label>
                                <Form.Control
                                    type="email" name="email"
                                    value={this.state.email}
                                    placeholder="Wpisz..."
                                    onChange={this.handleChange}
                                />

                                <Form.Label>Pozostałe dni urlopowe
                                </Form.Label>
                                <Form.Control
                                    type="number" name="vacationDays"
                                    value={this.state.vacationDays}
                                    placeholder="Wpisz..."
                                    onChange={this.handleChange}
                                    style={{marginBottom: 10}}
                                />

                                {this.state.validatorMessage != '' ?
                                    <Alert variant={this.state.validatorAlertVariant}>
                                        {this.state.validatorMessage}
                                    </Alert> : false
                                }
                            </Form.Group>
                            <Button
                                variant="outline-dark"
                                type="submit"
                                size="sm"
                                style={{marginRight: "5px"}}>
                                Dodaj
                            </Button>
                            {/*<Button*/}
                            {/*    size="sm"*/}
                            {/*    variant="outline-dark"*/}
                            {/*    onClick={() => this.setState({redirect: '/userList'})}>*/}
                            {/*    Anuluj*/}
                            {/*</Button>*/}
                        </Form>
                        <Modal show={this.state.showDeleteModal} onHide={() => this.setState({showDeleteModal: false})}>
                            <Modal.Header closeButton>
                                <Modal.Title>Potwierdzenie</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>Czy napewno chcesz usunąć: {this.state.shortName}</Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={() => this.setState({showDeleteModal: false})}>
                                    Anuluj
                                </Button>
                                <Button variant="primary" onClick={this.handleDeleteOnClick}>
                                    Usuń
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </Col>
                </Row>
            </div>
        )
    }
}

export default UserAdd;
