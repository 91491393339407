import React, {Component} from 'react';
import {AppContext} from "../AppContext";
import axios from "axios"; //

class DbHotel extends Component {

    static contextType = AppContext;

    static getHotelsAfterDeviceId (deviceId, context)  {
        axios.get(`${context.hostname}/api/deviceHotel/getAllByDevice/`+deviceId, {
            headers: {"X-Requested-With": "XMLHttpRequest"},
            withCredentials: true,
        }).then(res => {
            if (res.status = 200) {
                context.toggleMachineState({
                    ...context.machine,
                    hotels: res.data
                })
            }
        })
            .catch((error) => {
                console.log(error)
            });
    }




    render() {return (<div></div>);}

}

export default DbHotel;
