import Card from "react-bootstrap/Card";
import React, {Component} from "react";
import {Alert, Badge, Button, Form, Modal} from "react-bootstrap";
import {AppContext} from "../../AppContext";
import axios from "axios";
import DbUser from "../../queries/DbUser";
import {Redirect} from "react-router-dom";

class MachineNotes extends Component {

    static contextType = AppContext;

    state = {
        isShowedModalEditNote: false,
        isShowedModalNewNote: false,
        currentNote: '',
        currentIdNote: 0,
        deviceNotes: [],
        errorMessage: ''
    }

    componentDidMount() {
        DbUser.getLoggedUser(this.context)

        axios.get(`${this.context.hostname}/api/deviceNote/findByIdDevice/${this.props.idDevice}`, {
            headers: {"X-Requested-With": "XMLHttpRequest"},
            withCredentials: true,
        }).then(res => {
            if (res.status = 200) {
                this.setState({
                    deviceNotes: res.data, errorMessage: ''
                })
            }
        })
            .catch((error) => {
                console.log(error)
            });
    }

    handleOnClick = e => {
        this.setState({
            isShowedModalEditNote: true,
            currentNote: e.target.value,
            currentIdNote: e.target.id
        })
    }

    handleSaveOnClick = () => {
        console.log(this.state.currentIdNote)
        axios.put(`${this.context.hostname}/api/deviceNote/update`, {
                headers: {
                    "X-Requested-With": "XMLHttpRequest",
                    "Content-Type": "application/json",
                },
                "device": {
                    "id": this.props.idDevice
                },
                "id": this.state.currentIdNote,
                "text": this.state.currentNote
            },
            {withCredentials: true}
        ).then(res => {
            if (res.status === 200) {
                console.log(res)
                this.componentDidMount()
                this.setState({isShowedModalEditNote: false})
            }
        })
            .catch((error) => {
                console.log({error})
                let errorMessage = 'Nieznany błąd.';
                if (error.response.status === 403) {
                    errorMessage = 'Brak uprawnień do tej operacji.'
                }
                this.setState({...this.state, errorMessage: errorMessage})
            });
    }

    handleChange = (e) => {
        this.setState({currentNote: e.target.value})
    }

    handleOnClickAddNewDeviceNotes = () => {
        this.setState({isShowedModalNewNote: true})
    }

    handleSaveNewDeviceNotes = () => {
        axios.post(`${this.context.hostname}/api/deviceNote/insert`, {
                headers: {
                    "X-Requested-With": "XMLHttpRequest",
                    "Content-Type": "application/json",
                },
                "device": {
                    "id": this.props.idDevice
                },
                "text": this.state.currentNote
            },
            {withCredentials: true}
        ).then(res => {
            if (res.status === 200) {
                console.log(res)
                this.componentDidMount()
                this.setState({isShowedModalNewNote: false})
            }
        })
            .catch((error) => {
                console.log({error})
                this.setState({errorMessage: "Błąd!"})
            });
    }

    handleDeleteDeviceNote = (e) => {
        console.log(e.target)
        axios.delete(`${this.context.hostname}/api/deviceNote/delete/${this.state.currentIdNote}`, {
            headers: {"X-Requested-With": "XMLHttpRequest"},
            withCredentials: true,
        }).then(res => {
            if (res.status === 200) {
                console.log(res)
                this.componentDidMount()
                this.setState({isShowedModalEditNote: false, errorMessage: ''})
            }
        })
            .catch((error) => {
                console.log({error})
                let errorMessage = 'Nieznany błąd.';
                if (error.response.status === 403) {
                    errorMessage = 'Brak uprawnień do tej operacji.'
                }
                this.setState({...this.state, errorMessage: errorMessage})
            });
    }


    render() {

        return (
            <div>
                {this.context.redirectToLoginPage ? <Redirect to="/login"/> : ''}

                <Modal show={this.state.isShowedModalNewNote}
                       onHide={() => this.setState({isShowedModalNewNote: false})}>
                    <Modal.Header closeButton>
                        <Modal.Title>Nowa notatka</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Control
                            type="text"
                            as="textarea"
                            rows={3}
                            placeholder="Wprowadź nową notatkę."
                            value={this.state.newDeviceNote}
                            name='newDeviceNote'
                            onChange={this.handleChange}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => this.setState({isShowedModalNewNote: false})}>
                            Anuluj
                        </Button>
                        <Button variant="primary" onClick={this.handleSaveNewDeviceNotes}>
                            Dodaj
                        </Button>
                    </Modal.Footer>
                </Modal>


                <Modal show={this.state.isShowedModalEditNote}
                       onHide={() => this.setState({isShowedModalEditNote: false})}>
                    <Modal.Header closeButton>
                        <Modal.Title>Edycja notatki</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.state.errorMessage != '' ?
                            <Alert variant='danger'>
                                {this.state.errorMessage}
                            </Alert> : false
                        }
                        <Form.Control
                            type="text"
                            as="textarea"
                            rows={3}
                            style={{whiteSpace: 'pre-line'}}
                            placeholder="Wprowadź nową notatkę."
                            value={this.state.currentNote}
                            name='editDeviceNote'
                            onChange={this.handleChange}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" className="mr-auto" onClick={this.handleDeleteDeviceNote}>
                            Usuń
                        </Button>
                        <Button variant="secondary" onClick={() => this.setState({isShowedModalEditNote: false})}>
                            Anuluj
                        </Button>
                        <Button variant="primary" onClick={this.handleSaveOnClick}>
                            Zapisz
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Card style={{margin: '3px'}} border="white">
                    <Card.Body style={{padding: '5px', margin: '3px'}}>
                        <Button
                            className="float-end"
                            variant="outline-dark"
                            onClick={this.handleOnClickAddNewDeviceNotes}
                        >+</Button>
                    </Card.Body>

                </Card>
                {this.state.deviceNotes.map(
                    note =>
                        <Card style={{margin: '3px'}}>
                            <Card.Body style={{padding: '5px', margin: '3px'}}>
                                <Badge bg="light" style={{color: 'lightgray', marginRight: 10}}
                                       className='float-end'>
                                    {note.userUpdated != null ?
                                    note.userUpdated.firstName +" " + note.userUpdated.lastName : false}
                                </Badge>
                                <Button
                                    style={{
                                        whiteSpace: 'pre-line',
                                        textAlign: 'left',
                                        backgroundColor: 'white',
                                        border: 'none',
                                        width: '100%'
                                    }}
                                    variant="light"
                                    size="sm"
                                    onClick={this.handleOnClick}
                                    value={note.text}
                                    id={note.id}
                                >{note.text}</Button>
                            </Card.Body>
                        </Card>
                )}
            </div>
        );
    }
}

export default MachineNotes;
