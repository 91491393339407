import React, {Component} from "react";
import Card from "react-bootstrap/Card";
import {Alert, Button, Dropdown, Form, Modal} from "react-bootstrap";
import {AppContext} from "../../AppContext";
import axios from "axios";
import DbUser from "../../queries/DbUser";
import {Redirect} from "react-router-dom";


class MachineParameters extends Component {

    static contextType = AppContext;

    state = {
        parameters: [],
        isShowedModalNewParameters: false,
        currentDescription: '',
        selectedFile: null,
        errorNoSelectedFile: false,
        errorMessage: ''
    }

    componentDidMount() {
        DbUser.getLoggedUser(this.context)

        axios.get(`${this.context.hostname}/api/file/findByDeviceAndType/${this.props.idDevice}/MACHINE_PARAMETER`, {
            headers: {"X-Requested-With": "XMLHttpRequest"},
            withCredentials: true,
        }).then(res => {
            if (res.status = 200) {
                this.setState({
                    parameters: res.data,
                    errorMessage: ''

                })
            }
        })
            .catch((error) => {
                console.log(error)
            });
    }

    handleOnClickFile = e => {

        const fileName = e.target.value;

        axios.get(`${this.context.hostname}/api/storage/downloadFile?fileName=${encodeURI(e.target.value)}`, {
            headers: {"X-Requested-With": "XMLHttpRequest"},
            responseType: 'blob',
            withCredentials: true,
        }).then(res => {
            if (res.status = 200) {
                console.log(res)
                var url = window.URL.createObjectURL(res.data);
                var a = document.createElement('a');
                a.href = url;
                a.download = (fileName);
                document.body.appendChild(a);
                a.click();
                a.remove();
            }
        })
            .catch((error) => {
                console.log(error)
            });
    }

    handleOnClickAddNewParameter = () =>{
        this.setState({isShowedModalNewParameters: true, errorNoSelectedFile: false, selectedFile: null, currentDescription: ''})
    }

    handleOnChangeAddFileModal = (e) => {
        this.setState({[e.target.name]: e.target.value})
    }

    handleOnChangeNewFile = (e) => {
        let description = e.target.files[0].name.replace(/[.].*/g, "");
        console.log(description)
        this.setState({
            selectedFile: e.target.files[0],
            currentFilename: e.target.files[0].name,
            currentDescription: description
        });
    }

    upload = () => {

        if (this.state.selectedFile !== null) {

            var formData = new FormData()

            if (this.state.currentFilename !== '') {
                var input = document.querySelector('input[type="file"]')
                formData.append('file', input.files[0], this.state.currentFilename)
            }
            formData.set("fileJson", "{device: {id:"+this.props.idDevice+"}, type:'MACHINE_PARAMETER',"
                + "filename:'"+this.state.currentFilename+"',description:'"+this.state.currentDescription+"'}")
            axios.post(`${this.context.hostname}/api/file/add`, formData, {
                    headers: {
                        "X-Requested-With": "XMLHttpRequest",
                        "Content-Type": "application/json",
                    },
                    withCredentials: true,
                }
            ).then(res => {
                if (res.status === 200) {
                    this.componentDidMount()
                    this.setState({
                        isShowedModalNewParameters: false,
                    })
                }
            })
                .catch((error) => {
                    console.log({error})
                });
        } else {
            this.setState({...this.state, errorNoSelectedFile: true})
        }
    };

    handleOnClickDeleteFile = e => {
        let filename = e.target.name
        let fileId = e.target.id

        axios.delete(`${this.context.hostname}/api/file/delete/${fileId}`, {
            headers: {"X-Requested-With": "XMLHttpRequest"},
            withCredentials: true,
        }).then(res => {
            if (res.status === 200) {
                console.log(res)
                this.componentDidMount()
            }
        })
            .catch((error) => {
                console.log({error})
                let errorMessage = 'Nieznany błąd.';
                if (error.response.status === 403) {
                    errorMessage = 'Brak uprawnień do tej operacji.'
                }
                this.setState({...this.state, errorMessage: errorMessage})
            });
    }


    render() {
        return (
            <div>
                {this.context.redirectToLoginPage ? <Redirect to="/login"/> : ''}

                <Modal show={this.state.isShowedModalNewParameters}
                       onHide={() => this.setState({isShowedModalNewParameters: false})}>
                    <Modal.Header closeButton>
                        <Modal.Title>Nowy parametr</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.state.errorNoSelectedFile === true ?
                            <Alert variant='danger'>
                                Nie wybrano pliku!
                            </Alert> : false}
                        <Form.Control
                            type="text"
                            as="textarea"
                            rows={3}
                            placeholder="Wprowadź opis."
                            value={this.state.currentDescription}
                            name='currentDescription'
                            onChange={this.handleOnChangeAddFileModal}
                        />
                        <br/>
                        <Form.Control
                            type="file"
                            onChange={this.handleOnChangeNewFile}
                        />

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => this.setState({isShowedModalNewParameters: false})}>
                            Anuluj
                        </Button>
                        <Button variant="primary" onClick={this.upload}>
                            Dodaj
                        </Button>
                    </Modal.Footer>
                </Modal>



                <Card style={{margin: '3px'}} border="white">
                    <Card.Body style={{padding: '5px', margin: '3px'}}>
                        <Button
                            className="float-end"
                            variant="outline-dark"
                            onClick={this.handleOnClickAddNewParameter}
                        >+</Button>
                    </Card.Body>
                </Card>
                {this.state.errorMessage != '' ?
                    <Alert variant='danger' style={{marginTop: 10}}>
                        {this.state.errorMessage}
                    </Alert> : false
                }
                {this.state.parameters.length !== 0 ? this.state.parameters.map(
                    parameter =>
                        <Card style={{margin: '3px'}}>
                            <Card.Body style={{padding: '5px', margin: '3px'}}>
                                <Dropdown size="sm">
                                    <Dropdown.Toggle id="dropdown-basic" size="sm" variant="light"
                                                     className='float-end' style={{backgroundColor: 'white'}}>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu size="sm">
                                        <Dropdown.Item id={parameter.id} name={parameter.filename} size="sm"
                                                       onClick={this.handleOnClickDeleteFile}>Usuń</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                                <Button variant="link" size="sm"
                                        onClick={this.handleOnClickFile}
                                        value={parameter.filename}>{parameter.description}</Button><br/>
                                {/*<div style={{marginLeft: '8px'}}>*/}
                                {/*    {parameter.description}</div>*/}
                            </Card.Body>
                        </Card>
                ):false}
            </div>
        )
    }
}

function Padder(len, pad) {
    if (len === undefined) {
        len = 1;
    } else if (pad === undefined) {
        pad = '0';
    }

    var pads = '';
    while (pads.length < len) {
        pads += pad;
    }

    this.pad = function (what) {
        var s = what.toString();
        return pads.substring(0, pads.length - s.length) + s;
    };
}

export default MachineParameters;
