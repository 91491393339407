import React, {Component, useEffect, useState} from 'react';
import Card from "react-bootstrap/Card";
import {Alert, Button, CardDeck, CardGroup, Col, Container, Form, Modal, Nav, Row, Tab} from "react-bootstrap";
import {AppContext} from "../../AppContext";
import axios from "axios";
import DbUser from "../../queries/DbUser";
import {Redirect} from "react-router-dom";
import {MapContainer, Marker, Popup, TileLayer, useMap, useMapEvents} from "react-leaflet";
import {GeoSearchControl, OpenStreetMapProvider} from "leaflet-geosearch";
import L from "leaflet";
import InstallationOperations from "./InstallationOperations";


function AddEditBranch(props) {
    return <div style={{padding: 5}}>
        <Form.Label>Nazwa
        </Form.Label>
        <Form.Control
            type="text"
            placeholder="Wprowadź nazwę."
            value={props.branch.name}
            name="name"
            onChange={props.onChange}
        />
        <Form.Label>Ulica / nr budynku
        </Form.Label>
        <Form.Control
            type="text"
            placeholder="Wprowadź ulicę."
            value={props.branch.street}
            name="street"
            onChange={props.onChange}
        />
        <Form.Label>Kod pocztowy
        </Form.Label>
        <Form.Control
            type="text"
            placeholder="Wprowadź kod pocztowy."
            value={props.branch.zipCode}
            name="zipCode"
            onChange={props.onChange}
        />
        <Form.Label>Miasto
        </Form.Label>
        <Form.Control
            type="text"
            placeholder="Wprowadź miasto."
            value={props.branch.city}
            name="city"
            onChange={props.onChange}
        />
        <Form.Label>Telefon
        </Form.Label>
        <Form.Control
            type="text"
            placeholder="Wprowadź telefon."
            value={props.branch.phone}
            name="phone"
            onChange={props.onChange}
        />
        <Form.Label>Email
        </Form.Label>
        <Form.Control
            type="text"
            placeholder="Wprowadź email."
            value={props.branch.email}
            name="email"
            onChange={props.onChange}
        />
        <Form.Label>Województwo
        </Form.Label>
        <Form.Control as="select" name="province"
                      value={props.branch.province}
                      onChange={props.onChange}>
            {props.context.data.provinces.map(props.prop3
            )}
        </Form.Control>
        <Form.Label>Opis
        </Form.Label>
        <Form.Control
            type="text"
            placeholder="Wprowadź opis...."
            value={props.branch.description}
            name="description"
            onChange={props.onChange}
        />
        <Card className="text-center" style={{marginTop: 20}}>

            <div>
                <i className="bi bi-geo-alt" style={{fontSize: 30}}></i><br/>
                {props.gpsLatitude != 0 && props.gpsLongitude != 0 ?
                    <>
                        <div style={{fontSize: 10, color: "grey"}}>długość :</div>
                        {props.gpsLatitude}°
                        <div style={{fontSize: 10, color: "grey"}}>szerokość
                            : </div>{props.gpsLongitude}°</>
                    : "Brak ustalonej pozycji"}

            </div>
        </Card>
        {props.validator !== '' ?
            <Alert variant='danger' style={{marginTop: 10}}>
                {props.validator}
            </Alert> : false
        }
        <div className="float-end" style={{margin: 10}}>
            <Button className="float-end" variant="outline-secondary" style={{width: 80}} size="sm"

                    onClick={props.onClick}>
                Anuluj
            </Button>
            <Button className="float-end" variant="outline-primary" size="sm"
                    style={{width: 80, marginRight: 10}}
                    onClick={props.onClick1}>Ok</Button>
        </div>
    </div>;
}


class Settings extends Component {

    static contextType = AppContext;

    state = {
        isShowedModalNewPassword: false,
        oldPassword: '',
        newPassword: '',
        newPassword2: '',
        errorSetNewPassword: '',
        showMap: false,
        gpsLatitude: 0,
        gpsLongitude: 0,
        isShowedModalNewBranch: false,
        isShowedDetailsBranch: false,
        showDeleteConfirmationModal: false,
        validator: '',
        branches: [],
        branch: {
            id: '',
            name: '',
            street: '',
            zipCode: '',
            city: '',
            phone: '',
            email: '',
            province: '',
            description: '',
            www: ''
        },
        installationOperations: []
    }


    componentDidMount() {
        DbUser.getLoggedUser(this.context)

        axios.get(`${this.context.hostname}/api/branch/getAll`, {
            headers: {"X-Requested-With": "XMLHttpRequest"},
            withCredentials: true,
        }).then(res => {
            if (res.status = 200) {
                this.setState({
                    ...this.state,
                    isShowedModalNewBranch: false,
                    isShowedDetailsBranch: this.state.isShowedModalNewBranch ? true : false,
                    showDeleteConfirmationModal: false,
                    gpsLatitude: 0,
                    gpsLongitude: 0,
                    branches: res.data,
                    branch: {...this.state.branch, latitude: this.state.gpsLatitude, longitude: this.state.gpsLongitude}
                })
            }
        })
            .catch((error) => {
                console.log(error)
            });
    }

    handleOnChange = (e) => {
        this.setState({[e.target.name]: e.target.value})
    }

    showDeleteConfirmationModal = e => {
        this.setState({...this.state, showDeleteConfirmationModal: true})
    }

    handleSubmitAPI = () => {
        let error = '';

        if (this.state.newPassword !== this.state.newPassword2) {
            error = 'Nowe hasła muszą być takie same';
        }
        if (this.state.newPassword.length < 8) {

            error = 'Nowe hasło musi mieć conajmniej 8 znaków';
        }
        console.log(error)
        this.setState({...this.state, errorSetNewPassword: error})


        if (error === '') {
            axios.patch(`${this.context.hostname}/api/user/setNewPassword`, {
                    headers: {
                        "X-Requested-With": "XMLHttpRequest",
                        "Content-Type": "application/json",
                    },
                    'oldPassword': this.state.oldPassword,
                    'newPassword': this.state.newPassword,
                },
                {withCredentials: true}
            ).then(res => {
                if (res.status === 200) {
                    console.log(res)
                    this.setState({
                        isShowedModalNewPassword: false,
                        oldPassword: '',
                        newPassword: '',
                        newPassword2: '',
                        errorSetNewPassword: '',
                    })
                }
            })
                .catch((error) => {
                    console.log({error})
                    this.setState({...this.state, errorSetNewPassword: 'Błędne hasło.'})
                });
        }
    }

    handleSaveNewBranch = () => {
        if (this.state.branch.name === '') {
            this.setState({...this.state, validator: 'Wprowadź nazwę'})
        } else if (this.state.gpsLatitude === 0 && this.state.gpsLongitude === 0) {
            this.setState({...this.state, validator: 'Wybierz lokalizację na mapie'})
        } else {
            axios.post(`${this.context.hostname}/api/branch/insert`, {
                    headers: {
                        "X-Requested-With": "XMLHttpRequest",
                        "Content-Type": "application/json",
                    },
                    "id": this.state.branch.id,
                    "name": this.state.branch.name,
                    "street": this.state.branch.street,
                    "zipCode": this.state.branch.zipCode,
                    "city": this.state.branch.city,
                    "phone": this.state.branch.phone,
                    "province": this.state.branch.province,
                    "description": this.state.branch.description,
                    "email": this.state.branch.email,
                    "latitude": this.state.gpsLatitude,
                    "longitude": this.state.gpsLongitude
                },
                {withCredentials: true}
            ).then(res => {
                if (res.status === 200) {
                    this.componentDidMount()
                }
            })
                .catch((error) => {
                    console.log({error})
                    this.setState({errorMessage: "Błąd!"})
                });
        }
    }

    handleOnClickAddNewBranch = () => {
        if (!this.state.isShowedModalNewBranch) {
            this.setState({
                ...this.state,
                isShowedModalNewBranch: true,
                isShowedDetailsBranch: false,
                gpsLatitude: 0,
                gpsLongitude: 0,
                branch: {
                    id: '',
                    name: '',
                    street: '',
                    zipCode: '',
                    city: '',
                    phone: '',
                    email: '',
                    province: '',
                    description: '',
                },
            })
        } else {
            this.setState({
                ...this.state,
                isShowedModalNewBranch: false,
                isShowedDetailsBranch: false,
                gpsLatitude: 0,
                gpsLongitude: 0,
                validator: '',
            })
        }
    }

    handleChange = (e) => {
        this.setState({
            ...this.state,
            branch: {
                ...this.state.branch,
                [e.target.name]: e.target.value
            }
        })
    }

    handleOnClickMarker = (e, data) => {
        const branchId = e.target.options.data.id
        const branch = this.state.branches.find(branch => branch.id === branchId)

        this.setState({
            ...this.state,
            isShowedModalNewBranch: false,
            isShowedDetailsBranch: true,
            branch
        })
    }

    handleSelect = (key) => {
        if (key == 'branches') {
            this.setState({...this.state, showMap: true})
        }
    }

    update = (nextState) => {
        this.setState(nextState);
    }

    handleOnClickEditBranch = (branch) => {

        this.setState({
            ...this.state,
            isShowedModalNewBranch: true,
            isShowedDetailsBranch: false,
            gpsLatitude: this.state.branch.latitude,
            gpsLongitude: this.state.branch.longitude,
            validator: ''
        })
    }

    handleOnClickDeleteBranch = (e) => {
        axios.delete(`${this.context.hostname}/api/branch/deleteBranch/${this.state.branch.id}`, {
            headers: {"X-Requested-With": "XMLHttpRequest"},
            withCredentials: true,
        }).then(res => {
            if (res.status === 200) {
                this.componentDidMount()

            }
        })
            .catch((error) => {
                console.log({error})
                let errorMessage = 'Nieznany błąd.';
                if (error.response.status === 403) {
                    errorMessage = 'Brak uprawnień do tej operacji.'
                }
                this.setState({
                    ...this.state,
                    errorMessage: errorMessage,
                })
            });
    }

    render() {
        return (
            <>
                {this.context.redirectToLoginPage ? <Redirect to="/login"/> : ''}


                <Card style={{marginTop: 125, marginLeft: 50, marginRight: 50, padding: 9, marginBottom: 150}}>
                    <Tab.Container id="left-tabs-example" defaultActiveKey="user" onSelect={this.handleSelect}>
                        <Row>
                            <Col sm={2}>
                                <Nav variant="pills" className="flex-column" style={{cursor: 'pointer'}}>
                                    <Nav.Item>
                                        <Nav.Link eventKey="user">Dane użytkownika</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="management">Zarządzanie kontem</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="leaves">Urlopy</Nav.Link>
                                    </Nav.Item>
                                    {this.context.isAdmin === true ?
                                        <Nav.Item>
                                            <Nav.Link eventKey="branches">Oddziały</Nav.Link>
                                        </Nav.Item>
                                        : false}
                                    <Nav.Item>
                                        <Nav.Link eventKey="company">Dane firmy</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="installation-operations">Protokół montażu</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </Col>
                            <Col sm={10}>
                                <Tab.Content>
                                    <Tab.Pane eventKey="user">
                                        {this.context.login}<br/>
                                        {this.context.userName}<br/>
                                        {this.context.isAdmin ? 'ADMIN' : 'USER'}
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="management">
                                        <Button onClick={() => this.setState({
                                            ...this.state,
                                            isShowedModalNewPassword: true
                                        })}> Zmiana hasła </Button>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="leaves">
                                        Pozostałe dni urlopowe: {this.context.vacationDays}
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="branches">

                                        {this.state.showMap ? <>
                                                <Card style={{margin: '3px'}} border="white">
                                                    <Card.Body style={{padding: '5px', margin: '3px'}}>
                                                        <Button
                                                            className="float-end"
                                                            variant="outline-dark"
                                                            onClick={this.handleOnClickAddNewBranch}
                                                        >+</Button>
                                                        <i
                                                            className="bi bi-arrow-clockwise float-start clickableFont"
                                                            onClick={() => this.componentDidMount()}
                                                            style={{fontSize: 18}}
                                                        />
                                                    </Card.Body>

                                                </Card>
                                                <Row>
                                                    <Col>
                                                        <Card style={{marginTop: 10}}>
                                                            <MapContainer
                                                                center={startPosition}
                                                                zoom={6}
                                                                scrollWheelZoom={true}
                                                            >
                                                                <SearchField/>

                                                                <TileLayer
                                                                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                                                />
                                                                {this.state.isShowedModalNewBranch ?
                                                                    <AddMarkerToClick updateParent={this.update}
                                                                                      state={this.state}/> : false}

                                                                <>
                                                                    {this.state.branches.map(branch =>
                                                                        <>
                                                                            {branch.latitude !== null && branch.longitude !== null ?
                                                                                <Marker
                                                                                    key={branch.id}
                                                                                    data={branch}
                                                                                    position={[branch.latitude, branch.longitude]}
                                                                                    interactive={true}
                                                                                    eventHandlers={{
                                                                                        click: this.handleOnClickMarker
                                                                                    }}
                                                                                    icon={hotelMarker}
                                                                                >
                                                                                    <Popup
                                                                                        id={branch.id}>{branch.name}</Popup>
                                                                                </Marker>
                                                                                : false}
                                                                        </>
                                                                    )}
                                                                </>
                                                                {/*}*/}
                                                            </MapContainer>
                                                        </Card>
                                                    </Col>

                                                    <Col sm={4}>
                                                        <Card style={{marginTop: 10, width: '100%'}}>
                                                            {this.state.isShowedModalNewBranch ?
                                                                <>
                                                                    {this.state.branch.id === '' ?
                                                                        <h5 style={{margin: 5}}>Wprowadź dane
                                                                            oddziału</h5> :
                                                                        <h5 style={{margin: 5}}>Edytuj dane oddziału</h5>}


                                                                    <AddEditBranch branch={this.state.branch}
                                                                                   onChange={this.handleChange}
                                                                                   context={this.context}
                                                                                   validator={this.state.validator}
                                                                                   prop3={province =>
                                                                                       <option
                                                                                           id={province.id}>{province}
                                                                                       </option>}
                                                                                   gpsLatitude={this.state.gpsLatitude}
                                                                                   gpsLongitude={this.state.gpsLongitude}
                                                                                   onClick={this.handleCancel}
                                                                                   onClick1={this.handleSaveNewBranch}/></>
                                                                :
                                                                false}

                                                            {this.state.isShowedDetailsBranch ?
                                                                <>
                                                                    <Card.Header>

                                                                        Szczegóły Oddziału

                                                                        <i className="bi bi-pencil-square float-end clickableFont"
                                                                           onClick={this.handleOnClickEditBranch}/>
                                                                        <i className="bi bi-trash float-end clickableFont"
                                                                           style={{marginRight: 10}}
                                                                           onClick={this.showDeleteConfirmationModal}/>
                                                                    </Card.Header>
                                                                    <Card.Body>
                                                                        <Card.Title>{this.state.branch.name}</Card.Title>
                                                                        <Card.Text>
                                                                            {this.state.branch.street}<br/>
                                                                            {this.state.branch.zipCode} {this.state.branch.city}<br/>
                                                                            {this.state.branch.province}<br/><br/>

                                                                            <i className="bi bi-telephone"></i> {this.state.branch.phone}<br/>
                                                                            <i className="bi bi-envelope"></i> {this.state.branch.email}<br/>
                                                                            <a href={`${this.state.branch.www != null ?
                                                                                this.state.branch.www.startsWith('http://' || 'https://') ?
                                                                                    this.state.branch.www
                                                                                    :
                                                                                    '//' + this.state.branch.www
                                                                                : false}`}
                                                                               target='_blank'>{this.state.branch.www}</a>
                                                                            <br/><br/>
                                                                            {this.state.branch.description}<br/>
                                                                            <Card className="text-center"
                                                                                  style={{marginTop: 20}}>
                                                                                <i className="bi bi-geo-alt"
                                                                                   style={{fontSize: 30}}></i>
                                                                                {this.state.branch.latitude != 0 && this.state.branch.longitude != 0 ?
                                                                                    <>
                                                                                        <div style={{
                                                                                            fontSize: 10,
                                                                                            color: "grey"
                                                                                        }}>szerokość :
                                                                                        </div>
                                                                                        {this.state.branch.latitude}°
                                                                                        <div style={{
                                                                                            fontSize: 10,
                                                                                            color: "grey"
                                                                                        }}>długość
                                                                                            : </div>{this.state.branch.longitude}°
                                                                                    </>
                                                                                    : "Brak ustalonej pozycji"}

                                                                            </Card>
                                                                        </Card.Text>
                                                                    </Card.Body></>
                                                                : false}

                                                            {!this.state.isShowedDetailsBranch && !this.state.isShowedModalNewBranch ?
                                                                <>
                                                                    <Card.Header>
                                                                        Oddziały
                                                                    </Card.Header>
                                                                    <Card.Body>
                                                                        <Card.Text>
                                                                            Ilość oddziałów : {this.state.branches.length}
                                                                        </Card.Text>
                                                                    </Card.Body></>
                                                                : false}
                                                        </Card>
                                                    </Col>
                                                </Row>
                                            </>
                                            : false}
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="company">
                                        MASZYNY-POLSKIE.PL sp. z o.o.<br/>
                                        NIP 879 269 13 65<br/>
                                        REGON 36706273200000<br/>
                                        KRS 0000674129<br/>
                                        ul. Mazowiecka 52-68, 87-100 Toruń<br/>
                                        https://maszyny-polskie.pl<br/>
                                        https://www.facebook.com/maszynydometalu
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="installation-operations">
                                        <InstallationOperations/>
                                    </Tab.Pane>
                                </Tab.Content>
                            </Col>
                        </Row>
                    </Tab.Container>
                </Card>


                <Modal show={this.state.isShowedModalNewPassword}
                       onHide={() => this.setState({...this.state, isShowedModalNewPassword: false})}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Zmiana hasła</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        {this.state.errorSetNewPassword !== '' ?
                            <Alert variant='danger'>
                                {this.state.errorSetNewPassword}
                            </Alert> : false
                        }

                        <Form.Group controlId="formBasicPassword">
                            <Form.Label>Stare hasło</Form.Label>

                            <Form.Control
                                style={{margin: 5, marginBottom: 20}}
                                type="password"
                                placeholder="wpisz stare hasło"
                                value={this.state.oldPassword}
                                name='oldPassword'
                                onChange={this.handleOnChange}
                            />
                            <Form.Label>Nowe hasło</Form.Label>
                            <Form.Control
                                style={{margin: 5}}
                                type="password"
                                placeholder="wpisz nowe hasło"
                                value={this.state.newPassword}
                                onChange={this.handleOnChange}
                                name='newPassword'
                            />
                            <Form.Control
                                style={{margin: 5}}
                                type="password"
                                placeholder="powtórz nowe hasło"
                                value={this.state.newPassword2}
                                onChange={this.handleOnChange}
                                name='newPassword2'
                            />
                        </Form.Group>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary"
                                onClick={() => this.setState({...this.state, isShowedModalNewPassword: false})}
                        >
                            Anuluj
                        </Button>
                        <Button variant="primary"
                                onClick={this.handleSubmitAPI}
                        >
                            Zatwierdź
                        </Button>
                    </Modal.Footer>
                </Modal>

                {this.DeleteBranchModal()}

            </>
        );
    }

    DeleteBranchModal() {
        return <Modal show={this.state.showDeleteConfirmationModal}
                      onHide={() => this.setState({showDeleteConfirmationModal: false})}>
            <Modal.Header closeButton>
                <Modal.Title>Potwierdzenie</Modal.Title>
            </Modal.Header>
            <Modal.Body>Czy napewno chcesz usunąć oddział?</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary"
                        onClick={() => this.setState({...this.state, showDeleteConfirmationModal: false})}>
                    Anuluj
                </Button>
                <Button variant="primary" onClick={this.handleOnClickDeleteBranch}>
                    Usuń
                </Button>
            </Modal.Footer>
        </Modal>;
    }
}


export default Settings;


const startPosition = [51.055207338584964, 19.1057076459545]


function AddMarkerToClick(props) {
    const [position, setPosition] = useState({
        latitude: props.state.gpsLatitude,
        longitude: props.state.gpsLongitude
    });

    const map = useMapEvents({
        click(event) {
            const {lat, lng} = event.latlng;
            setPosition({
                latitude: lat,
                longitude: lng,
            });
            let branches = []
            if (props.state.isShowedModalNewBranch) {
                branches = props.state.branches.map(branch => {
                    if (branch.id === props.state.branch.id) {
                        return {...branch, latitude: lat, longitude: lng}
                    } else {
                        return branch
                    }
                })
            }
            props.updateParent({...props.state, branches, gpsLatitude: lat, gpsLongitude: lng})
        },
    });

    return (
        position.latitude !== 0 ? (
            <Marker
                position={[position.latitude, position.longitude]}
                interactive={false}
                icon={hotelMarker}

            ><Popup>Opis co to</Popup></Marker>
        ) : null);
}


const SearchField = (
        {
            apiKey
        }
    ) => {
        const provider = new OpenStreetMapProvider({});

        const searchControl = new GeoSearchControl({
            provider: provider,
            searchLabel: 'Wpisz adres aby wyszukać...',
            style: 'bar',
        });

        const map = useMap();
    useEffect(() => {
        map.whenReady(() => {
            // Twoja logika po załadowaniu mapy
            console.log("Mapa jest gotowa!");
            // map.addControl(searchControl);
        });

        return () => {
            // map.removeControl(searchControl);
        };
    }, [map, searchControl]);

        return null;
    }
;

const hotelMarker = L.icon(
    {
        iconUrl: require("../../../src/static/branch_pin.svg"),
        iconSize: [40, 40],
        iconAnchor: [20, 40],
        popupAnchor: [0, -40],
        shadowUrl: null,
        shadowSize: null,
        shadowAnchor: null
    })
;
