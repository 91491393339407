import React from "react";
import '../App.css';
import {Link} from "react-bootstrap-icons";

const Footer = () =>

<div className="footer">
    <div className="footer">
        <p>Maszyny Polskie Serwis - Created by <a target="_blank" href='https://www.codeworld.pl'>Code World</a> </p>
    </div>
</div>


export default Footer;


