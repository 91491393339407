import React, {Component} from 'react';
import {AppContext} from "../AppContext";
import axios from "axios";

class DbUser extends Component {

    static contextType = AppContext;

    static getLoggedUser(context) {
        const res = axios.get(`${context.hostname}/api/user/check-auth`, {
            headers: {
                "X-Requested-With": "XMLHttpRequest"
            },
            withCredentials: true,
        }).then(res => {
            if (res.status = 200) {
                context.toggleUserNameState(res.data.userName);
                context.toggleLoginState(res.data.email);
                context.toggleLoggedState(true);
                context.toggleFirstNameState(res.data.firstName);
                context.toggleLastNameState(res.data.lastName);
                context.toggleVacationDays(res.data.vacationDays);
                context.toggleRedirectToLoginPage(false);
                res.data.roles.map(item => {
                    if (item.role === 'ADMIN') {
                        context.toggleAdminState(true);
                    }
                })
            }
        })
            .catch((error) => {
                context.toggleRedirectToLoginPage(true);
            });
    }


    render() {
        return (<div></div>);
    }

}

export default DbUser;
