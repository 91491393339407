import React, {Component} from 'react';
import {AppContext} from "../../AppContext";
import {Redirect} from "react-router-dom";
import {
    Alert,
    Button,
    Card,
    Col,
    FloatingLabel,
    Form,
    Image,
    ListGroup,
    Modal,
    Row,
    Spinner,
    Tab
} from "react-bootstrap";
import axios from "axios";
import ReactPlayer from 'react-player'
import {PDFViewer} from 'react-view-pdf';
import DbUser from "../../queries/DbUser";


class Tutorials extends Component {

    static contextType = AppContext;

    state = {
        googleDriveTree: [],
        isShowedModalVideoPlayer: false,
        currentFileId: '',
        currentFileName: '',
        isDataLoaded: false,
        alert: '',
        alertVariant: 'danger',
        showSpinner: true,
        fileAfterSearch: [],
        redirect: '',
        pdfURL: null
    }

    componentDidMount() {
        DbUser.getLoggedUser(this.context)

        axios.get(`${this.context.hostname}/api/google-drive/getTree`, {
            headers: {"X-Requested-With": "XMLHttpRequest"},
            withCredentials: true,
        }).then(res => {
            if (res.status = 200) {
                this.setState({
                    ...this.state,
                    googleDriveTree: res.data,
                    isDataLoaded: true,
                    alert: '',
                })
            }
        })
            .catch((error) => {
                console.log(error)
                this.setState({
                    ...this.state,
                    alert: 'Błąd połączenia z dyskiem google.',
                    alertVariant: 'danger',
                    showSpinner: false
                })
            });
    }


    handleBackOnClick = () => {
        this.setState({redirect: '/home'})
    }


    handleOnClickFile = e => {
        var fileExtensionPattern = /\.([0-9a-z]+)(?=[?#])|(\.)(?:[\w]+)$/gmi
        var extension = e.target.name.match(fileExtensionPattern)[0];
        if (extension === ".mkv" || extension === ".mp4") {
            this.setState({
                ...this.state,
                isShowedModalVideoPlayer: true,
                currentVideo: e.target.value,
                currentFileName: e.target.name
            })
        } else if (extension === ".jpg" || extension === ".bmp" || extension === ".png" || extension === ".gif" || extension === ".jpeg") {
            this.setState({
                ...this.state,
                isShowedModalPreviewImage: true,
                currentFileId: e.target.value,
                currentFileName: e.target.name
            })
        } else if (extension === ".pdf") {
            const fileName = e.target.name;
            axios.get(`${this.context.hostname}/api/google-drive/downloadFile?fileId=${encodeURI(e.target.value)}`, {
                headers: {"X-Requested-With": "XMLHttpRequest"},
                responseType: 'blob',
                withCredentials: true,
            }).then(res => {
                if (res.status = 200) {
                    this.setState({
                        ...this.state,
                        isShowedModalPreviewPDF: true,
                        currentFileName: fileName,
                        pdfURL: window.URL.createObjectURL(res.data)
                    })
                }
            })
                .catch((error) => {
                    console.log(error)
                });


        } else {
            const fileName = e.target.name;
            axios.get(`${this.context.hostname}/api/google-drive/downloadFile?fileId=${encodeURI(e.target.value)}`, {
                headers: {"X-Requested-With": "XMLHttpRequest"},
                responseType: 'blob',
                withCredentials: true,
            }).then(res => {
                if (res.status = 200) {
                    var url = window.URL.createObjectURL(res.data);
                    var a = document.createElement('a');
                    a.href = url;
                    a.download = (fileName);
                    document.body.appendChild(a);
                    a.click();
                    a.remove();
                }
            })
                .catch((error) => {
                    console.log(error)
                });
        }
    }

    handleOnKeyUpSearch = e => {
        const text = e.target.value
        let fileAfterSearch = []
        if (text !== '') {
            this.state.googleDriveTree.forEach(item =>
                item.googleFiles.forEach(file => {
                    if (file.name.toUpperCase().includes(text.toUpperCase())) {
                        fileAfterSearch.push({name: file.name, folder: item.folder, id: file.id})
                    }
                })
            )
            this.setState({...this.state, fileAfterSearch})
        } else {
            this.setState({...this.state, fileAfterSearch: []})
        }

    }

    handleBackOnClick = () => {
        this.setState({redirect: '/home'})
    }


    render() {


        return (


            <div className="fragment">
                {this.state.redirect !== '' ? <Redirect to={this.state.redirect}/> : ''}
                {this.context.redirectToLoginPage ? <Redirect to="/login"/> : ''}

                <h3><Button variant="light" onClick={this.handleBackOnClick}>
                    <svg width="1em" height="1em" viewBox="0 0 16 16"
                         className="bi bi-arrow-return-left"
                         fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd"
                              d="M14.5 1.5a.5.5 0 0 1 .5.5v4.8a2.5 2.5 0 0 1-2.5 2.5H2.707l3.347 3.346a.5.5 0 0 1-.708.708l-4.2-4.2a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 8.3H12.5A1.5 1.5 0 0 0 14 6.8V2a.5.5 0 0 1 .5-.5z"/>
                    </svg>
                </Button>
                    &nbsp;Tutoriale
                </h3>
                <br/>
                <hr/>
                {this.state.alert !== '' ? <Alert variant={this.state.alertVariant}>{this.state.alert}</Alert> : false}

                <br/>
                {!this.state.isDataLoaded ?
                    this.state.showSpinner ?
                        <div className={"container"} style={{textAlign: "center"}}><br/>Ładowanie
                            danych<br/><br/><Spinner animation="border"/></div>
                        : false :
                    <>
                        <Row  >
                            <Col xs={12} md={8}>
                                <Tab.Container id="left-tabs-example" defaultActiveKey="#all" style={{marginBottom: 15}}>
                                    <Row>
                                        <Col sm={3} style={{minWidth: 250, cursor: "pointer"}}>
                                            <ListGroup>
                                                <ListGroup.Item action href='#all'>Wszystko</ListGroup.Item>
                                                {this.state.googleDriveTree.map(item =>
                                                    <ListGroup.Item action href={`#${item.folder.id}`}>
                                                        {item.folder.name}
                                                    </ListGroup.Item>
                                                )}
                                            </ListGroup>
                                        </Col>
                                        <Col>
                                            <Tab.Content>


                                                <Tab.Pane eventKey='#all'>
                                                    <Card>
                                                        <Card.Body>
                                                            {this.state.googleDriveTree.length === 0 ? 'Brak plików' : false}

                                                            {this.state.googleDriveTree.map(item =>
                                                                <>
                                                                    <span style={{fontSize: 10, color: "grey"}}>
                                                                            {item.folder.name}<br/>
                                                                    </span>
                                                                    {item.googleFiles.map(file =>
                                                                        <>
                                                                            <Button variant="link" size="sm"
                                                                                    style={{padding: 0}}
                                                                                    onClick={this.handleOnClickFile}
                                                                                    value={file.id}
                                                                                    name={file.name}>{file.name}</Button><br/>
                                                                        </>
                                                                    )}
                                                                </>)}
                                                        </Card.Body>
                                                    </Card>
                                                </Tab.Pane>
                                                {this.state.googleDriveTree.map(item =>
                                                    <>
                                                        <Tab.Pane eventKey={`#${item.folder.id}`}>
                                                            <Card style={{marginBottom: 15}}>
                                                                <Card.Body>
                                                                    {item.googleFiles.length === 0 ? 'Brak plików' : false}
                                                                    {item.googleFiles.map(file =>
                                                                        <>
                                                                            <Button variant="link" size="sm"
                                                                                    style={{padding: 0}}
                                                                                    onClick={this.handleOnClickFile}
                                                                                    value={file.id}
                                                                                    name={file.name}>{file.name}</Button><br/>
                                                                        </>
                                                                    )}
                                                                </Card.Body>
                                                            </Card>
                                                        </Tab.Pane>
                                                    </>
                                                )}

                                            </Tab.Content>
                                        </Col>
                                    </Row>
                                </Tab.Container>

                            </Col>
                            <Col xs={12} md={4}>
                                {/*Wyszukiwarka*/}
                                <Card>
                                    <Card.Body>
                                        <FloatingLabel
                                            controlId="floatingInput"
                                            label="Wpisz aby wyszukać"
                                            className="mb-3"
                                        >
                                            <Form.Control
                                                type="text"
                                                placeholder="wpisz..."
                                                onKeyUp={this.handleOnKeyUpSearch}
                                                value={this.state.searchText}/>
                                        </FloatingLabel>
                                        {this.state.fileAfterSearch.map(file =>
                                            <><Button variant="link" size="sm"
                                                      style={{padding: 0}}
                                                      onClick={this.handleOnClickFile}
                                                      value={file.id}
                                                      name={file.name}>
                                                {file.name}
                                            </Button>
                                                <span style={{fontSize: 10, color: "grey"}}>
                                                    &nbsp;&nbsp;{file.folder.name}
                                                </span>
                                                <br/></>
                                        )}
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>

                    </>
                }


                {/********************************* MODAL VIDEO PLAYER ***********************************/}
                <Modal
                    show={this.state.isShowedModalVideoPlayer}
                    onHide={() => this.setState({...this.state, isShowedModalVideoPlayer: false})}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>{this.state.currentFileName}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <ReactPlayer
                            controls
                            playing
                            width='100%'
                            height='440px'
                            url={this.context.hostname + "/api/google-drive/downloadFile?fileId=" + this.state.currentVideo}
                        />
                    </Modal.Body>
                </Modal>

                {/*************************MODAL PREVIEW IMAGE********************************/}

                <Modal
                    show={this.state.isShowedModalPreviewImage}
                    onHide={() => this.setState({...this.state, isShowedModalPreviewImage: false})
                    }
                    fullscreen
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="example-custom-modal-styling-title">
                            {this.state.currentDescription}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='d-flex justify-content-center'>

                            <Image
                                style={{maxWidth: '100%'}}
                                src={`${this.context.hostname + "/api/google-drive/downloadFile?fileId=" + this.state.currentFileId}`}
                                rounded/>
                        </div>
                    </Modal.Body>

                </Modal>

                {/*************************MODAL PREVIEW PDF********************************/}
                <Modal
                    show={this.state.isShowedModalPreviewPDF}
                    onHide={() => this.setState({...this.state, isShowedModalPreviewPDF: false})
                    }
                    fullscreen
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="example-custom-modal-styling-title">
                            {this.state.currentFileName}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='d-flex justify-content-center'>
                            <PDFViewer
                                // url={`${this.context.hostname + "/api/google-drive/downloadFile?fileId=" + this.state.currentFileId}`}/>
                                url={this.state.pdfURL}/>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}

export default Tutorials;
