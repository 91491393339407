import React, {Component} from 'react';
import {AppContext} from "../../AppContext";
import {Alert, Badge, Button, Dropdown, Form, Modal, OverlayTrigger, Tooltip} from "react-bootstrap";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import axios from "axios";
import DbUser from "../../queries/DbUser";
import {Redirect} from "react-router-dom";
import ToolkitProvider, {Search} from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import Utils from "../../utils/Utils";


const defaultSorted = [{
    dataField: 'date',
    order: 'desc'
}];

const {SearchBar} = Search;

const searchProps = {
    delay: 800,
    placeholder: "wpisz aby wyszukać",
};

const sizePerPageRenderer = ({
                                 options,
                                 currSizePerPage,
                                 onSizePerPageChange
                             }) => (
    <div className="btn-group" role="group">
        {
            options.map((option) => {
                const isSelect = currSizePerPage === `${option.page}`;
                return (
                    <button style={{fontSize: 12}}
                            key={option.text}
                            type="button"
                            onClick={() => onSizePerPageChange(option.page)}
                            className={`btn ${isSelect ? 'btn-secondary' : 'btn-warning'}`}
                    >
                        {option.text}
                    </button>
                );
            })
        }
    </div>
);

const options = {
    sizePerPageRenderer,
    sizePerPageList: [{
        text: '15', value: 15
    }, {
        text: '30', value: 30
    }, {
        text: '50', value: 50
    }, {
        text: '100', value: 100
    }]
};


class MachineIncident extends Component {

    static contextType = AppContext;

    state = {
        incidents: [],
        id: '',
        text: '',
        date: '',
        time: '',
        isShowedModalNewIncident: false,
        errorAddIncident: '',
        searchText: '',
        searchDateFrom: '',
        searchDateTo: '',
        showService: true,
        isShowedModalDeleteIncident: false,
        errorDelete: false,
        errorModifyIncident: false
    }

    componentDidMount() {
        DbUser.getLoggedUser(this.context)
        this.handleOnClickSearchButton()
    }


    handleChange = (e) => {
        let value = e.target.value;
        let name = e.target.name;
        this.setState(
            {
                [name]: value,
            }
        )

    }
    handleOnClickNewIncident = () => {
        const today = new Date();
        let date = today.toISOString().slice(0, 10)
        let time;
        if (today.getMinutes() < 10) {
            time = today.getHours() + ":0" + today.getMinutes()
        } else {
            time = today.getHours() + ":" + today.getMinutes()
        }
        this.setState({
            ...this.state,
            isShowedModalNewIncident: true,
            date: date,
            time: time,
            id: 0,
            text: '',
            errorModifyIncident: false
        })
    }

    insertIncident = () => {
        axios.post(`${this.context.hostname}/api/incident/insert`, {
                headers: {
                    "X-Requested-With": "XMLHttpRequest",
                    "Content-Type": "application/json",
                },
                "device": {
                    "id": this.props.idDevice
                },
                "id": this.state.id,
                "date": this.state.date + "T" + this.state.time,
                "text": this.state.text
            },
            {withCredentials: true}
        ).then(res => {
            if (res.status === 200) {
                this.setState({isShowedModalNewIncident: false, errorAddIncident: '', text: '', id: ''})
                this.componentDidMount()
            }
        })
            .catch((error) => {
                if (error.response.status === 403) {
                    this.setState({...this.state, errorModifyIncident: true})
                } else {
                    console.log({error})
                    this.setState({errorAddIncident: "Próba zakończona niepowodzeniem."})
                }
            });
    }

    handleChangeShowServiceCheckbox = () => {
        this.setState({
            ...this.state, showService: !this.state.showService
        })
    }

    handleOnClickSearchButton = () => {
        axios.get(`${this.context.hostname}/api/incident/findByParameters`,
            {
                params: {
                    deviceId: this.props.idDevice,
                    text: this.state.searchText,
                    showService: this.state.showService,
                    dateFrom: this.state.searchDateFrom == '' ? null : this.state.searchDateFrom + "T00:00:00",
                    dateTo: this.state.searchDateTo == '' ? null : this.state.searchDateTo + "T23:59:59.999999"
                },
                headers: {"X-Requested-With": "XMLHttpRequest"},
                withCredentials: true,
            }).then(res => {
            if (res.status = 200) {
                this.setState({
                    ...this.state,
                    incidents: res.data,
                })
            }
        })
            .catch((error) => {
                console.log(error)
            });
    }

    handleOnClickEditIncident = e => {
        const id = e.target.id;
        const incident = this.state.incidents.find(incident => incident.id == id);
        let date = incident.date.slice(0, 10)
        let time;
        if (incident.date.length > 15) {
            time = incident.date.slice(11, 16)
        }

        this.setState({
            ...this.state,
            isShowedModalNewIncident: true,
            id: incident.id,
            text: incident.text,
            date: date,
            time: time,
            errorModifyIncident: false
        })
    }

    handleOnClickDeleteIncident = e => {
        this.setState({isShowedModalDeleteIncident: true, errorDelete: false, isShowedModalNewIncident:false })
    }

    deleteIncident = () => {
        axios.delete(`${this.context.hostname}/api/incident/delete/${this.state.id}`, {
            headers: {"X-Requested-With": "XMLHttpRequest"},
            withCredentials: true,
        }).then(res => {
            if (res.status === 200) {
                this.componentDidMount();
                this.setState({...this.state, isShowedModalDeleteIncident: false, errorDelete: false})
            }
        })
            .catch((error) => {
                console.log({error})
                if (error.response.status === 403) {
                    this.setState({...this.state, errorDelete: true})
                }
            });
    }


    render() {

        const onClickEvent = {

            onClick: (e, column, columnIndex, row, rowIndex) => {
                if (row.service === null) {
                    const id = row.id;
                    const incident = this.state.incidents.find(incident => incident.id == id);
                    let date = incident.date.slice(0, 10)
                    let time;
                    if (incident.date.length > 15) {
                        time = incident.date.slice(11, 16)
                    }

                    this.setState({
                        ...this.state,
                        isShowedModalNewIncident: true,
                        id: incident.id,
                        text: incident.text,
                        date: date,
                        time: time,
                        errorModifyIncident: false
                    })
                }
            }
        }

        const columns = [
            {
                dataField: 'id',
                text: 'id',
                style: {width: '4%', verticalAlign: 'middle'},
                headerAlign: 'center',
                sort: true,
                align: 'center',
                events: onClickEvent,
            }, {
                dataField: 'date',
                text: 'Data',
                align: 'center',
                sort: true,
                headerAlign: 'center',
                events: onClickEvent,
                style: {width: '7%', minWidth: 80, verticalAlign: 'middle'},
                formatter: cell => `${cell != null ? cell.substring(0, 10) : false}`,
                sortFunc: (a, b, order, dataField, rowA, rowB) => {
                    const date1 = Date.parse(rowA.date);
                    const date2 = Date.parse(rowB.date)
                    if (order === 'asc') {
                        return date1 - date2;
                    } else {
                        return date2 - date1;
                    }
                },
            },
            {
                dataField: 'fullNumber',
                text: 'Numer',
                align: 'center',
                headerAlign: 'center',
                events: onClickEvent,
                style: {width: '8%', minWidth: 70, verticalAlign: 'middle'},
                formatter: (cell, row) => `${row.service != null && row.service.number > 0 ? row.service.number + row.service.numberExtension + '/' + row.service.month + '/' + row.service.year : ''}`,
            },
            {dataField: 'service.number', hidden: true},
            {dataField: 'numberExtension', hidden: true},
            {dataField: 'month', hidden: true},
            {dataField: 'year', hidden: true},
            {dataField: 'text', hidden: true},
            {
                dataField: 'service.devices',
                text: 'Maszyny',
                headerAlign: 'center',
                align: 'center',
                events: onClickEvent,
                style: {width: '12%', minWidth: 100, verticalAlign: 'middle'},
                formatter: devicesFormatter
            },
            {
                dataField: 'service',
                text: 'Typ',
                headerAlign: 'center',
                align: 'center',
                events: onClickEvent,
                style: {width: '6%', verticalAlign: 'middle'},
                formatter: typeFormatter
            },
            {
                dataField: 'service.faultDescription',
                text: 'Opis usterki',
                headerAlign: 'center',
                align: 'center',
                events: onClickEvent,
                style: {width: '18%', minWidth: 200, verticalAlign: 'middle'},
                formatter: (cell, row) => <>
                    {row.text == '' ?
                        <>{cell != undefined && cell.length > 80 ?
                            <OverlayTrigger
                                key='top'
                                placement='left'
                                overlay={
                                    <Tooltip id={`tooltip-top`}>
                                        {cell}
                                    </Tooltip>
                                }
                            >
                                <div variant="secondary">{cell.substring(0, 80)}...</div>
                            </OverlayTrigger> : <>{cell}</>}</>
                        : <>{row.text != undefined && row.text.length > 80 ?
                            <OverlayTrigger
                                key='top'
                                placement='left'
                                overlay={
                                    <Tooltip id={`tooltip-top`}>
                                        {row.text}
                                    </Tooltip>
                                }
                            >
                                <div variant="secondary">{row.text.substring(0, 80)}...</div>
                            </OverlayTrigger> : <>{row.text}</>}</>}
                </>,
            }, {
                dataField: 'userCreated',
                text: 'Pracownik',
                headerAlign: 'center',
                align: 'center',
                events: onClickEvent,
                style: {width: '10%', verticalAlign: 'middle'},
                formatter: (cell, row) => `${cell != null ? cell.fullName : row.service.user}`,
            }, {
                dataField: 'service.state',
                text: 'Stan',
                headerAlign: 'center',
                align: 'center',
                events: onClickEvent,
                style: {width: '8%', verticalAlign: 'middle'}
            }];

        function typeFormatter(cell, row, rowIndex, formatExtraData) {
            let types = "";
            if (cell != null) {
                let t = [];
                t = Utils.getServiceTypesNamesAsArray(cell.type)
                t.map((type, i) =>
                    types = <>{types}
                        {type === 'PRZEGLĄD OKRESOWY' && row.period > 0 ? <>{type.replace('PRZEGLĄD OKRESOWY', 'PRZEGLĄD OKRESOWY (' + row.period + 'm)')} </> :
                            <>{type}</>}{i < t.length - 1 ? ',' : ''}<br/></>
                )
            } else {
                types = 'ZDARZENIE'
            }
            return types;
        }

        function devicesFormatter(cell, row, rowIndex, formatExtraData) {
            let devices = "";
            if (cell != null) {

                cell.map(d =>
                    devices = <>{devices}({d.id}) {d.name}<br/></>
                )
            }
            return devices;
        }


        return (
            <div>
                {this.context.redirectToLoginPage ? <Redirect to="/login"/> : ''}

                <div style={{fontSize: 12}}>
                    <ToolkitProvider
                        bootstrap3
                        keyField="id"
                        data={this.state.incidents}
                        columns={columns}
                        defaultSorted={defaultSorted}
                        bordered={false}
                        noDataIndication=""
                        search>
                        {
                            props => (
                                <div style={{fontSize: 12}}>


                                    <Card style={{margin: '3px'}}>
                                        <Card.Body style={{padding: '5px', margin: '3px'}}>

                                            <Row className="align-items-center">
                                                <Col>
                                                    <Form.Check
                                                        checked={this.state.showService}
                                                        name='showServices'
                                                        onChange={this.handleChangeShowServiceCheckbox}
                                                        type='checkbox'
                                                        label='Serwis'
                                                    />
                                                    <Form.Control size="sm"
                                                                  onChange={this.handleChange}
                                                                  type="text"
                                                                  name="searchText"
                                                                  value={this.state.searchText}
                                                                  placeholder="wpisz aby wyszukać..."
                                                    />

                                                </Col>
                                                <Col>

                                                    <Form.Label style={{marginTop: 0, marginBottom: 5}}>Data od</Form.Label>
                                                    <Form.Control size="sm"
                                                                  style={{marginTop: 4}}
                                                                  type='date' value={this.state.searchDateFrom} name='searchDateFrom'
                                                                  onChange={this.handleChange}/>
                                                </Col>
                                                <Col>
                                                    <Form.Label style={{marginTop: 0, marginBottom: 5}}>Data do</Form.Label>
                                                    <Row>
                                                        <Col>

                                                            <Form.Control size="sm"
                                                                          style={{marginTop: 4}}
                                                                          type='date' value={this.state.searchDateTo} name='searchDateTo'
                                                                          onChange={this.handleChange}/>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col xs lg="2">
                                                    <Button
                                                        style={{marginTop: 22}}
                                                        size='sm'
                                                        variant="primary"
                                                        onClick={this.handleOnClickSearchButton}
                                                    >
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                                             className="bi bi-search" viewBox="0 0 16 16">
                                                            <path
                                                                d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
                                                        </svg>
                                                        Szukaj</Button>
                                                </Col>
                                                <Col xs lg="2">
                                                    <Button
                                                        className="float-end"
                                                        variant="outline-dark"
                                                        onClick={this.handleOnClickNewIncident}
                                                    >+</Button>
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                    <hr/>
                                    <BootstrapTable
                                        bootstrap3
                                        keyField="id"
                                        data={this.state.incidents}
                                        columns={columns}
                                        defaultSorted={defaultSorted}
                                        bordered={false}
                                        noDataIndication=""
                                        pagination={paginationFactory(options)}
                                        {...props.baseProps}
                                        style={{fontSize: 5}}
                                        wrapperClasses="table-responsive"
                                    />
                                </div>
                            )
                        }
                    </ToolkitProvider>
                </div>
                {/**************************** MODAL ADD INCIDENT ****************************/}
                <Modal show={this.state.isShowedModalNewIncident}
                       onHide={() => this.setState({isShowedModalNewIncident: false})}>
                    <Modal.Header closeButton>
                        <Modal.Title>{this.state.id > 0 ? 'Edycja zdarzenia' : 'Nowe zdarzenie'}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.state.errorAddIncident === '' ? false :
                            <Alert variant='danger'>
                                {this.state.errorAddIncident}
                            </Alert>}
                        {this.state.errorModifyIncident === false ? false :
                            <Alert variant='danger'>
                                Brak uprawnień.
                            </Alert>}
                        <Row>
                            <Col>
                                <Form.Control type='date' value={this.state.date} name='date'
                                              onChange={this.handleChange}/>
                            </Col>
                            <Col>
                                <Form.Control type='time' value={this.state.time} name='time'
                                              onChange={this.handleChange}/>
                            </Col>
                        </Row>
                        <Form.Control
                            type="text"
                            as="textarea"
                            rows={3}
                            placeholder="Opisz czego dotyczyło zdarzenie."
                            value={this.state.text}
                            name='text'
                            onChange={this.handleChange}
                            style={{marginTop: 10}}
                        />

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" className="mr-auto" onClick={this.handleOnClickDeleteIncident}>
                            Usuń
                        </Button>
                        <Button variant="secondary" onClick={() => this.setState({isShowedModalNewIncident: false})}>
                            Anuluj
                        </Button>
                        <Button variant="primary" onClick={this.insertIncident}>
                            {this.state.id > 0 ? 'Zatwierdź' : 'Dodaj'}
                        </Button>
                    </Modal.Footer>
                </Modal>

                {/********************************* MODAL CONFIRMATION DELETE INCIDENT ***********************************/}
                <Modal show={this.state.isShowedModalDeleteIncident}
                       onHide={() => this.setState({...this.state, isShowedModalDeleteIncident: false})}>
                    <Modal.Header closeButton>
                        <Modal.Title>Czy napewno chcez usunąć wybrane zdarzenie?</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.state.errorDelete ?
                            <Alert variant='danger'>Brak uprawnień do tej operacji!</Alert> : false}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary"
                                onClick={() => this.setState({
                                    ...this.state,
                                    isShowedModalDeleteIncident: false,
                                    isShowedModalNewIncident: true,
                                })}>
                            NIE
                        </Button>
                        <Button variant="primary"
                                onClick={this.deleteIncident}
                        >
                            TAK
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

export default MachineIncident;
