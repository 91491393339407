import React, {Component} from "react";
import {Alert, Button, Form, Modal} from "react-bootstrap";
import {Redirect} from "react-router-dom";
import '../../App.css';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {AppContext} from "../../AppContext";
import axios from "axios";
import DbUser from "../../queries/DbUser";

class UserEdit extends Component {

    static contextType = AppContext;

    state = {
        id: new URLSearchParams(this.props.location.search).get("id"),
        user: {},
        userName: '',
        password: '',
        email: '',
        firstName: '',
        lastName: '',
        country: '',
        isActive: false,
        isAdmin: false,
        vacationDays: 0,

        validatorMessage: '',
        validatorAlertVariant: 'danger',
        delete: false,
        showDeleteModal: false,
        exit: false,
        redirect: ''
    }

    componentDidMount() {
        DbUser.getLoggedUser(this.context)

        axios.get(`${this.context.hostname}/api/user/findById/${this.state.id}`, {
                headers: {
                    "X-Requested-With": "XMLHttpRequest",
                    "Content-Type": "application/json",
                }, withCredentials: true
            },
            {withCredentials: true}
        ).then(res => {
            if (res.status === 200) {
                console.log(res)
                this.setState({
                        userName: res.data.userName,
                        email: res.data.email,
                        firstName: res.data.firstName,
                        lastName: res.data.lastName,
                        isActive: res.data.active,
                        vacationDays: res.data.vacationDays
                    },
                )
            }
            res.data.roles.map(r => {
                if (r.role === "ADMIN") {
                    this.setState({...this.state, isAdmin: true})
                }
            } )
        })
            .catch((error) => {
                console.log({error})


            });
    }

    handleChange = (e) => {
        let value = e.target.value;
        let name = e.target.name;
        if (value === 'on') {
            value = e.target.checked;
        }
        this.setState(
            {...this.state,
                [name]: value,
            }
        )
    }

    handleSubmit = (e) => {
        e.preventDefault()
        const value = e.target.userName.value;
        if (value.length < 3) {
            this.setState({
                validatorMessage: 'Stanowisko musi mieć min. 3 znaki.',
                validatorAlertVariant: 'danger',
            })
        } else {
            this.submitToAPI(
                this.state.id,
                this.state.userName,
                this.state.password,
                this.state.email,
                this.state.firstName,
                this.state.lastName,
                this.state.country,
                this.state.isActive,
                this.state.isAdmin,
                this.state.vacationDays)
            this.setState({
                ...this.state,
                shortName: '',
                validatorMessage: '',
                validatorAlertVariant: 'danger',
            })
        }
    }

    submitToAPI = (id, userName, password, email, firstName, lastName, country, isActive, isAdmin, vacationDays) => {
        let role;
        if (isAdmin) {
            role = {id: 1, role: 'ADMIN'}
        } else {
            role = {id: 2, role: 'USER'}
        }

        axios.put(`${this.context.hostname}/api/user/update`, {
                headers: {
                    "X-Requested-With": "XMLHttpRequest",
                    "Content-Type": "application/json",
                },
                "id": id,
                "email": email,
                "userName": userName,
                "password": password,
                "firstName": firstName,
                "lastName": lastName,
                "country": country,
                "active": isActive,
                "roles": [role],
                "vacationDays": vacationDays
            },
            {withCredentials: true}
        ).then(res => {
            if (res.status === 200) {
                console.log(res)
                this.setState({validatorAlertVariant: 'info', validatorMessage: 'Zapisano pomyślnie.'})
            }
        })
            .catch((error) => {
                console.log({error})
                this.setState({
                    ...this.state,
                    validatorAlertVariant: 'danger',
                    validatorMessage: "Błąd zapisu do bazy danych."})
            });
    }

    handleOnClickDelete = () => {
        this.setState({
            showDeleteModal: true
        })
    }

    handleDeleteOnClick = () => {
        this.setState({
            delete: true,
            showDeleteModal: false,
        });

        axios.delete(`${this.context.hostname}/api/user/delete/${this.state.id}`, {
            headers: {"X-Requested-With": "XMLHttpRequest"},
            withCredentials: true,
        }).then(res => {
            if (res.status === 200) {
                console.log(res)
                this.setState({exit: true,})
            }
        })
            .catch((error) => {
                console.log({error})
                let validatorMessage = 'Nieznany błąd.';
                if (error.response.status === 409) {
                    validatorMessage = 'Operacja niedozwolona. Pracownik nie może być usunięy ponieważ ' +
                        'jest używany przez inne obiekty. Oznacz praconika jako nieaktywny.'
                }
                this.setState({
                    ...this.state, validatorMessage, validatorAlertVariant: 'danger',
                })
            });
    }

    handleBackOnClick = () => {
        this.setState({redirect: '/userList'})
    }

    render() {

        if (this.state.exit === true) {
            return (<Redirect to="/userList"/>
            )
        }

        return (

            <div className="fragment">
                {this.context.redirectToLoginPage ? <Redirect to="/login"/> : ''}
                {this.state.redirect !== '' ? <Redirect to={this.state.redirect}/> : ''}
                <Row>
                    <Col sm={5}>
                        <h3>
                            <Button variant="light" onClick={this.handleBackOnClick}>
                                <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-arrow-return-left"
                                     fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd"
                                          d="M14.5 1.5a.5.5 0 0 1 .5.5v4.8a2.5 2.5 0 0 1-2.5 2.5H2.707l3.347 3.346a.5.5 0 0 1-.708.708l-4.2-4.2a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 8.3H12.5A1.5 1.5 0 0 0 14 6.8V2a.5.5 0 0 1 .5-.5z"/>
                                </svg>
                            </Button>
                            &nbsp;Edycja pracownika <Button onClick={this.handleOnClickDelete}
                                                            size="sm"
                                                            variant="outline-dark"
                                                            className='float float-end'
                                                            style={{marginLeft: "5px"}}>
                            Usuń
                        </Button></h3>

                        <Form onSubmit={this.handleSubmit}>
                            <Form.Check type="checkbox"
                                        label="Aktywny"
                                        name="isActive"
                                        onChange={this.handleChange}
                                        checked={this.state.isActive}
                                        className='float float-end'/>
                            <Form.Check type="checkbox"
                                        label="Admin&nbsp;&nbsp;"
                                        name="isAdmin"
                                        onChange={this.handleChange}
                                        checked={this.state.isAdmin}
                                        className='float float-end'/>


                            <Form.Group controlId="formBasicEmail">
                                <Form.Label>Stanowisko
                                </Form.Label>

                                <Form.Control
                                    type="text" name="userName"
                                    value={this.state.userName}
                                    placeholder="Wpisz..."
                                    onChange={this.handleChange}
                                />
                                <Form.Label>Imię
                                </Form.Label>
                                <Form.Control
                                    type="text" name="firstName"
                                    value={this.state.firstName}
                                    placeholder="Wpisz..."
                                    onChange={this.handleChange}
                                />
                                <Form.Label>Nazwisko
                                </Form.Label>
                                <Form.Control
                                    type="text" name="lastName"
                                    value={this.state.lastName}
                                    placeholder="Wpisz..."
                                    onChange={this.handleChange}
                                />
                                <Form.Label>Email
                                </Form.Label>
                                <Form.Control
                                    type="email" name="email"
                                    value={this.state.email}
                                    placeholder="Wpisz..."
                                    onChange={this.handleChange}
                                    disabled={true}
                                />
                                <Form.Label>Pozostałe dni urlopowe
                                </Form.Label>
                                <Form.Control
                                    type="number" name="vacationDays"
                                    value={this.state.vacationDays}
                                    placeholder="Wpisz..."
                                    onChange={this.handleChange}
                                    style={{marginBottom: 10}}
                                />
                                {this.state.validatorMessage != '' ?
                                    <Alert variant={this.state.validatorAlertVariant}>
                                        {this.state.validatorMessage}
                                    </Alert> : false
                                }
                            </Form.Group>
                            <Button
                                variant="outline-dark"
                                type="submit"
                                size="sm"
                                style={{marginRight: "5px"}}>
                                Zapisz
                            </Button>
                            {/*<Button*/}
                            {/*    size="sm"*/}
                            {/*    variant="outline-dark"*/}
                            {/*    onClick={() => this.setState({redirect: '/userList'})}>*/}
                            {/*    Anuluj*/}
                            {/*</Button>*/}
                        </Form>
                        <Modal show={this.state.showDeleteModal} onHide={() => this.setState({showDeleteModal: false})}>
                            <Modal.Header closeButton>
                                <Modal.Title>Potwierdzenie</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>Czy napewno chcesz usunąć: {this.state.shortName}</Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={() => this.setState({showDeleteModal: false})}>
                                    Anuluj
                                </Button>
                                <Button variant="primary" onClick={this.handleDeleteOnClick}>
                                    Usuń
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </Col>
                </Row>
            </div>
        )
    }
}

export default UserEdit;
