import React, {Component} from "react";
import '../App.css';
import {Alert, Button, Form, Modal} from "react-bootstrap";
import {AppContext} from "../AppContext";
import {Redirect} from "react-router-dom";
import axios from 'axios';


class Login extends Component {

    static contextType = AppContext;

    state = {
        login: '',
        password: '',
        user: {},
        isLogged: false,
        errorMessage: '',
        showResetPasswordModal: false,
        email: '',
        alertSendEmail: false,
        alertEmailNotExist: '',
        alertIncorrectEmail: false,
        checkAuth: false
    }

    componentDidMount() {
        const res = axios.get(`${this.context.hostname}/api/user/auth`, {
            headers: {
                "X-Requested-With": "XMLHttpRequest"
            },
            withCredentials: true,
        }).then(res => {
            console.log(res)
            if (res.status = 200) {
                this.context.toggleUserNameState(res.data.userName);
                this.context.toggleLoginState(res.data.email);
                this.context.togglePasswordState('');
                this.context.toggleLoggedState(true);
                this.context.toggleFirstNameState(res.data.firstName);
                this.context.toggleLastNameState(res.data.lastName);
                this.context.toggleVacationDays(res.data.vacationDays);
                res.data.roles.map(item => {
                    if (item.role === 'ADMIN') {
                        this.context.toggleAdminState(true);
                    }
                })
                this.setState({isLogged: true, checkAuth: true})
            }
        })
            .catch((error) => {
                console.log(error.response)
                this.setState({checkAuth: true})

            });
    }

    handleChange = (e) => {
        let value = e.target.value;
        let name = e.target.name;
        this.setState(
            {
                [name]: value,
            }
        )
    }

    handleSubmit = (e) => {
        e.preventDefault()
        this.submitToAPI(this.state);
    }


    submitToAPI = (props) => {
        console.log(`${this.context.hostname}/api/user/auth`);

        const res = axios.get(`${this.context.hostname}/api/user/auth`, {
            headers: {
                "X-Requested-With": "XMLHttpRequest"
            },
            withCredentials: true,
            auth: {
                username: `${props.login}`,
                password: `${props.password}`
            }
        }).then(res => {
            console.log(res)
            if (res.status = 200) {
                this.context.toggleUserNameState(res.data.userName);
                this.context.toggleLoginState(this.state.login);
                this.context.togglePasswordState('');
                this.context.toggleLoggedState(true);
                this.context.toggleFirstNameState(res.data.firstName);
                this.context.toggleLastNameState(res.data.lastName);
                this.context.toggleVacationDays(res.data.vacationDays);
                res.data.roles.map(item => {
                    if (item.role === 'ADMIN') {
                        this.context.toggleAdminState(true);
                    }
                })
                this.setState({isLogged: true})
            }
        })
            .catch((error) => {
                console.log(error.response)

                let errorMessage = 'Nieznany błąd.';
                if (error.response != null) {
                    if (error.response.status === 401) {
                        errorMessage = 'Błędny login lub hasło.'
                    }
                    if (error.response.status === 500) {
                        errorMessage = 'Błąd połączenia z serwerem.'
                    }
                } else {
                    errorMessage = 'Błąd połączenia z serwerem.'
                }
                this.setState({...this.state, errorMessage: errorMessage})
            });
    }

    handleResetPasswordOnClick = e => {
        if (validateEmail(this.state.email)) {
            axios.post(`${this.context.hostname}/api/user/resetPassword`, {
                    headers: {
                        "X-Requested-With": "XMLHttpRequest",
                        "Content-Type": "application/json",
                    },
                    "recipients": [this.state.email],
                },
                {withCredentials: true}
            ).then(res => {
                if (res.status === 200) {
                    this.setState({
                        showResetPasswordModal: false, alertSendEmail: true,
                        errorMessage: ''
                    })
                }
            })
                .catch((error) => {
                    let errorMessage = 'Nieznany błąd.';
                    if (error.response.status === 404) {
                        errorMessage = 'Podany email nie istnieje w naszej baszie danych.'
                    }
                    this.setState({
                        ...this.state,
                        errorMessage: errorMessage,
                        showResetPasswordModal: false,
                        alertSendEmail: false,
                    })
                });
        } else {
            this.setState({...this.state, alertIncorrectEmail: true})
        }
    }


    render() {

        return (
            <div className="fragment">
                {this.state.isLogged ? <Redirect to="/home"/> : ''}
                {this.state.checkAuth ? <>

                        {this.state.alertSendEmail ?
                            <Alert variant='primary'>Na podany email zostało wysłane nowe hasło.</Alert> : false}
                        {/*{this.state.alertEmailNotExist ?*/}
                        {/*    <Alert variant='warning'>Podany email nie istnieje w naszej baszie danych.</Alert> : false}*/}
                        {this.state.errorMessage != '' ?
                            <Alert variant='danger'>{this.state.errorMessage}</Alert> : false}

                        <Form style={{
                            paddingTop: '50px',
                            maxWidth: '500px',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            float: 'none',
                        }} className='span9 centred'>
                            <h3>
                                Logowanie </h3>
                            <Form.Group controlId="formBasicEmail">
                                <Form.Label>Email</Form.Label>
                                <Form.Control
                                    type="email"
                                    placeholder="Wpisz email..."
                                    value={this.state.login}
                                    onChange={this.handleChange}
                                    name="login"
                                />
                            </Form.Group>
                            <Form.Group controlId="formBasicPassword">
                                <Form.Label>Hasło</Form.Label>
                                <Form.Control
                                    value={this.state.password}
                                    onChange={this.handleChange}
                                    type="password"
                                    name="password"
                                    placeholder="Wpisz hasło..."
                                />
                                <Button
                                    onClick={() => this.setState({
                                        ...this.state,
                                        showResetPasswordModal: true, email: '',
                                        alertIncorrectEmail: false
                                    })}
                                    className="float-end"
                                    style={{color: "grey"}}
                                    size='sm' variant="link">Zapomniałeś hasło?</Button>
                            </Form.Group>

                            <Button
                                variant="outline-secondary"
                                type="submit"
                                onClick={this.handleSubmit}
                                style={{marginTop: 10}}
                            >
                                Zaloguj
                            </Button>
                        </Form>
                    </>
                    : false
                }
                <Modal show={this.state.showResetPasswordModal}
                       onHide={() => this.setState({showResetPasswordModal: false})}>
                    <Modal.Header closeButton>
                        <Modal.Title>Resetowanie hasła.</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.state.alertIncorrectEmail ?
                            <Alert variant='warning'>Nieprawidłowy adres email</Alert> : false}
                        Wprowadź swój email
                        <Form.Control
                            type="text" name="email"
                            value={this.state.email}
                            placeholder="Wpisz email..."
                            onChange={this.handleChange}
                        />
                    </Modal.Body>
                    <Modal.Footer>

                        <Button variant="secondary"
                                onClick={() => this.setState({showResetPasswordModal: false})}>
                            Anuluj
                        </Button>
                        <Button variant="primary" onClick={this.handleResetPasswordOnClick}>
                            Resetuj
                        </Button>
                    </Modal.Footer>
                </Modal>

            </div>
        )
    }
}

export default Login;


function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}
