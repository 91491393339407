import React, {useContext, useEffect} from "react";


import {MapContainer, Marker, TileLayer} from "react-leaflet";
import {Button, Card, Col, Container, Spinner} from "react-bootstrap";
import {Link, Redirect} from "react-router-dom";
import "leaflet-geosearch/dist/geosearch.css";
import {AppContext} from "../../AppContext";
import DbUser from "../../queries/DbUser";
import "../../App.css"
import axios from "axios";
import MapImageGenerator from "./MapImageGenerator";
import Row from "react-bootstrap/Row";

import MachineMarker from "./MachineMarker";



const Map = (props) => {

    const context = useContext(AppContext);

    const [state, setState] = React.useState({
        points: []
    })


    useEffect(() => {
        DbUser.getLoggedUser(context)

        axios.get(`${context.hostname}/api/device/with-gps-location-data`, {
            headers: {"X-Requested-With": "XMLHttpRequest"},
            withCredentials: true,
        }).then(res => {
            if (res.status === 200) {
                setState({
                    points: res.data
                })
            }
        })
            .catch((error) => {
                console.log(error)
            });
    }, [])


    const startPosition = [51.965578, 19.180303]


    const handlePrint = () => {

        context.toggleRefreshState({...context.refresh, map: new Date().valueOf()})
    }

    const downloadCurrentMap = () => {
        axios.get(`${context.hostname}/api/storage/download-public-file?fileName=machines-map.png`, {
            headers: {"X-Requested-With": "XMLHttpRequest"},
            responseType: 'blob',
            withCredentials: true,
        }).then(res => {
            if (res.status = 200) {
                var url = window.URL.createObjectURL(res.data);
                var a = document.createElement('a');
                a.href = url;
                a.download = ("machines-map.png");
                document.body.appendChild(a);
                a.click();
                a.remove();
            }
        })
            .catch((error) => {
                console.log(error)
            });
    }


    return (
        <>
            {context.redirectToLoginPage ? <Redirect to="/login"/> : ''}

            <Container style={{marginTop: 140, width: 1100, height: 1100, marginBottom: 200}}>
                <Row>
                    <Col>
                        <Button variant="primary" onClick={handlePrint} className="float-end">
                            <Spinner
                                as="span"
                                animation={context.refresh.uploadingMapImage === true ? "grow" : ""}
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                            {context.refresh.uploadingMapImage === false ? "Aktualizuj" : "...trwa generowanie"}
                        </Button>
                        <Button style={{marginRight: 10}} className="float-end" variant="dark"
                                onClick={downloadCurrentMap}>Pobierz aktualną wersje mapy</Button>
                    </Col>
                </Row>
                <Row>
                    <div className='d-flex justify-content-center'>
                        <Card style={{marginTop: 10, width: 1100, height: 1100}}>
                            <MapContainer
                                id={'map-devices'}
                                style={{height: 1100, width: 1100}}
                                center={startPosition}
                                zoom={7}
                                zoomControl={false}
                                attributionControl={false}
                                scrollWheelZoom={true}
                            >
                                <MapImageGenerator/>
                                <TileLayer
                                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                />
                                {state.points.map(p =>
                                    <>
                                        {p.latitude != null && p.longitude != null ?
                                            <MachineMarker color1={p.color1 != null ? p.color1 : '#adb5bd'} color2={p.color2 != null ? p.color2 : '#6c757d'} position={[p.latitude, p.longitude]}/>
                                            : false}
                                    </>
                                )}
                            </MapContainer>
                        </Card>
                    </div>
                </Row>
                <div
                    style={{fontSize: 14}}>{`${context.hostname}`}/api/storage/download-public-file?fileName=machines-map.png
                </div>
            </Container>

        </>
    )
}


export default Map;
