import React from 'react';

const Privacy = () => {
    return (
        <div style={{padding: 100}}>
            <h3>Polityka prywatności</h3>
            <br/>

            Witamy w aplikacji "Maszyny Polskie Serwis". Jesteśmy świadomi ważności ochrony prywatności naszych użytkowników i zobowiązujemy się do ochrony ich danych osobowych. Poniżej przedstawiamy naszą politykę prywatności, która opisuje, jak gromadzimy, używamy i chronimy dane osobowe.
            <br/><br/>
            Gromadzenie danych osobowych
            <br/> Podczas korzystania z aplikacji "Maszyny Polskie Serwis" możemy gromadzić różne rodzaje danych osobowych w celu świadczenia usług serwisowych. Przykłady takich danych obejmują:

            <br/>a) Dane identyfikacyjne: takie jak imię, nazwisko, adres e-mail, numer telefonu, firma, stanowisko, które mogą być wymagane podczas rejestracji konta użytkownika lub zgłaszania problemów technicznych.

            <br/> b) Dane dotyczące maszyn: informacje o maszynach, takie jak identyfikatory, dane techniczne, historie napraw, informacje o konserwacji itp. Te dane są zbierane w celu świadczenia usług serwisowych, diagnozowania problemów i zarządzania cyklem życia maszyn.

            <br/> c) Dane transakcyjne: w przypadku płatnych usług serwisowych możemy gromadzić dane dotyczące transakcji, takie jak informacje o płatnościach, faktury, dane kontaktowe do celów rozliczeniowych i obsługi klienta.

            <br/> <br/><h5>Cel gromadzenia danych</h5>
            <br/> Gromadzenie danych osobowych odbywa się w celu realizacji usług serwisowych i zapewnienia najlepszej jakości obsługi naszych użytkowników. Główne cele gromadzenia danych to:

            <br/>a) Świadczenie usług serwisowych: wykorzystujemy dane osobowe do zapewnienia obsługi serwisowej maszyn, w tym zgłaszania problemów, diagnozowania usterek, planowania i realizacji napraw oraz udzielania wsparcia technicznego.

            <br/>b) Komunikacja: korzystamy z danych kontaktowych, aby utrzymywać z Tobą regularną komunikację w związku z usługami serwisowymi, taką jak potwierdzenia zgłoszeń, informacje o naprawach, aktualizacje dotyczące maszyn itp.

            <br/> c) Doskonalenie usług: analizujemy zgromadzone dane w celu doskonalenia naszych usług serwisowych, identyfikowania obszarów wymagających poprawy, a także prowadzenia badań i analiz statystycznych.

            <br/><br/><h5>Udostępnianie danych osobowych</h5>
            <br/>Zobowiązujemy się do zachowania poufności i nieudostępniania danych osobowych użytkowników aplikacji "Maszyny Polskie Serwis" osobom trzecim, chyba że jest to niezbędne w celu świadczenia usług serwisowych. W takich przypadkach podejmujemy odpowiednie środki bezpieczeństwa i zawieramy umowy lub porozumienia w celu ochrony danych osobowych.

            <br/> Jednakże, w niektórych sytuacjach, możemy być zobowiązani do ujawnienia danych osobowych zgodnie z obowiązującym prawem, nakazem sądowym, postępowaniem prawnym lub w celu ochrony naszych praw, własności lub bezpieczeństwa.

            <br/><br/><h5> Bezpieczeństwo danych</h5>
            <br/> Przykładamy dużą wagę do zapewnienia bezpieczeństwa danych osobowych naszych użytkowników. Wdrażamy odpowiednie środki techniczne, organizacyjne i administracyjne, aby chronić dane przed nieautoryzowanym dostępem, utratą, kradzieżą lub manipulacją.

            <br/> Nasi pracownicy i osoby trzecie mają dostęp do danych osobowych jedynie w zakresie niezbędnym do świadczenia usług serwisowych. Wszyscy nasi pracownicy oraz osoby trzecie zobowiązują się do zachowania poufności i przestrzegania odpowiednich procedur bezpieczeństwa.

            <br/><br/><h5>Prawa użytkowników</h5>
            <br/>Zapewniamy naszym użytkownikom pewne prawa dotyczące ich danych osobowych. Przysługuje Ci:

            <br/> a) Prawo dostępu: możesz zażądać informacji o tym, jakie dane osobowe gromadzimy i jak są wykorzystywane.

            <br/> b) Prawo do poprawiania: jeżeli Twoje dane osobowe są nieprawidłowe lub niekompletne, masz prawo do ich poprawienia lub uzupełnienia.

            <br/> c) Prawo do usunięcia: możesz zażądać usunięcia swoich danych osobowych, o ile nie ma prawnego obowiązku ich przechowywania.

            <br/> d) Prawo do ograniczenia przetwarzania: w pewnych okolicznościach możesz zażądać ograniczenia przetwarzania Twoich danych osobowych.

            <br/> e) Prawo do przenoszenia danych: masz prawo otrzymać kopię swoich danych osobowych w formacie umożliwiającym przeniesienie do innego dostawcy usług.

            <br/> f) Prawo do wniesienia skargi: jeżeli uważasz, że naruszamy Twoje prawa prywatności, masz prawo wniesienia skargi do właściwego organu nadzorczego.


            <br/><br/><h5>Zmiany w polityce prywatności</h5>
            <br/> Niniejsza polityka prywatności może podlegać okresowym aktualizacjom, które odzwierciedlają zmiany w naszych praktykach dotyczących prywatności lub zmiany przepisów prawnych. Jeżeli wprowadzimy istotne zmiany w naszej polityce prywatności, poinformujemy Cię o nich poprzez powiadomienie w aplikacji lub wysłanie wiadomości na Twój adres e-mail.

            <br/> Zalecamy, aby regularnie zapoznawać się z naszą polityką prywatności, aby być świadomym sposobu, w jaki chronimy Twoje dane osobowe.

            <br/>  <br/><h5>Kontakt</h5>
            <br/> Jeżeli masz pytania, wątpliwości lub sugestie dotyczące naszej polityki prywatności lub sposobu, w jaki gromadzimy i przetwarzamy dane osobowe, prosimy o kontakt z naszym zespołem obsługi klienta. Z przyjemnością udzielimy Ci wszelkich potrzebnych informacji i wsparcia.

            <br/> Dziękujemy za zaufanie, jakim obdarzyłeś naszą aplikację "Maszyny Polskie Serwis". Zapewniamy Ci, że dbamy o Twoją prywatność i podejmujemy wszelkie niezbędne środki w celu ochrony Twoich danych osobowych.
            <br/>
            <br/>
        </div>
    );
};

export default Privacy;