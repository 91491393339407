import React, {Component} from "react";
import {Redirect} from "react-router-dom";
import {AppContext} from "../../AppContext";
import {Button} from "react-bootstrap";
import axios from "axios";
import DbUser from "../../queries/DbUser";


class Logs extends Component {

    static contextType = AppContext;

    state = {
        logs: [],
        redirect: ''
    }

    componentDidMount() {
        DbUser.getLoggedUser(this.context)

        axios.get(`${this.context.hostname}/api/log/findAll`, {
            headers: {"X-Requested-With": "XMLHttpRequest"},
            withCredentials: true,
        }).then(res => {
            if (res.status = 200) {
                this.setState({
                    logs: res.data
                })
            }
        })
            .catch((error) => {
                console.log(error)
            });
    }

    handleBackOnClick = () => {
        this.setState({redirect: '/home'})
    }

    render() {
        return (
            <div className="fragment">
                {this.context.redirectToLoginPage ? <Redirect to="/login"/> : ''}
                {/*{this.context.isAdmin === false ? <Redirect to="/home"/> : ''}*/}
                {this.state.redirect != '' ? <Redirect to={this.state.redirect}/> : ''}
                <h3>
                    <Button variant="light" onClick={this.handleBackOnClick}>
                        <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-arrow-return-left"
                             fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd"
                                  d="M14.5 1.5a.5.5 0 0 1 .5.5v4.8a2.5 2.5 0 0 1-2.5 2.5H2.707l3.347 3.346a.5.5 0 0 1-.708.708l-4.2-4.2a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 8.3H12.5A1.5 1.5 0 0 0 14 6.8V2a.5.5 0 0 1 .5-.5z"/>
                        </svg>
                    </Button>
                    &nbsp;Logi
                </h3>
                {this.state.logs.map(log =>
                        <div style={{fontSize: 12}}>
                            {log.eventId}&nbsp;
                            [{dateFromTimestamp(log.timestmp)}]&nbsp;&nbsp;
                            &nbsp;[{log.arg1}({log.arg0})]&nbsp;&nbsp;-
                            {log.formattedMessage}<br/>
                        </div>
                )}
            </div>
        )
    }
}

export default Logs;


function dateFromTimestamp(timestamp) {
    var date = new Date(timestamp);
    var hours = date.getHours();
    var minutes = "0" + date.getMinutes();
    var seconds = "0" + date.getSeconds();
    var formattedTime = date.getFullYear() + "-" + (date.getMonth() + 1) + '-' + date.getDate() + ' ' + hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);
    return formattedTime;
}
