import React, {Component} from "react";
import Card from "react-bootstrap/Card";
import {
    Accordion,
    Alert,
    Badge,
    Button,
    Col,
    Dropdown,
    Form, Image,
    Modal,
    OverlayTrigger,
    Tooltip
} from "react-bootstrap";
import {AppContext} from "../../AppContext";
import axios from "axios";
import Row from "react-bootstrap/Row";
import DbUser from "../../queries/DbUser";
import {Redirect} from "react-router-dom";
import ToolkitProvider, {Search} from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import Utils from "../../utils/Utils";
import {PDFViewer} from "react-view-pdf";


const defaultSorted = [{
    dataField: 'fullNumber',
    order: 'desc'
}];

const {SearchBar} = Search;

const searchProps = {
    delay: 800,
    placeholder: "wpisz aby wyszukać",
};

const sizePerPageRenderer = ({
                                 options,
                                 currSizePerPage,
                                 onSizePerPageChange
                             }) => (
    <div className="btn-group" role="group">
        {
            options.map((option) => {
                const isSelect = currSizePerPage === `${option.page}`;
                return (
                    <button style={{fontSize: 12}}
                            key={option.text}
                            type="button"
                            onClick={() => onSizePerPageChange(option.page)}
                            className={`btn ${isSelect ? 'btn-secondary' : 'btn-warning'}`}
                    >
                        {option.text}
                    </button>
                );
            })
        }
    </div>
);

const options = {
    sizePerPageRenderer,
    sizePerPageList: [{
        text: '15', value: 15
    }, {
        text: '30', value: 30
    }, {
        text: '50', value: 50
    }, {
        text: '100', value: 100
    }]
};

class MachineService extends Component {

    static contextType = AppContext;

    initialVariables = {
        idDevice: this.props.data.idDevice,
        idCustomer: this.props.data.idCustomer,
        isShowedModalNewService: false,
        isShowedModalAddFile: false,
        isShowedModalDeleteFile: false,
        isShowedModalDeleteService: false,
        idService: 0,
        number: '(auto)',
        numberExtension: '',
        fullNumber: '',
        date: '',
        currentType: new Set(),
        paymentType: {enum: "PLATNA", name: "Płatna"},
        currentFilename: '',
        isCheckedToSign: false,
        disabledIsCheckedToSign: false,
        errorAddService: '',
        selectedId: 0,
        selectedFileId: 0,
        selectedServiceId: 0,
        errorServiceIsNotLast: false,
        // checkBoxes
        PRZEGLAD_OKRESOWY: false,
        MONTAZ: false,
        NAPRAWA: false,
        SZKOLENIE_OPERATOROW: false,
        INSTALACJA: false,
        PROTOKOL_PRZEDMONTAZOWY: false,
        //
        faultDescription: '',
        period: 0,
        devices: [],
        month: 'MM',
        year: 'yyyy',
        isDisabledNumberField: true,
        errorDeleteService: '',
        pdfURL: null,
        currentFileId: '',
        currentFileName: '',
        isShowedModalPreviewPDF: false,
        isShowedModalPreviewImage: false,
        response: '',
        errorDeleteFile: '',
        isShowedModalPreviewOther: false
    }

    state = {
        services: this.props.data.services,
        ...this.initialVariables,
        currentMachine: {id: '', category: {name: ''}},
        devices: [],
        customerAllDevices: [],
        isShowedAdditionalMachine: false,
        month: 'MM',
        year: 'yyyy',
        isDisabledNumberField: true,
        response: '',
        errorDeleteFile: ''
    }

    componentDidMount() {
        DbUser.getLoggedUser(this.context)

        axios.get(`${this.context.hostname}/api/service/findByDeviceWithFilesEnitiy/${this.props.data.idDevice}`, {
            headers: {"X-Requested-With": "XMLHttpRequest"},
            withCredentials: true,
        }).then(res => {
            if (res.status = 200) {
                this.setState({
                    ...this.state,
                    services: res.data,
                    ...this.initialVariables,
                })
            }
        })
            .catch((error) => {
                console.log(error)
            });

        axios.get(`${this.context.hostname}/api/device/findByCustomer/${this.props.data.idCustomer}`, {
            headers: {"X-Requested-With": "XMLHttpRequest"},
            withCredentials: true,
        }).then(res => {
            if (res.status = 200) {
                let currentMachine = res.data.find(machine => machine.id == this.props.data.idDevice)
                let customerAllDevices = res.data.filter(m => m.id != currentMachine.id)
                this.setState({
                    ...this.state,
                    customerAllDevices: customerAllDevices,
                    currentMachine
                })
            }
        })
            .catch((error) => {
                console.log(error)
            });
    }

    handleOnClickCheckBoxIsSigning = e => {


        this.setState({
            ...this.state,
            isCheckedToSign: !this.state.isCheckedToSign,
            isDisabledNumberField: this.state.isCheckedToSign,
            number: this.state.isCheckedToSign ? '' : this.state.number,
            fullNumber: this.state.isCheckedToSign ? '' : this.state.fullNumber,
        })

    }

    handleOnClickFile = e => {

        const id = e.target.getAttribute('name')
        const fileName = e.target.id;

        var extension = Utils.getExtensionFromFilename(fileName)

        axios.get(`${this.context.hostname}/api/storage/downloadFile?fileName=${encodeURI(e.target.id)}`, {
            headers: {"X-Requested-With": "XMLHttpRequest"},
            responseType: 'blob',
            withCredentials: true,
        }).then(res => {
            if (res.status = 200) {
                console.log('sukces')
                console.log(fileName)
                const fileId = id
                if (extension === ".pdf") {
                    this.setState({
                        ...this.state,
                        isShowedModalPreviewPDF: true,
                        currentFileName: fileName,
                        pdfURL: window.URL.createObjectURL(res.data),
                        selectedFileId: id
                    })
                } else if (extension === ".jpg" || extension === ".bmp" || extension === ".png" || extension === ".gif" || extension === ".jpeg") {
                    this.setState({
                        ...this.state,
                        isShowedModalPreviewImage: true,
                        currentFileName: fileName,
                        selectedFileId: fileId
                    })
                } else {
                    this.setState({
                        ...this.state, isShowedModalPreviewOther: true,
                        currentFileName: fileName,
                        selectedFileId: fileId,
                        response: res.data
                    })
                }
            }
        })
            .catch((error) => {
                console.log(error)
            });
    }


    handleOnClickDownloadFile = e => {
        const id = e.target.getAttribute('name')
        const fileName = e.target.id;

        axios.get(`${this.context.hostname}/api/storage/downloadFile?fileName=${encodeURI(e.target.id)}`, {
            headers: {"X-Requested-With": "XMLHttpRequest"},
            responseType: 'blob',
            withCredentials: true,
        }).then(res => {
            if (res.status = 200) {
                var url = window.URL.createObjectURL(res.data);
                var a = document.createElement('a');
                a.href = url;
                a.download = (fileName);
                document.body.appendChild(a);
                a.click();
                a.remove();
            }
        })
            .catch((error) => {
                console.log(error)
            });
    }

    handleOnClickAddNewService = () => {
        this.setState({
            ...this.state,
            ...this.initialVariables,
            currentType: new Set([]),
            isShowedModalNewService: true,
            devices: [this.state.currentMachine]
        })
    }

    handleChangeSelectType = (e) => {
        let outType;
        this.context.data.serviceTypes.map(type => {
            if (type.name === e.target.value) {
                outType = type;
            }
        })

        this.setState({...this.state, [e.target.name]: outType})
    }

    handleChangeSelectPaymentType = (e) => {
        let outType;
        this.context.data.paymentType.map(type => {
            if (type.name === e.target.value) {
                outType = type;
            }
        })

        this.setState({...this.state, [e.target.name]: outType})
    }

    handleChange = (e) => {

        if (e.target.name == 'date') {
            let date = new Date(e.target.value);
            this.setState({
                ...this.state,
                [e.target.name]: e.target.value,
                month: date.getMonth() + 1,
                year: date.getFullYear()
            })
        } else {
            this.setState({
                ...this.state,
                [e.target.name]: e.target.value,

            })
        }
    }

    handleOnChangeNewFile = (e) => {
        this.setState({
            ...this.state,
            selectedFile: e.target.files[0],
            currentFilename: e.target.files[0].name
        });
    }

    handleOnClickDeleteFile = (e, data) => {
        this.setState({...this.state, isShowedModalDeleteFile: true, selectedFileId: e.target.name})
    }


    deleteFile = e => {
        axios.delete(`${this.context.hostname}/api/file/delete/${this.state.selectedFileId}`, {
            headers: {"X-Requested-With": "XMLHttpRequest"},
            withCredentials: true,
        }).then(res => {
            if (res.status === 200) {
                this.componentDidMount();
            }
        })
            .catch((error) => {
                console.log({error})
                let errorDeleteFile = 'Nieznany błąd.';
                if (error.response.status === 403) {
                    errorDeleteFile = 'Brak uprawnień do tej operacji.'
                }
                this.setState({...this.state, errorDeleteFile: errorDeleteFile})
            });
    }

    handleOnClickDeleteService = e => {
        const id = e.target.id
        this.setState({...this.state, isShowedModalDeleteService: true, selectedServiceId: id})
    }

    deleteService = e => {

        axios.delete(`${this.context.hostname}/api/service/delete/${this.state.selectedServiceId}`, {
            headers: {"X-Requested-With": "XMLHttpRequest"},
            withCredentials: true,
        }).then(res => {
            if (res.status === 200) {
                this.componentDidMount();
            }
        })
            .catch((error) => {
                console.log({error})
                if (error.response.status === 304) {
                    this.setState({errorServiceIsNotLast: true})
                }
                if (error.response.status === 403) {
                    this.setState({errorDeleteService: 'Brak uprawnień do tej operacji!'})
                }
            });
    }

    handleOnClickAddFile = e => {
        this.setState({...this.state, isShowedModalAddFile: true, selectedId: e.target.id})
    }

    upload = () => {
        let error = '';
        if (this.state.date === '') {
            error = 'Uzupełnij datę!'
        }
        if (!this.state.INSTALACJA && !this.state.SZKOLENIE_OPERATOROW &&
            !this.state.NAPRAWA && !this.state.PRZEGLAD_OKRESOWY && !this.state.PROTOKOL_PRZEDMONTAZOWY && !this.state.MONTAZ) {
            error = 'Wybierz typ serwisu!'
        }
        let period = this.state.period;
        if ((this.state.period === '') || !this.state.PRZEGLAD_OKRESOWY) {
            period = -1;
        }
        if (error != '') {
            this.setState({...this.state, errorAddService: error})
        }

        if (error === '') {
            var formData = new FormData()
            let devices = this.state.devices

            if (this.state.currentFilename !== '') {
                var input = document.querySelector('input[type="file"]')
                formData.append('protocolSerwisowyFile', input.files[0], this.state.currentFilename)
            }
            let number = this.state.number;
            if (!isNumeric(this.state.number)) {
                number = 0;
            }
            let numberExtension;
            if (this.state.numberExtension == '' || this.state.numberExtension == null) {
                numberExtension = ''
            } else {
                numberExtension = ",numberExtension:'" + this.state.numberExtension + "'"
            }

            formData.set("serviceJson", "{id:" + this.state.idService + ",date:'" +
                this.state.date +
                "',number: '" +
                number +
                "'" +
                numberExtension +
                ",paymentType: '" +
                this.state.paymentType.enum +
                "', type:'[" + Array.from(this.state.currentType).join(',') + "]'," +
                // "device:{id:" + this.state.idDevice + "}," +
                "customer:{id:" + this.state.idCustomer + "}, isToSign:" + this.state.isCheckedToSign + "," +
                "faultDescription: '" + this.state.faultDescription + "', period:" +
                period + ", devices: " + JSON.stringify(devices) +
                "}")
            axios.post(`${this.context.hostname}/api/service/add`, formData, {
                    headers: {
                        "X-Requested-With": "XMLHttpRequest",
                        "Content-Type": "application/json",
                    },
                    withCredentials: true,
                }
            ).then(res => {
                if (res.status === 200) {
                    this.componentDidMount()
                    this.setState({
                        ...this.state,
                        ...this.initialVariables,
                    })
                }
            })
                .catch((error) => {
                    console.log({error})
                    if (error.response.status === 409) {
                        this.setState({...this.state, errorAddService: 'Dokument z podanym numerem już istnieje!'})
                    }
                    if (error.response.status === 403) {
                        this.setState({...this.state, errorAddService: 'Brak uprawnień do tej operacji!'})
                    }
                })
        }
    };

    uploadFile = e => {
        var formData = new FormData()
        if (this.state.currentFilename !== '') {
            var input = document.querySelector('input[type="file"]')
            formData.append('file', input.files[0], this.state.currentFilename)
        }
        axios.patch(`${this.context.hostname}/api/service/addFile/${this.state.selectedId}`, formData, {
                headers: {
                    "X-Requested-With": "XMLHttpRequest",
                    "Content-Type": "application/json",
                },
                withCredentials: true,
            }
        ).then(res => {
            if (res.status === 200) {
                this.componentDidMount()
                this.setState({
                    ...this.state,
                    ...this.initialVariables,
                })
            }
        })
            .catch((error) => {
                console.log({error})
            });
    }

    handleChangeTypeCheckbox = e => {
        let currentType = this.state.currentType;
        if (e.target.checked) {
            currentType.add(e.target.name)
        } else {
            currentType.delete(e.target.name)
        }
        this.setState({
            ...this.state,
            [e.target.name]: e.target.checked,
            currentType: currentType
        })
    }

    handleAddAnotherMachineCheckBox = e => {
        let machine = this.state.customerAllDevices.find(m => m.id == e.target.id);
        let additionalMachines = this.state.devices;
        if (e.target.checked) {
            additionalMachines.push(machine)
        } else {
            let deviceIndex = additionalMachines.indexOf(machine);
            additionalMachines.splice(deviceIndex, 1)
        }
        this.setState({
            ...this.state,
            devices: additionalMachines,
        })
    }

    handleChangeNumber = e => {
        let fullNumber = e.target.value;
        let number = 0;
        let numberExtension = '';
        let errorAddService = '';
        if (isNumeric(fullNumber)) {
            number = fullNumber
        } else {
            if (isNumeric(fullNumber.substring(0, fullNumber.length - 1))) {
                number = fullNumber.substring(0, fullNumber.length - 1);
                numberExtension = fullNumber.substring(fullNumber.length - 1, fullNumber.length).toUpperCase();
                fullNumber = fullNumber.toUpperCase();
                // znaki od a-z
                if (numberExtension.charCodeAt(numberExtension.length - 1) < 65
                    || numberExtension.charCodeAt(numberExtension.length - 1) > 90) {
                    errorAddService = 'Niepoprawny numer protokołu (dopuszczalne np. 25, 25A, 25B...25Z)'
                    number = 0;
                    numberExtension = '';
                }
            } else {
                errorAddService = 'Niepoprawny numer protokołu (dopuszczalne np. 25, 25A, 25B...25Z)'
            }
        }
        if (fullNumber == '') {
            errorAddService = ''
        }
        this.setState({
            ...this.state,
            number,
            numberExtension,
            fullNumber,
            errorAddService
        })
    }


    render() {
        const onClickEvent = {

            onClick: (e, column, columnIndex, row, rowIndex) => {

                const service = this.state.services.find(s => s.id == row.id)
                let PRZEGLAD_OKRESOWY
                let MONTAZ
                let NAPRAWA
                let SZKOLENIE_OPERATOROW
                let INSTALACJA
                let PROTOKOL_PRZEDMONTAZOWY
                let currentType = new Set();
                if (service.type.includes("PRZEGLAD_OKRESOWY")) {
                    PRZEGLAD_OKRESOWY = true;
                    currentType.add("PRZEGLAD_OKRESOWY")
                }
                if (service.type.includes("MONTAZ,") || service.type.includes("MONTAZ]")) {
                    MONTAZ = true;
                    currentType.add("MONTAZ")
                }
                if (service.type.includes("NAPRAWA")) {
                    NAPRAWA = true;
                    currentType.add("NAPRAWA")
                }
                if (service.type.includes("SZKOLENIE_OPERATOROW")) {
                    SZKOLENIE_OPERATOROW = true;
                    currentType.add("SZKOLENIE_OPERATOROW")
                }
                if (service.type.includes("INSTALACJA")) {
                    INSTALACJA = true;
                    currentType.add("INSTALACJA")
                }
                if (service.type.includes("PROTOKOL_PRZEDMONTAZOWY")) {
                    PROTOKOL_PRZEDMONTAZOWY = true;
                    currentType.add("PROTOKOL_PRZEDMONTAZOWY")
                }


                let paymentType = this.context.data.paymentType.find(t => t.enum == service.paymentType)
                if (paymentType == undefined) {
                    paymentType = {enum: "PLATNA", name: "Płatna"};
                }
                let isCheckedToSign;
                let disabledIsCheckedToSign = false;
                if (service.state === "PODPISANY") {
                    isCheckedToSign = true;
                    disabledIsCheckedToSign = true;
                }
                if (this.context.isAdmin) {
                    disabledIsCheckedToSign = false;
                }
                let devices = service.devices;

                this.setState({
                    ...this.initialVariables,
                    ...this.state,
                    idService: service.id,
                    date: service.date,
                    paymentType: paymentType,
                    isShowedModalNewService: true,
                    isCheckedToSign: isCheckedToSign,
                    disabledIsCheckedToSign: disabledIsCheckedToSign,
                    PRZEGLAD_OKRESOWY,
                    NAPRAWA,
                    SZKOLENIE_OPERATOROW,
                    INSTALACJA,
                    PROTOKOL_PRZEDMONTAZOWY,
                    MONTAZ,
                    currentType,
                    faultDescription: service.faultDescription,
                    period: (service.period < 0 ? 0 : service.period),
                    devices: devices,
                    year: service.year,
                    month: service.month,
                    number: (service.number === 0 ? '' : service.number),
                    numberExtension: service.numberExtension,
                    fullNumber: (service.number === 0 ? '' : service.number) + (service.numberExtension == null ? '' : service.numberExtension),
                    isDisabledNumberField: service.state == 'PODPISANY' ? false : true,
                })
            }
        }

        const columns = [
            {
                dataField: 'id',
                text: 'id',
                sort: true,
                events: onClickEvent,
                style: {width: '5%', verticalAlign: 'middle'},
                headerAlign: 'center',
                align: 'center'
            },
            {
                dataField: 'fullNumber',
                text: 'Numer',
                sort: true,
                events: onClickEvent,
                align: 'center',
                headerAlign: 'center',
                style: {width: '8%', minWidth: 70, verticalAlign: 'middle'},
                sortFunc: (a, b, order, dataField, rowA, rowB) => {
                    const a1 = Number(rowA.year + String(rowA.month).padStart(2, '0') + String(rowA.number).padStart(10, '0') + (rowA.numberExtension != null ? String(rowA.numberExtension.charCodeAt(0)).padStart(6, '0') : '000000'))
                    const a2 = Number(rowB.year + String(rowB.month).padStart(2, '0') + String(rowB.number).padStart(10, '0') + (rowB.numberExtension != null ? String(rowB.numberExtension.charCodeAt(0)).padStart(6, '0') : '000000'))
                    if (order === 'asc') {
                        return a1 - a2;
                    } else {
                        return a2 - a1;
                    }
                },
            },
            {dataField: 'number', hidden: true},
            {dataField: 'numberExtension', hidden: true},
            {dataField: 'month', hidden: true},
            {dataField: 'year', hidden: true},
            {
                dataField: 'date',
                text: 'Data',
                sort: true,
                events: onClickEvent,
                align: 'center',
                headerAlign: 'center',
                style: {width: '7%', minWidth: 80, verticalAlign: 'middle'},
                formatter: cell => `${cell != null ? cell.substring(0, 10) : false}`,
                sortFunc: (a, b, order, dataField, rowA, rowB) => {
                    const date1 = Date.parse(rowA.date);
                    const date2 = Date.parse(rowB.date)
                    if (order === 'asc') {
                        return date1 - date2;
                    } else {
                        return date2 - date1;
                    }
                },
            }, {
                dataField: 'devices',
                text: 'Maszyny',
                sort: true,
                events: onClickEvent,
                headerAlign: 'center',
                align: 'center',
                style: {width: '12%', minWidth: 100, verticalAlign: 'middle'},
                formatter: devicesFormatter
            },
            {
                dataField: 'type',
                text: 'Typ',
                sort: true,
                events: onClickEvent,
                headerAlign: 'center',
                align: 'center',
                style: {width: '6%', verticalAlign: 'middle'},
                formatter: typeFormatter
            },
            {
                dataField: 'paymentType',
                text: 'Koszt',
                sort: true,
                events: onClickEvent,
                headerAlign: 'center',
                align: 'center',
                style: {width: '6%', verticalAlign: 'middle'}
            },
            {
                dataField: 'faultDescription',
                text: 'Opis usterki',
                sort: true,
                events: onClickEvent,
                headerAlign: 'center',
                align: 'center',
                style: {width: '18%', minWidth: 200, verticalAlign: 'middle'},
                formatter: cell => <>
                    {cell.length > 80 ?
                        <OverlayTrigger
                            key='top'
                            placement='left'
                            overlay={
                                <Tooltip id={`tooltip-top`}>
                                    {cell}
                                </Tooltip>
                            }
                        >
                            <div variant="secondary">{cell.substring(0, 80)}...</div>
                        </OverlayTrigger> : <>{cell}</>}
                </>,

            }, {
                dataField: 'user',
                text: 'Pracownik',
                sort: true,
                events: onClickEvent,
                headerAlign: 'center',
                align: 'center',
                style: {width: '10%', verticalAlign: 'middle'}
            }, {
                dataField: 'state',
                text: 'Stan',
                sort: true,
                events: onClickEvent,
                headerAlign: 'center',
                align: 'center',
                style: {width: '8%', verticalAlign: 'middle'}
            }, {
                dataField: 'fileDtos',
                text: 'Pliki',
                headerAlign: 'right',
                align: 'center',
                sort: true,
                height: 60,
                formatter: filesFormatter,
                style: {width: '6%', verticalAlign: 'middle', height: 45,},
                formatExtraData: this
            }, {
                dataField: '',
                text: '',
                headerAlign: 'center',
                align: 'center',
                // sort: true,
                formatter: addFileFormatter,
                style: {width: '3%', verticalAlign: 'middle'},
                formatExtraData: this
            }];

        function typeFormatter(cell, row, rowIndex, formatExtraData) {
            let types = "";
            if (cell != null) {
                let t = [];
                t = Utils.getServiceTypesNamesAsArray(cell)
                t.map((type, i) =>
                    types = <>{types}{type === 'PRZEGLĄD OKRESOWY' && row.period > 0 ? <>{type.replace('PRZEGLĄD OKRESOWY', 'PRZEGLĄD OKRESOWY (' + row.period + 'm)')} </> :
                        <>{type}</>}{i < t.length-1 ? ',' : ''}<br/></>
                )
            }
            return types;
        }

        function devicesFormatter(cell, row, rowIndex, formatExtraData) {
            let devices = "";
            if (cell != null) {

                cell.map(d =>
                    devices = <>{devices}({d.id}) {d.name}<br/></>
                )
            }
            return devices;
        }

        function filesFormatter(cell, row, rowIndex, formatExtraData) {
            let files = "";
            if (cell != null) {
                cell.map(file => files = <>
                        {files}<Col xs={2} id={file.filename} className='clickableFont'
                        /*onClick={formatExtraData.handleOnClickFile}*/>

                        {Utils.getExtensionFromFilename(file.filename) === '.pdf' ?
                            <Dropdown>
                                <Dropdown.Toggle as='div' variant="success" id="dropdown-basic"
                                                 className='dropdown-toggle-without-arrow'>
                                    <i id={file.filename} className="bi bi-file-earmark-pdf" style={{fontSize: 18}}
                                       name={file.id}/>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item id={file.filename}
                                                   onClick={formatExtraData.handleOnClickFile}>Podgląd</Dropdown.Item>
                                    <Dropdown.Item id={file.filename}
                                                   onClick={formatExtraData.handleOnClickDownloadFile}>Pobierz</Dropdown.Item>
                                    <Dropdown.Item id={file.filename} name={file.id}
                                                   onClick={formatExtraData.handleOnClickDeleteFile}>Usuń</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                            :
                            Utils.getExtensionFromFilename(file.filename) === '.jpg' || Utils.getExtensionFromFilename(file.filename) === '.jpeg' ?

                                <Dropdown>
                                    <Dropdown.Toggle as='div' variant="success" id="dropdown-basic"
                                                     className='dropdown-toggle-without-arrow'>
                                        <i id={file.filename} className="bi bi-filetype-jpg" style={{fontSize: 18}}
                                           name={file.id}/>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item id={file.filename}
                                                       onClick={formatExtraData.handleOnClickFile}>Podgląd</Dropdown.Item>
                                        <Dropdown.Item id={file.filename}
                                                       onClick={formatExtraData.handleOnClickDownloadFile}>Pobierz</Dropdown.Item>
                                        <Dropdown.Item id={file.filename} name={file.id}
                                                       onClick={formatExtraData.handleOnClickDeleteFile}>Usuń</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                                :
                                <Dropdown>
                                    <Dropdown.Toggle as='div' variant="success" id="dropdown-basic"
                                                     className='dropdown-toggle-without-arrow'>
                                        <i id={file.filename} className="bi bi-file-earmark" style={{fontSize: 18}}
                                           name={file.id}/>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item disabled href="#/action-1">Podgląd</Dropdown.Item>
                                        <Dropdown.Item id={file.filename}
                                                       onClick={formatExtraData.handleOnClickDownloadFile}>Pobierz</Dropdown.Item>
                                        <Dropdown.Item id={file.filename} name={file.id}
                                                       onClick={formatExtraData.handleOnClickDeleteFile}>Usuń</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                        }
                    </Col>
                    </>
                )

            }
            return <Row>{files}</Row>;
        }

        function addFileFormatter(cell, row, rowIndex, formatExtraData) {

            return <i id={row.id} onClick={formatExtraData.handleOnClickAddFile} style={{marginLeft: 8}}
                      className="bi bi-plus-circle-dotted"></i>;
        }


        return (
            <>
                {this.context.redirectToLoginPage ? <Redirect to="/login"/> : ''}

                <div style={{fontSize: 12}}>
                    <ToolkitProvider
                        bootstrap3
                        keyField="id"
                        data={this.state.services}
                        columns={columns}
                        defaultSorted={defaultSorted}
                        bordered={false}
                        noDataIndication=""
                        search>
                        {
                            props => (
                                <div style={{fontSize: 12}}>
                                    <Row>
                                        <Col>
                                            <SearchBar
                                                {...props.searchProps}
                                                placeholder="Wpisz aby wyszukać..."
                                                srText=''
                                                style={{
                                                    marginTop: 12,
                                                    paddingBottom: 5,
                                                    paddingLeft: 12,
                                                    marginBottom: 0,
                                                }}
                                            /></Col>
                                        <Col>
                                            <Card style={{margin: '3px'}} border="white">
                                                <Card.Body style={{
                                                    paddingTop: '5px',
                                                    paddingRight: '5px',
                                                    paddingBottom: 0,
                                                    marginBottom: 0,
                                                    marginTop: '3px'
                                                }}>
                                                    <Button
                                                        className="float-end"
                                                        variant="outline-dark"
                                                        onClick={this.handleOnClickAddNewService}
                                                    >+</Button>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    </Row>
                                    <hr/>
                                    <BootstrapTable
                                        bootstrap3
                                        keyField="id"
                                        data={this.state.data}
                                        columns={columns}
                                        defaultSorted={defaultSorted}
                                        bordered={false}
                                        noDataIndication=""
                                        pagination={paginationFactory(options)}
                                        {...props.baseProps}
                                        style={{fontSize: 5}}
                                        wrapperClasses="table-responsive"
                                    />
                                </div>
                            )
                        }
                    </ToolkitProvider>

                </div>


                {/**************************** MODAL ADD SERVICE ****************************/}
                <Modal show={this.state.isShowedModalNewService} size="lg"
                       onHide={() => this.setState({...this.state, isShowedModalNewService: false})}>
                    <Modal.Header closeButton>
                        {this.state.idService === 0 ? <Modal.Title>Nowe zdarzenie serwisowe</Modal.Title> :
                            <Modal.Title>Edycja zdarzenia serwisowego</Modal.Title>}

                    </Modal.Header>
                    <Modal.Body>
                        {this.state.errorAddService === '' ? false :
                            <Alert variant='danger'>
                                {this.state.errorAddService}
                            </Alert>}

                        <Accordion>

                            <Accordion.Item eventKey="0">
                                <OverlayTrigger

                                    placement='right'
                                    overlay={
                                        <Tooltip id={`tooltip-${'right'}`}>
                                            Dodaje inną maszynę tego kontrahenta do wspólnego protokołu
                                            serwisowego.
                                        </Tooltip>
                                    }
                                >
                                    <Accordion.Header><b
                                        style={{fontSize: 16}}>({this.state.currentMachine.id})&nbsp;
                                        {this.state.currentMachine.name} •&nbsp;
                                        {this.state.currentMachine.category.name}
                                        {this.state.devices.length > 1 ? <>&nbsp;+&nbsp;
                                            <Badge>{this.state.devices.length - 1}</Badge></> : false}
                                    </b></Accordion.Header>
                                </OverlayTrigger>
                                <Accordion.Body>

                                    {this.state.customerAllDevices.map(machine =>

                                        (this.state.devices.filter(m => m.id === machine.id).length > 0 ?
                                                <>
                                                    <Form.Check
                                                        id={machine.id}
                                                        style={{fontSize: 14}}
                                                        name={machine.id}
                                                        onChange={this.handleAddAnotherMachineCheckBox}
                                                        type='checkbox'
                                                        checked={true}
                                                        label={"(" + machine.id + ") " + machine.name + " • " + machine.category.name}
                                                    /> </> :
                                                <Form.Check
                                                    id={machine.id}
                                                    style={{fontSize: 14}}
                                                    name={machine.id}
                                                    onChange={this.handleAddAnotherMachineCheckBox}
                                                    type='checkbox'
                                                    checked={false}
                                                    label={"(" + machine.id + ") " + machine.name + " • " + machine.category.name}
                                                />
                                        )
                                    )
                                    }

                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                        <br/>

                        <Row className="align-items-bottom">
                            <Col style={{paddingRight: 1}} xs lg="2">
                                <div>
                                    <Form.Label>Numer</Form.Label>
                                    <Form.Control
                                        disabled={this.state.isDisabledNumberField}
                                        type="text"
                                        name="number"
                                        value={this.state.fullNumber}
                                        onChange={this.handleChangeNumber}
                                        placeholder="(auto)"
                                    />
                                </div>
                            </Col>
                            <Col style={{paddingLeft: 0}} xs lg="2">

                                <Form.Label>&nbsp;</Form.Label>
                                <Form.Control plaintext readOnly
                                              value={"/" + this.state.month + "/" + this.state.year}/>


                            </Col>
                            <Col lg="4">
                                <Form.Label>Data</Form.Label>
                                <Form.Control type="date" name="date"
                                              value={this.state.date}
                                              onChange={this.handleChange}
                                />
                            </Col>
                        </Row>
                        <Form.Label>Rodzaj serwisu</Form.Label>

                        <Form.Check
                            checked={this.state.PRZEGLAD_OKRESOWY}
                            name='PRZEGLAD_OKRESOWY'
                            onChange={this.handleChangeTypeCheckbox}
                            type='checkbox'
                            label={this.context.data.serviceTypes[0].name}
                            id={this.context.data.serviceTypes[0].enum}
                        />

                        <Form.Check
                            checked={this.state.MONTAZ}
                            name='MONTAZ'
                            onChange={this.handleChangeTypeCheckbox}
                            type='checkbox'
                            label={this.context.data.serviceTypes[5].name}
                            id={this.context.data.serviceTypes[5].enum}
                        />

                        <Form.Check
                            checked={this.state.NAPRAWA}
                            name='NAPRAWA'
                            onChange={this.handleChangeTypeCheckbox}
                            type='checkbox'
                            label={this.context.data.serviceTypes[1].name}
                            id={this.context.data.serviceTypes[1].enum}
                        />
                        <Form.Check
                            checked={this.state.SZKOLENIE_OPERATOROW}
                            name='SZKOLENIE_OPERATOROW'
                            onChange={this.handleChangeTypeCheckbox}
                            type='checkbox'
                            label={this.context.data.serviceTypes[2].name}
                            id={this.context.data.serviceTypes[2].enum}
                        />
                        <Form.Check
                            checked={this.state.INSTALACJA}
                            name='INSTALACJA'
                            onChange={this.handleChangeTypeCheckbox}
                            type='checkbox'
                            label={this.context.data.serviceTypes[3].name}
                            id={this.context.data.serviceTypes[3].enum}
                        />
                        <Form.Check
                            checked={this.state.PROTOKOL_PRZEDMONTAZOWY}
                            name='PROTOKOL_PRZEDMONTAZOWY'
                            onChange={this.handleChangeTypeCheckbox}
                            type='checkbox'
                            label={this.context.data.serviceTypes[4].name}
                            id={this.context.data.serviceTypes[4].enum}
                        />
                        <div>
                            <Row>
                                <Col>
                                    <Form.Label>Koszt</Form.Label>
                                    <Form.Control as="select" name="paymentType"
                                                  value={this.state.paymentType.name}
                                                  onChange={this.handleChangeSelectPaymentType}
                                    >
                                        {this.context.data.paymentType.map(type =>
                                            <option id={type}>{type.name}</option>
                                        )}
                                    </Form.Control>
                                </Col>
                                <Col>
                                    {this.state.PRZEGLAD_OKRESOWY ?
                                        <>
                                            <Form.Label>Miesiąc przeglądu okr.</Form.Label>
                                            <OverlayTrigger

                                                placement='right'
                                                overlay={
                                                    <Tooltip>
                                                        Wpisz którego miesiąca gwarancji dotyczy
                                                        przegląd <strong>np.6,12,18,24...</strong>.
                                                    </Tooltip>
                                                }
                                            >
                                                <Form.Control type="number" name="period" min='0' step='6'
                                                              value={this.state.period}
                                                              onChange={this.handleChange}
                                                />
                                            </OverlayTrigger></> : false}

                                </Col>
                            </Row></div>

                        <Form.Label>Opis usterki</Form.Label>
                        <Form.Control
                            as='textarea'
                            type="text" name="faultDescription"
                            value={this.state.faultDescription}
                            placeholder="Wpisz..."
                            onChange={this.handleChange}
                            style={{height: '100px'}}
                        />
                        <br/>
                        <Form.Check type="checkbox"
                                    onClick={this.handleOnClickCheckBoxIsSigning}
                                    checked={this.state.isCheckedToSign}
                                    disabled={this.state.disabledIsCheckedToSign}
                                    label="Czy podpisać zdarzenie serwisowe?"/>
                        <br/>
                        <Form.Control
                            type="file"
                            onChange={this.handleOnChangeNewFile}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary"
                                onClick={() => this.setState({
                                    ...this.state,
                                    isShowedModalNewService: false
                                })}>
                            Anuluj
                        </Button>
                        <Button variant="primary"
                                onClick={this.upload}
                        >
                            Dodaj
                        </Button>
                    </Modal.Footer>
                </Modal>

                {/********************************* MODAL ADD FILE ***********************************/}
                <Modal show={this.state.isShowedModalAddFile}
                       onHide={() => this.setState({...this.state, isShowedModalAddFile: false})}>
                    <Modal.Header closeButton>
                        <Modal.Title>Dodaj załącznik</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <Form.Control
                            type="file"
                            onChange={this.handleOnChangeNewFile}
                        />

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary"
                                onClick={() => this.setState({...this.state, isShowedModalAddFile: false})}>
                            Anuluj
                        </Button>
                        <Button variant="primary"
                                onClick={this.uploadFile}
                        >
                            Dodaj
                        </Button>
                    </Modal.Footer>
                </Modal>

                {/********************************* MODAL CONFIRMATION DELETE FILE ***********************************/}
                <Modal show={this.state.isShowedModalDeleteFile}
                       onHide={() => this.setState({...this.state, isShowedModalDeleteFile: false})}>
                    <Modal.Header closeButton>
                        <Modal.Title>Czy napewno chcez usunąć wybrany plik?</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.state.errorDeleteService === '' ? false :
                            <Alert variant='danger'>
                                {this.state.errorDeleteService}
                            </Alert>}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary"
                                onClick={() => this.setState({
                                    ...this.state,
                                    isShowedModalDeleteFile: false
                                })}>
                            NIE
                        </Button>
                        <Button variant="primary"
                                onClick={this.deleteFile}
                        >
                            TAK
                        </Button>
                    </Modal.Footer>
                </Modal>

                {/********************************* MODAL CONFIRMATION DELETE SERVICE ***********************************/}
                <Modal show={this.state.isShowedModalDeleteService}
                       onHide={() => this.setState({...this.state, isShowedModalDeleteService: false})}>
                    <Modal.Header closeButton>
                        <Modal.Title>Czy napewno chcez usunąć wybrane zdarzenie serwisowe?</Modal.Title>
                    </Modal.Header>
                    {this.state.errorDeleteService ?
                        <Alert variant='danger'>Brak uprawnień do tej operacji!</Alert> : false}
                    <Modal.Body>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary"
                                onClick={() => this.setState({
                                    ...this.state,
                                    isShowedModalDeleteService: false,
                                    errorServiceIsNotLast: false
                                })}>
                            NIE
                        </Button>
                        <Button variant="primary"
                                onClick={this.deleteService}
                        >
                            TAK
                        </Button>
                    </Modal.Footer>
                </Modal>

                {/*************************MODAL PREVIEW IMAGE********************************/}
                <Modal
                    show={this.state.isShowedModalPreviewImage}
                    onHide={() => this.setState({...this.state, isShowedModalPreviewImage: false})
                    }
                    fullscreen
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="example-custom-modal-styling-title">
                            {this.state.currentDescription}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <i onClick={this.handleOnClickDeleteFile} style={{marginLeft: 50}}
                           className="bi bi-trash float-end"/>
                        <div className='d-flex justify-content-center'>

                            <Image
                                style={{maxWidth: '100%'}}
                                src={`${this.context.hostname}/api/storage/downloadFile?fileName=${encodeURI(this.state.currentFileName)}`}
                                rounded/>
                        </div>
                    </Modal.Body>
                </Modal>

                {/*************************MODAL PREVIEW PDF********************************/}
                <Modal
                    show={this.state.isShowedModalPreviewPDF}
                    onHide={() => this.setState({...this.state, isShowedModalPreviewPDF: false})
                    }
                    fullscreen
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="example-custom-modal-styling-title">
                            {this.state.currentFileName}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <i onClick={this.handleOnClickDeleteFile} style={{marginLeft: 50}}
                           className="bi bi-trash float-end"/>
                        <div className='d-flex justify-content-center'>
                            <PDFViewer
                                url={this.state.pdfURL}/>
                        </div>
                    </Modal.Body>
                </Modal>
            </>
        )
    }
}

function

Padder(len, pad) {
    if (len === undefined) {
        len = 1;
    } else if (pad === undefined) {
        pad = '0';
    }

    var pads = '';
    while (pads.length < len) {
        pads += pad;
    }

    this.pad = function (what) {
        var s = what.toString();
        return pads.substring(0, pads.length - s.length) + s;
    };
}

function

isNumeric(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
}

export default MachineService;
