import React, {useContext, useEffect} from 'react';
import Card from "react-bootstrap/Card";
import {Button, Col, Form, FormLabel, Modal, OverlayTrigger, Row, Tooltip} from "react-bootstrap";
import axios from "axios";
import {AppContext} from "../../AppContext";

const InstallationOperations = () => {


    const [operations, setOperations] = React.useState([])
    const [state, setState] = React.useState({
        showDeleteModal: false,
        showFormModal: false,
        currentId: 0,
        id: 0,
        text: '',
        orderNumber: 0,
        tableHeader: '',
        title: ''
    })
    const context = useContext(AppContext);


    useEffect(() => {
        getInstallationOperations()
        getConfigurations()
    }, [])

    const getInstallationOperations = () => {
        axios.get(`${context.hostname}/api/installation-operation`, {
            headers: {"X-Requested-With": "XMLHttpRequest"},
            withCredentials: true,
        }).then(res => {
            if (res.status = 200) {
                setOperations(res.data)
            }
        })
            .catch((error) => {
                console.log(error)
            });
    }

    const handleOnClickMoveUp = e => {
        axios.patch(`${context.hostname}/api/installation-operation/move-up?installationOperationId=${e.target.id}`, {
                headers: {
                    "X-Requested-With": "XMLHttpRequest",
                    "Content-Type": "application/json",
                }
            },
            {withCredentials: true}
        ).then(res => {
            if (res.status === 200) {
                getInstallationOperations()
            }
        })
            .catch((error) => {
                console.log({error})

            });
    }

    const handleOnClickMoveDown = e => {
        axios.patch(`${context.hostname}/api/installation-operation/move-down?installationOperationId=${e.target.id}`, {
                headers: {
                    "X-Requested-With": "XMLHttpRequest",
                    "Content-Type": "application/json",
                }
            },
            {withCredentials: true}
        ).then(res => {
            if (res.status === 200) {
                getInstallationOperations()
            }
        })
            .catch((error) => {
                console.log({error})

            });
    }

    const handleAddOperation = e => {
        setState({...state, showFormModal: true, text: '', id: 0, orderNumber: 0})
    }

    const handleOnClickDelete = e => {
        axios.delete(`${context.hostname}/api/installation-operation?id=${state.currentId}`, {
            headers: {"X-Requested-With": "XMLHttpRequest"},
            withCredentials: true,
        }).then(res => {
            if (res.status === 200) {
                getInstallationOperations()
                setState({...state, showDeleteModal: false})
            }
        })
            .catch((error) => {
                console.log({error})

            });
    }

    const addOperationRequest = e => {
        axios.post(`${context.hostname}/api/installation-operation`, {
                headers: {
                    "X-Requested-With": "XMLHttpRequest",
                    "Content-Type": "application/json",
                },
                "text": state.text
            },
            {withCredentials: true}
        ).then(res => {
            if (res.status === 200) {
                getInstallationOperations()
                setState({...state, showFormModal: false})
            }
        })
            .catch((error) => {
                console.log({error})
            });
    }



    const updateOperationRequest = e => {
        axios.put(`${context.hostname}/api/installation-operation`, {
                headers: {
                    "X-Requested-With": "XMLHttpRequest",
                    "Content-Type": "application/json",
                },
                "id": state.id,
                "text": state.text,
                "orderNumber": state.orderNumber
            },
            {withCredentials: true}
        ).then(res => {
            if (res.status === 200) {
                getInstallationOperations()
                setState({...state, showFormModal: false})
            }
        })
            .catch((error) => {
                console.log({error})
            });
    }

    const saveConfiguration = e => {
        const data = [{name: 'pdf/installation-protocol/title', value: state.title},
            {name: 'pdf/installation-protocol/table-header', value: state.tableHeader}];

        const config = {
            headers: {
                "X-Requested-With": "XMLHttpRequest",
                'Content-Type': 'application/json'
            },
        };

        axios.post(`${context.hostname}/api/configuration`, data, config, {withCredentials: true})
            .then((response) => {
                console.log('Data saved successfully', response);
            })
            .catch((error) => {
                console.error('Error saving data', error);
            });
    }

    const getConfigurations = e => {
        axios.defaults.withCredentials = true;
        axios.all([
            axios.get(`${context.hostname}/api/configuration?name=pdf/installation-protocol/title`),
            axios.get(`${context.hostname}/api/configuration?name=pdf/installation-protocol/table-header`)
        ]).then(axios.spread((
            title,
            tableHeader,
        ) => {
           setState({...state, title: title.data.value, tableHeader: tableHeader.data.value})

        }))
            .catch(error => {
                console.log(error)
            });
    }


    const handleChange = (e) => {
        let value = e.target.value;
        let name = e.target.name;
        setState(
            {
                ...state,
                [name]: value,
            }
        )
    }

    const handleOnClickOperation = (e, item) => {
        setState({
            ...state,
            ...item,
            showFormModal: true
        })
    }

    const saveBasicParameters = e => {
        saveConfiguration()
    }


    return (
        <div>

            <Card style={{padding: 10}}>
                <h3 style={{margin: '3px'}}>Ustawienia protokołu montażu</h3>

            {/*    <Card style={{padding: 10, margin: '3px'}}>*/}
            {/*        <Form.Label>Tytuł</Form.Label>*/}
            {/*        <Form.Control*/}
            {/*            value={state.title}*/}
            {/*            onChange={handleChange}*/}
            {/*            name="title"*/}
            {/*            placeholder="Wpisz tekst..."*/}
            {/*        />*/}
            {/*        <Form.Label>Nagłówek tabeli</Form.Label>*/}
            {/*        <Form.Control*/}
            {/*            value={state.tableHeader}*/}
            {/*            onChange={handleChange}*/}
            {/*            name="tableHeader"*/}
            {/*            placeholder="Wpisz tekst..."*/}
            {/*        />*/}
            {/*        <Card style={{margin: '3px'}} border="white">*/}
            {/*            <Card.Body style={{padding: '5px', margin: '3px'}}>*/}
            {/*                <Button*/}
            {/*                    className="float-end"*/}
            {/*                    variant="outline-dark"*/}
            {/*                    onClick={saveBasicParameters}*/}
            {/*                >Zapisz</Button>*/}
            {/*            </Card.Body>*/}
            {/*        </Card>*/}
            {/*    </Card>*/}
            {/*    <br/>*/}


                <Card style={{margin: '3px'}} border="white">
                    <Card.Body style={{padding: '5px', margin: '3px'}}>

                        <Button
                            className="float-end"
                            variant="outline-dark"
                            onClick={handleAddOperation}
                        >+</Button>

                    </Card.Body>
                </Card>
                <h5 style={{margin: '3px'}}>Procesy montażowe</h5>
                {operations.map((item, index) =>


                    <Card style={{margin: '3px'}}>
                        <Card.Body value={index}
                                   style={{padding: '5px', margin: '3px'}}>
                            <Row>
                                <Col>
                                    <Button
                                        style={{
                                            textAlign: 'left',
                                            backgroundColor: 'white',
                                            border: 'none',
                                            // width: '92%',
                                            width: '100%',
                                            display: "inline-block",
                                            marginRight: 30,
                                            paddingRight: 30
                                        }}
                                        variant="light"
                                        size="sm"
                                        onClick={(e) => handleOnClickOperation(e, item)}
                                        name={item.text}
                                        id={item.id}
                                    >{index + 1}. {item.text}
                                    </Button>
                                </Col>

                                <Col sm={1} style={{width: 70}}>
                                    <Row>
                                        <Col style={{padding: 0}}>
                                            <i className="bi-chevron-down clickableFont" id={item.id}
                                               onClick={handleOnClickMoveDown}
                                            ></i>
                                        </Col>
                                        <Col style={{padding: 0}}>
                                            <i className="bi bi-chevron-up clickableFont" id={item.id}
                                               onClick={handleOnClickMoveUp}
                                            >
                                            </i>
                                        </Col>
                                        <Col style={{padding: 0}}>
                                            <i className="bbi bi-x-square  clickableFont" id={item.id}
                                               onClick={() => setState({
                                                   ...state,
                                                   showDeleteModal: true,
                                                   currentId: item.id
                                               })}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                )}

            </Card>


            {/*---------------------------------------DELETE MODAL------------------------------------------*/}
            <Modal show={state.showDeleteModal} onHide={() => this.setState({showDeleteModal: false})}>
                <Modal.Header closeButton>
                    <Modal.Title>Potwierdzenie</Modal.Title>
                </Modal.Header>
                <Modal.Body>Czy napewno chcesz usunąć?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setState({showDeleteModal: false})}>
                        Anuluj
                    </Button>
                    <Button variant="primary" onClick={handleOnClickDelete}>
                        Usuń
                    </Button>
                </Modal.Footer>
            </Modal>

            {/*---------------------------------------FORM MODAL------------------------------------------*/}
            <Modal show={state.showFormModal} onHide={() => this.setState({showFormModal: false})}>
                <Modal.Header closeButton>
                    <Modal.Title>Pozycja na protoloke montażu</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Control
                        value={state.text}
                        onChange={handleChange}
                        name="text"
                        placeholder="Wpisz tekst..."
                    />

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setState({showFormModal: false})}>
                        Anuluj
                    </Button>
                    <Button variant="primary" onClick={state.id > 0 ? updateOperationRequest : addOperationRequest}>
                        Zapisz
                    </Button>
                </Modal.Footer>
            </Modal>

        </div>
    );
};

export default InstallationOperations;