import React from 'react';
import {Marker} from 'react-leaflet';
import L from 'leaflet';

function MachineMarker(props) {

    const machineMarker = L.icon({
        iconUrl: `data:image/svg+xml;base64,${btoa(
            `<?xml version="1.0" encoding="utf-8"?>
                    <svg version="1.1" id="Warstwa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                     viewBox="0 0 256 256" style="enable-background:new 0 0 256 256;" xml:space="preserve">
                    <style type="text/css">
                    .st0{fill:${props.color1};}
                    .st1{fill:${props.color2};}
                    .st2{fill:#FFFFFF;}
                    .st3{fill:#F0F0F0;}
                    .st4{display:none;fill:#0263A6;}
                    .st5{display:none;fill:#03558E;}
                    </style>
                    <desc>Created with Fabric.js 1.7.22</desc>
                    <path class="st0" d="M128,2C81.2,2,43.3,39.9,43.3,86.6c0,61.7,61,105.7,81,164.8c0.6,1.8,2.1,2.6,3.6,2.6
                    C143.3,163.4,146.2,78.2,128,2z"/>
                    <path class="st1" d="M128,2c46.8,0,84.7,37.9,84.7,84.6c0,61.7-61,105.7-81,164.8c-0.6,1.8-2.1,2.6-3.6,2.6V2z"/>
                    <path class="st2" d="M128,22.5c-34.8,0-63,28.2-63,63s28.2,63,63,63C144.8,106.5,144.8,64.5,128,22.5z"/>
                    <path class="st3" d="M128,22.5c34.8,0,63,28.2,63,63s-28.2,63-63,63V22.5z"/>
                    <g transform="translate(128 128) scale(0.72 0.72)">
                    <g transform="translate(-175.05 -175.05000000000004) scale(3.89 3.89)">
                    <path d="M32.4,44.3c-0.6,0-1.1-0.2-1.5-0.6l-1.1-1.1c-0.4-0.4-0.6-0.9-0.6-1.5c0-0.6,0.2-1.1,0.6-1.5l9.6-9.6
                    c-0.7-2.8,0.7-5.7,3.4-6.8c1.7-0.7,3.6-0.5,5.2,0.4c0.1,0.1,0.2,0.2,0.2,0.3c0,0.1,0,0.2-0.1,0.3l-4.7,4.7c-0.4,0.4-0.4,1,0,1.3
                    c0.4,0.4,1,0.4,1.3,0l4.7-4.7c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.2,0.1,0.3,0.2c0.2,0.3,0.3,0.5,0.4,0.8c1.2,2.9-0.2,6.3-3.1,7.5
                    c-1.2,0.5-2.5,0.6-3.7,0.2l-9.6,9.6C33.5,44.1,33,44.3,32.4,44.3z"/>
                    <path d="M46.8,45h-3.7c-1.4,0-2.5-1.1-2.5-2.5c0-0.7-0.4-1-0.7-1.2c-0.1-0.1-0.2-0.2-0.2-0.3c0-0.1,0-0.2,0.1-0.3l3.8-3.5
                    c0.1-0.1,0.2-0.1,0.3-0.1c1.5,0.2,3,0,4.3-0.6c2.1-0.9,3.8-2.5,4.7-4.7c0.9-2.1,0.9-4.5,0-6.6c-1.8-4.4-6.9-6.5-11.3-4.7
                    c-3.6,1.5-5.7,5.2-5.3,9c0,0.1,0,0.2-0.1,0.3l-3.6,3.6c-0.1,0.1-0.2,0.1-0.3,0.1c-0.1,0-0.2-0.1-0.3-0.1c-0.3-0.4-0.7-0.6-1.2-0.6
                    c0,0,0,0,0,0c-0.7,0-1.3-0.3-1.8-0.7c-0.5-0.5-0.7-1.1-0.7-1.8v-3.6c0-0.7,0.3-1.3,0.7-1.8c0.5-0.5,1.1-0.7,1.8-0.7h0
                    c0.9,0,1.2-0.6,1.4-0.9c0.1-0.3,0.3-1-0.3-1.6c-0.5-0.5-0.7-1.1-0.7-1.8c0-0.7,0.3-1.3,0.7-1.8l2.6-2.6c0.5-0.5,1.1-0.7,1.8-0.7
                    c0,0,0,0,0,0c0.7,0,1.3,0.3,1.8,0.7c0.6,0.6,1.3,0.4,1.6,0.3c0.3-0.1,0.9-0.5,0.9-1.4c0-1.4,1.1-2.5,2.5-2.5h3.7c0,0,0,0,0,0
                    c0.7,0,1.3,0.3,1.8,0.7c0.5,0.5,0.7,1.1,0.7,1.8c0,0.9,0.6,1.2,0.9,1.4c0.2,0.1,1,0.3,1.6-0.3c1-1,2.6-1,3.6,0l2.6,2.6
                    c0.5,0.5,0.7,1.1,0.7,1.8c0,0.7-0.3,1.3-0.7,1.8c-0.6,0.6-0.4,1.4-0.3,1.6c0.1,0.3,0.5,0.9,1.4,0.9c1.4,0,2.5,1.1,2.5,2.5l0,3.6
                    c0,1.4-1.1,2.5-2.5,2.5h0c-0.9,0-1.2,0.6-1.4,0.9c-0.1,0.2-0.3,1,0.3,1.6c1,1,1,2.6,0,3.5l-2.6,2.6c-1,1-2.6,1-3.6,0
                    c-0.6-0.6-1.3-0.4-1.6-0.3c-0.3,0.1-0.9,0.5-0.9,1.4c0,0.7-0.3,1.3-0.7,1.8c-0.2,0.2-0.5,0.4-0.8,0.5C47.5,44.9,47.2,45,46.8,45z"
                    />
                    </g>
                    </g>
                    </svg>`)}`,

        iconSize: [40, 40],
        iconAnchor: [20, 40],
        popupAnchor: [0, -40],
        shadowUrl: null,
        shadowSize: null,
        shadowAnchor: null
    });

    return (
        <Marker
            position={props.position}
            icon={machineMarker}
        />
    );
}

export default MachineMarker;