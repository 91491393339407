import React, {Component} from 'react';
import {AppContext} from "../AppContext";
import axios from "axios";

class LayoutUtil extends Component {

    static contextType = AppContext;

    static getStatusStyle (context, name)  {
        const statuses = context.data.deviceStatus;
        let style;
        statuses.map(s => {
            if (s.name == name) {
                style = {
                    backgroundColor: s.colorBackground,
                    borderColor: s.colorBackground,
                    color: s.colorText,
                    textAlign: 'center',
                    padding: '5px',
                    borderRadius: '5px',
                    width: 120
                }
            }
        })
        return style;
    }


    render() {
        return (
            <div>

            </div>
        );
    }
}

export default LayoutUtil;
