import React, {useContext, useEffect} from 'react';
import {AppContext} from "../../AppContext";
import {useMap} from "react-leaflet";
import leafletImage from "leaflet-image"
import axios from "axios";

const MapImageGenerator = () => {
    const context = useContext(AppContext);

    useEffect(() => {
        if (context.refresh.map > 0) {
            handlePrint()
        }
    }, [context.refresh.map])

    const map = useMap();

    function handlePrint() {
        context.toggleRefreshState({
            ...context.refresh,
            uploadingMapImage: true
        })
        leafletImage(map, function(err, canvas) {
            var img = document.createElement('img');
            var dimensions = map.getSize();
            img.width = dimensions.x;
            img.height = dimensions.y;
            img.src = canvas.toDataURL();
            uploadMapBase64Img(img.src)
        });
    }

    const uploadMapBase64Img = (base64String) => {
        axios.post(`${context.hostname}/api/storage/upload-public-file`, {
                headers: {
                    "X-Requested-With": "XMLHttpRequest",
                    "Content-Type": "application/json",
                },
                "name": 'map',
                "base64": base64String
            },
            {withCredentials: true}
        ).then(res => {
            if (res.status === 200) {
                context.toggleRefreshState({
                    ...context.refresh,
                    uploadingMapImage: false
                })
            }
        })
            .catch((error) => {
                console.log({error})
            });
    }



    return <>
        <button onClick={handlePrint}>Print Map</button>
    </>;
};

export default MapImageGenerator