import React, {Component} from "react";
import {Alert, Button, Form, Modal} from "react-bootstrap";
import {Redirect} from "react-router-dom";
import '../../App.css';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {AppContext} from "../../AppContext";
import axios from "axios";
import DbUser from "../../queries/DbUser";

class CustomerAdd extends Component {

    static contextType = AppContext;

    state = {
        id: '',
        shortName: '',
        fullName: '',
        streetAddress: '',
        zipCode: '',
        city: '',
        nip: '',
        regon: '',
        phoneNumber: '',
        email: '',
        validatorMessage: '',
        exit: false,
        redirect: ''
    }

    componentDidMount() {
        DbUser.getLoggedUser(this.context)
    }


    handleChange = (e) => {
        const value = e.target.value;
        const name = e.target.name;
        this.setState(
            {
                [name]: value,
            }
        )
    }

    handleSubmit = (e) => {
        e.preventDefault()
        const value = e.target.shortName.value;
        if (value.length < 3) {
            this.setState({
                ...this.state,
                validatorMessage: 'Nazwa skrócona musi mieć minimum 3 znaki'
            })
        }else if (value.length >20){
            this.setState({
                ...this.state,
                validatorMessage: 'Nazwa skrócona może mieć max 20 znaków.'
            })
        } else {
            this.submitToAPI(this.state.id, this.state.shortName, this.state.fullName, this.state.streetAddress, this.state.zipCode, this.state.city, this.state.province, this.state.nip, this.state.regon, this.state.phoneNumber, this.state.email)
            this.setState({...this.state,
                validatorMessage: ''
            })
        }
    }

    submitToAPI = (id, shortName, fullName, streetAddress, zipCode, city, province, nip, regon, phoneNumber, email) => {
        axios.post(`${this.context.hostname}/api/customer/insert`, {
                headers: {
                    "X-Requested-With": "XMLHttpRequest",
                    "Content-Type": "application/json",
                },
                "city": city,
                "email": email,
                "fullName": fullName,
                "id": id,
                "nip": nip,
                "phoneNumber": phoneNumber,
                "regon": regon,
                "shortName": shortName,
                "streetAddress": streetAddress,
                "zipCode": zipCode,
                "province": province,
            },
            {withCredentials: true}
        ).then(res => {
            if (res.status === 200) {
                console.log(res)
                this.setState({...this.state, exit: true,})
            }
        })
            .catch((error) => {
                console.log({error})
                let validatorMessage = 'Nieznany błąd.';
                if (error.response.status === 409) {
                    validatorMessage = 'Kontrahent z podaną nazwą już istnieje.'
                }
                this.setState({...this.state, validatorMessage: validatorMessage})
            });
    }

    handleBackOnClick = () => {
        this.setState({...this.state, redirect: '/customerList'})
    }

    render() {

        if (this.state.exit === true) {
            return (<Redirect to="/customerList"/>
            )
        }

        return (

            <div className="fragment">
                {this.context.redirectToLoginPage ? <Redirect to="/login"/> : ''}
                {this.state.redirect != '' ? <Redirect to={this.state.redirect}/> : ''}
                <Row>
                    <Col sm={5}>
                        <h3>
                            <Button variant="light" onClick={this.handleBackOnClick}>
                                <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-arrow-return-left"
                                     fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd"
                                          d="M14.5 1.5a.5.5 0 0 1 .5.5v4.8a2.5 2.5 0 0 1-2.5 2.5H2.707l3.347 3.346a.5.5 0 0 1-.708.708l-4.2-4.2a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 8.3H12.5A1.5 1.5 0 0 0 14 6.8V2a.5.5 0 0 1 .5-.5z"/>
                                </svg>
                            </Button>
                            &nbsp;Dodawanie kontrahenta
                        </h3>
                        <Form onSubmit={this.handleSubmit}>
                            <Form.Group controlId="formBasicEmail">
                                <Form.Label>Nazwa skrócona
                                </Form.Label>
                                <Form.Control
                                    type="text" name="shortName"
                                    value={this.state.shortName}
                                    placeholder="Wpisz..."
                                    onChange={this.handleChange}
                                />
                                <Form.Label>Nazwa pełna
                                </Form.Label>
                                <Form.Control
                                    type="text" name="fullName"
                                    value={this.state.fullName}
                                    placeholder="Wpisz..."
                                    onChange={this.handleChange}
                                />
                                <Form.Label>Adres
                                </Form.Label>
                                <Form.Control
                                    type="text" name="streetAddress"
                                    value={this.state.streetAddress}
                                    placeholder="Wpisz..."
                                    onChange={this.handleChange}
                                />
                                <Form.Label>Kod pocztowy
                                </Form.Label>
                                <Form.Control
                                    type="text" name="zipCode"
                                    value={this.state.zipCode}
                                    placeholder="Wpisz..."
                                    onChange={this.handleChange}
                                />
                                <Form.Label>Miasto
                                </Form.Label>
                                <Form.Control
                                    type="text" name="city"
                                    value={this.state.city}
                                    placeholder="Wpisz..."
                                    onChange={this.handleChange}
                                />
                                <Form.Label>Województwo
                                </Form.Label>
                                <Form.Control as="select" name="province"
                                              value={this.state.province}
                                              onChange={this.handleChange}>
                                    {this.context.data.provinces.map(province =>
                                        <option id={province}>{province}</option>
                                    )}
                                </Form.Control>
                                <Form.Label>NIP
                                </Form.Label>
                                <Form.Control
                                    type="text" name="nip"
                                    value={this.state.nip}
                                    placeholder="Wpisz..."
                                    onChange={this.handleChange}
                                />
                                <Form.Label>REGON
                                </Form.Label>
                                <Form.Control
                                    type="text" name="regon"
                                    value={this.state.regon}
                                    placeholder="Wpisz..."
                                    onChange={this.handleChange}
                                />
                                <Form.Label>Telefon
                                </Form.Label>
                                <Form.Control
                                    type="text" name="phoneNumber"
                                    value={this.state.phoneNumber}
                                    placeholder="Wpisz..."
                                    onChange={this.handleChange}
                                />
                                <Form.Label>E-mail
                                </Form.Label>
                                <Form.Control
                                    style={{marginBottom: 10}}
                                    type="text" name="email"
                                    value={this.state.email}
                                    placeholder="Wpisz..."
                                    onChange={this.handleChange}
                                />
                                {this.state.validatorMessage != '' ?
                                    <Alert variant='danger'>
                                        {this.state.validatorMessage}
                                    </Alert> : false
                                }
                            </Form.Group>
                            <Button
                                variant="outline-dark"
                                type="submit"
                                size="sm"
                                style={{marginRight: "5px"}}>
                                Dodaj
                            </Button>
                            <Button
                                size="sm"
                                variant="outline-dark"
                                onClick={() => this.setState({redirect: '/customerList'})}>
                                Anuluj
                            </Button>
                        </Form>
                        {/*<Modal show={this.state.showDeleteModal} onHide={() => this.setState({showDeleteModal: false})}>*/}
                        {/*    <Modal.Header closeButton>*/}
                        {/*        <Modal.Title>Potwierdzenie</Modal.Title>*/}
                        {/*    </Modal.Header>*/}
                        {/*    <Modal.Body>Czy napewno chcesz usunąć: {this.state.shortName}</Modal.Body>*/}
                        {/*    <Modal.Footer>*/}
                        {/*        <Button variant="secondary" onClick={() => this.setState({showDeleteModal: false})}>*/}
                        {/*            Anuluj*/}
                        {/*        </Button>*/}
                        {/*        <Button variant="primary" onClick={this.handleDeleteOnClick}>*/}
                        {/*            Usuń*/}
                        {/*        </Button>*/}
                        {/*    </Modal.Footer>*/}
                        {/*</Modal>*/}
                    </Col>
                </Row>
            </div>
        )
    }
}

export default CustomerAdd;
