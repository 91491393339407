import React, {Component} from 'react';
import {AppContext} from "../../AppContext";
import {Redirect} from "react-router-dom";
import ToolkitProvider, {Search} from "react-bootstrap-table2-toolkit";
import {Button, Form, Spinner} from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import DbMachineInspection from "../../queries/DbMachineInspection";
import DbUser from "../../queries/DbUser";

const defaultSorted = [{
    dataField: 'daysToNextInspection',
    order: 'asc'
}];

const {SearchBar} = Search;

const sizePerPageRenderer = ({
                                 options,
                                 currSizePerPage,
                                 onSizePerPageChange
                             }) => (
    <div className="btn-group" role="group">
        {
            options.map((option) => {
                const isSelect = currSizePerPage === `${option.page}`;
                return (
                    <button
                        key={option.text}
                        type="button"
                        onClick={() => onSizePerPageChange(option.page)}
                        className={`btn ${isSelect ? 'btn-secondary' : 'btn-warning'}`}
                    >
                        {option.text}
                    </button>
                );
            })
        }
    </div>
);

const options = {
    sizePerPageRenderer
};


class MachineInspectionsList extends Component {
    static contextType = AppContext;

    state = {
        machines: [],
        isDataLoaded: false,
        redirect: '',
        isCheckedNotShowAfterDate: true
    }

    componentDidMount() {
        DbUser.getLoggedUser(this.context)
        DbMachineInspection.getDevicesPeriodicInspection("onlyActual", this)
    }

    handleBackOnClick = () => {
        this.setState({redirect: '/home'})
    }

    handleOnClickCheckBoxNotShowAfterDate = e => {
        if (!this.state.isCheckedNotShowAfterDate === true) {
            DbMachineInspection.getDevicesPeriodicInspection("onlyActual", this)
        } else {
            DbMachineInspection.getDevicesPeriodicInspection("all", this)
        }
        this.setState({
            ...this.state,
            isCheckedNotShowAfterDate: !this.state.isCheckedNotShowAfterDate
        })

    }

    render() {
        const onClickEvent = {

            onClick: (e, column, columnIndex, row, rowIndex) => {
                const link = `/machineEdit?id=${row.device.id}&prev=machinePeriodicInspection`
                this.setState({redirect: link})
            }
        }

        const columns = [
            {
                dataField: 'device.id',
                text: 'id',
                sort: true,
                align: 'right',
                headerAlign: 'center',
                events: onClickEvent,
            },
            {
                dataField: 'device.status',
                text: 'Status',
                sort: true,
                events: onClickEvent,
                width: 200,
                filterValue: cell => `${cell}`,
                formatter: statusFormatter,
                formatExtraData: {
                    context: this.context,
                }
            },
            {
                dataField: 'device',
                text: 'Nazwa/Kategoria',
                sort: true,
                events: onClickEvent,
                width: 200,
                filterValue: cell => `${cell.name} ${cell.category.name}`,
                formatter: cell => <>{cell.name} <br/> {cell.category.name}</>,
            },
            {
                dataField: 'device',
                text: 'Kontrahent/Adres maszyny',
                sort: true,
                events: onClickEvent,
                width: 200,
                filterValue: cell => `${cell.customer.shortName} ${cell.streetAddress} ${cell.zipCode} ${cell.city}`,
                formatter: cell => <>{cell.customer.shortName}<br/>{cell.streetAddress} {cell.zipCode} {cell.city}</>
            }
            , {
                dataField: 'device.transferDate',
                text: 'Data przekazania',
                sort: true,
                align: 'center',
                headerAlign: 'center',
                formatter: cell => `${cell!= null ? cell.substring(0,10) : false}`,
                events: onClickEvent,
                searchable: false,
            },
            {
                dataField: 'lastInspection',
                text: 'Ostatni przegląd',
                sort: true,
                align: 'center',
                headerAlign: 'center',
                events: onClickEvent,
                searchable: false,
            },
            {
                dataField: 'nextInspection',
                text: 'Następny przegląd',
                sort: true,
                align: 'center',
                headerAlign: 'center',
                events: onClickEvent,
                searchable: false,
                formatter: nextInspectionformatter,
            }, {
                dataField: 'daysToNextInspection',
                text: 'Dni do przeglądu',
                sort: true,
                align: 'center',
                headerAlign: 'center',
                events: onClickEvent,
                formatter: daysToNextInspectionFormatter,
                searchable: false,

            }, {
                dataField: 'daysToEndWarranty',
                text: 'Dni do końca gwarancji',
                sort: true,
                align: 'center',
                headerAlign: 'center',
                events: onClickEvent,
                formatter: daysToEndWarrantyFormatter,
                searchable: false,
            },
        ];

        function statusFormatter(cell, row, rowIndex, formatExtraData) {

            const statuses = formatExtraData.context.data.deviceStatus;

            let statusDiv;
            statuses.map(s => {
                if (s.name === cell) {
                    const style = {
                        width: 100,
                        backgroundColor: s.colorBackground,
                        color: s.colorText,
                        textAlign: 'center',
                        padding: '5px',
                        borderRadius: '5px',
                    }
                    const bc = s.backgroundColor
                    statusDiv = <div type="button" style={style} size="sm">{s.name}</div>
                }
                ;
            })
            return (
                statusDiv
            );
        }

        function nextInspectionformatter(cell, row, rowIndex, formatExtraData) {
            if (row.lastWarrantyInspectionDone) {
                return 'Nie dotyczy'
            } else {
                return cell;
            }
        }

        function daysToEndWarrantyFormatter(cell, row, rowIndex) {
            let out;
            if (cell < 0) {
                out = <>Brak gwarancji</>
            } else {
                out = cell
            }
            return (
                <>
                    {out}
                </>
            );
        }

        function daysToNextInspectionFormatter(cell, row, rowIndex) {
            if (row.lastWarrantyInspectionDone) {
                return 'Nie dotyczy'
            } else {
                let out;
                if (cell < 0) {
                    out = <div style={{color: 'red'}}>Po terminie<br/>{-cell} dni</div>
                } else {
                    out = cell
                }
                return (
                    <>
                        {out}
                    </>
                );
            }
        }


        return (

            <div className="fragment">
                {this.state.redirect !== '' ? <Redirect to={this.state.redirect}/> : ''}
                {this.context.redirectToLoginPage ? <Redirect to="/login"/> : ''}
                <ToolkitProvider
                    bootstrap3
                    keyField="id"
                    data={this.state.machines}
                    columns={columns}
                    defaultSorted={defaultSorted}
                    bordered={false}
                    noDataIndication=""
                    search
                >
                    {
                        props => (
                            <div>
                                <h3><Button variant="light" onClick={this.handleBackOnClick}>
                                    <svg width="1em" height="1em" viewBox="0 0 16 16"
                                         className="bi bi-arrow-return-left"
                                         fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd"
                                              d="M14.5 1.5a.5.5 0 0 1 .5.5v4.8a2.5 2.5 0 0 1-2.5 2.5H2.707l3.347 3.346a.5.5 0 0 1-.708.708l-4.2-4.2a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 8.3H12.5A1.5 1.5 0 0 0 14 6.8V2a.5.5 0 0 1 .5-.5z"/>
                                    </svg>
                                </Button>
                                    &nbsp;Przeglądy okresowe
                                </h3>

                                <SearchBar
                                    {...props.searchProps}
                                    placeholder="Wpisz aby wyszukać..."
                                />
                                <Form>
                                    <Form.Check type="checkbox" onClick={this.handleOnClickCheckBoxNotShowAfterDate}
                                                checked={this.state.isCheckedNotShowAfterDate}
                                                label="Ukryj nie przekazane maszyny oraz z wykonanym ostatnim przeglądem gwarancyjnym."/>
                                </Form>
                                <hr/>
                                <BootstrapTable
                                    bootstrap3
                                    keyField="id"
                                    data={this.state.machines}
                                    columns={columns}
                                    defaultSorted={defaultSorted}
                                    bordered={false}
                                    noDataIndication=""
                                    pagination={paginationFactory(options)}
                                    {...props.baseProps}
                                />
                            </div>
                        )
                    }
                </ToolkitProvider>
                {!this.state.isDataLoaded &&
                <div className={"container"} style={{textAlign: "center"}}><Spinner animation="border"/></div>}
            </div>
        )
    }
}

export default MachineInspectionsList;
