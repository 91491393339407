import Card from "react-bootstrap/Card";
import React, {Component} from "react";
import {AppContext} from "../../AppContext";
import {Button, Dropdown, Form, Modal} from "react-bootstrap";
import axios from "axios";
import DbHotel from "../../queries/DbHotel";
import DbUser from "../../queries/DbUser";
import {Redirect} from "react-router-dom";


class MachineEquipments extends Component {

    static contextType = AppContext;

    state = {
        isShowedModalNewParts: false,
        isShowedModalEditParts: false,
        parts: [],
        newPartName: '',
        newSerialNumber: '',
        newProducer: '',
        newDescription: '',
        idEditPart: ''
    }

    componentDidMount() {
        DbUser.getLoggedUser(this.context)

        axios.get(`${this.context.hostname}/api/part/findByDevice/${this.props.idDevice}`, {
            headers: {"X-Requested-With": "XMLHttpRequest"},
            withCredentials: true,
        }).then(res => {
            if (res.status = 200) {
                this.setState({
                    parts: res.data,
                })
            }
        })
            .catch((error) => {
                console.log(error)
            });
    }

    handleChange = (e) => {
        this.setState({[e.target.name]: e.target.value})
    }

    handleOnClickNewPart = () => {
        this.setState({
            isShowedModalNewParts: true,
            newPartName: '',
            newSerialNumber: '',
            newProducer: '',
            newDescription: '',
            idEditPart: ''
        })
    }

    handleSaveNewPart = () => {

        axios.post(`${this.context.hostname}/api/part/save`, {
                headers: {
                    "X-Requested-With": "XMLHttpRequest",
                    "Content-Type": "application/json",
                },
                "decsription": this.state.newDescription,
                "producer": this.state.newProducer,
                "serialNumber": this.state.newSerialNumber,
                "name": this.state.newPartName,
                "device": {id: this.props.idDevice}
            },
            {withCredentials: true}
        ).then(res => {
            if (res.status === 200) {
                console.log(res)
                this.componentDidMount()
                this.setState({isShowedModalNewParts: false})
            }
        })
            .catch((error) => {
                console.log({error})
                this.setState({validatorMessage: "Błąd!"})
            });
    }

    handleSaveEditPart = () => {

        axios.post(`${this.context.hostname}/api/part/save`, {
                headers: {
                    "X-Requested-With": "XMLHttpRequest",
                    "Content-Type": "application/json",
                },
                "id": this.state.idEditPart,
                "decsription": this.state.newDescription,
                "producer": this.state.newProducer,
                "serialNumber": this.state.newSerialNumber,
                "name": this.state.newPartName,
                "device": {id: this.props.idDevice}
            },
            {withCredentials: true}
        ).then(res => {
            if (res.status === 200) {
                console.log(res)
                this.componentDidMount()
                this.setState({isShowedModalEditParts: false})
            }
        })
            .catch((error) => {
                console.log({error})
                this.setState({validatorMessage: "Błąd!"})
            });
    }

    handleOnClick = e => {
        console.log(e.target)
        this.setState({
            isShowedModalEditParts: true,
            idEditPart: e.target.id,
            newPartName: e.target.name,
            newSerialNumber: e.target.getAttribute('serialnumber'),
            newProducer: e.target.getAttribute('producer'),
            newDescription: e.target.getAttribute('decsription'),
        })
    }

    handleOnClickDeletePart = (e) => {
        let idPart = e.target.id

        axios.delete(`${this.context.hostname}/api/part/delete/${idPart}`, {
            headers: {"X-Requested-With": "XMLHttpRequest"},
            withCredentials: true,
        }).then(res => {
            if (res.status === 200) {
                console.log(res)
                this.componentDidMount()
            }
        })
            .catch((error) => {
                console.log({error})
            });
    }

    render() {
        return (
            <div>
                {this.context.redirectToLoginPage ? <Redirect to="/login"/> : ''}

                <Modal show={this.state.isShowedModalNewParts}
                       onHide={() => this.setState({isShowedModalNewParts: false})}>
                    <Modal.Header closeButton>
                        <Modal.Title>Nowa część</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Label>Nazwa</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Wprowadź nazwe..."
                            value={this.state.newPartName}
                            name='newPartName'
                            onChange={this.handleChange}
                        />
                        <Form.Label>Numer seryjny</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Wprowadź numer seryjny..."
                            value={this.state.newSerialNumber}
                            name='newSerialNumber'
                            onChange={this.handleChange}
                        />
                        <Form.Label>Producent</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Wprowadź producenta..."
                            value={this.state.newProducer}
                            name='newProducer'
                            onChange={this.handleChange}
                        />
                        <Form.Label>Opis</Form.Label>
                        <Form.Control
                            type="text"
                            as="textarea"
                            rows={3}
                            placeholder="Wprowadź opis..."
                            value={this.state.newDescription}
                            name='newDescription'
                            onChange={this.handleChange}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => this.setState({isShowedModalNewParts: false})}>
                            Anuluj
                        </Button>
                        <Button variant="primary" onClick={this.handleSaveNewPart}>
                            Dodaj
                        </Button>
                    </Modal.Footer>
                </Modal>


                <Modal show={this.state.isShowedModalEditParts}
                       onHide={() => this.setState({isShowedModalEditParts: false})}>
                    <Modal.Header closeButton>
                        <Modal.Title>Nowa część</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Label>Nazwa</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Wprowadź nazwe..."
                            value={this.state.newPartName}
                            name='newPartName'
                            onChange={this.handleChange}
                        />
                        <Form.Label>Numer seryjny</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Wprowadź numer seryjny..."
                            value={this.state.newSerialNumber}
                            name='newSerialNumber'
                            onChange={this.handleChange}
                        />
                        <Form.Label>Producent</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Wprowadź producenta..."
                            value={this.state.newProducer}
                            name='newProducer'
                            onChange={this.handleChange}
                        />
                        <Form.Label>Opis</Form.Label>
                        <Form.Control
                            type="text"
                            as="textarea"
                            rows={3}
                            placeholder="Wprowadź opis..."
                            value={this.state.newDescription}
                            name='newDescription'
                            onChange={this.handleChange}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => this.setState({isShowedModalEditParts: false})}>
                            Anuluj
                        </Button>
                        <Button variant="primary" onClick={this.handleSaveEditPart}>
                            Dodaj
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Card style={{margin: '3px'}} border="white">
                    <Card.Body style={{padding: '5px', margin: '3px'}}>
                        <Button
                            className="float-end"
                            variant="outline-dark"
                            onClick={this.handleOnClickNewPart}
                        >+</Button>
                    </Card.Body>
                </Card>
                {this.state.parts.map(
                    part =>
                        <Card style={{margin: '3px'}}>
                            <Card.Body style={{padding: '5px', margin: '3px'}}>
                                <Dropdown size="sm">
                                    <Dropdown.Toggle id="dropdown-basic" size="sm" variant="light"
                                                     className='float-end' style={{backgroundColor: 'white'}}>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu size="sm">
                                        <Dropdown.Item id={part.id} size="sm"
                                                       onClick={this.handleOnClickDeletePart}>Usuń</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                                <Button
                                    style={{
                                        whiteSpace: 'pre-line',
                                        textAlign: 'left',
                                        backgroundColor: 'white',
                                        border: 'none',
                                        width: '90%'
                                    }}
                                    variant="light"
                                    size="sm"
                                    onClick={this.handleOnClick}
                                    name={part.name}
                                    serialnumber={part.serialNumber}
                                    producer={part.producer}
                                    decsription={part.decsription}
                                    id={part.id}
                                >

                                    <b>Nazwa: {part.name}</b> <br/>
                                    S/N: {part.serialNumber}<br/>
                                    Producent: {part.producer}<br/>
                                    Opis: {part.decsription}<br/></Button>

                            </Card.Body>
                        </Card>
                )}
            </div>
        )
    }
}

export default MachineEquipments
