import Card from "react-bootstrap/Card";
import React, {Component, useEffect, useState} from "react";
import {Alert, Button, Col, Container, Dropdown, Form, Modal, Row} from "react-bootstrap";
import {AppContext} from "../../AppContext";
import axios from "axios";
import DbUser from "../../queries/DbUser";
import {Link, Redirect} from "react-router-dom";
import {MapContainer, Marker, Popup, TileLayer, useMap, useMapEvents} from "react-leaflet";
import {GeoSearchControl, OpenStreetMapProvider} from "leaflet-geosearch";
import "leaflet-geosearch/dist/geosearch.css";
import * as PropTypes from "prop-types";
import L from 'leaflet';

function AddEditHotel(props) {
    return <div style={{padding: 5}}>
        <Form.Label>Nazwa
        </Form.Label>
        <Form.Control
            type="text"
            placeholder="Wprowadź nazwę."
            value={props.hotel.name}
            name="name"
            onChange={props.onChange}
        />
        <Form.Label>Ulica / nr budynku
        </Form.Label>
        <Form.Control
            type="text"
            placeholder="Wprowadź ulicę."
            value={props.hotel.streetAddress}
            name="streetAddress"
            onChange={props.onChange}
        />
        <Form.Label>Kod pocztowy
        </Form.Label>
        <Form.Control
            type="text"
            placeholder="Wprowadź kod pocztowy."
            value={props.hotel.zipCode}
            name="zipCode"
            onChange={props.onChange}
        />
        <Form.Label>Miasto
        </Form.Label>
        <Form.Control
            type="text"
            placeholder="Wprowadź miasto."
            value={props.hotel.city}
            name="city"
            onChange={props.onChange}
        />
        <Form.Label>Telefon
        </Form.Label>
        <Form.Control
            type="text"
            placeholder="Wprowadź telefon."
            value={props.hotel.phone}
            name="phone"
            onChange={props.onChange}
        />
        <Form.Label>Email
        </Form.Label>
        <Form.Control
            type="text"
            placeholder="Wprowadź email."
            value={props.hotel.email}
            name="email"
            onChange={props.onChange}
        />
        <Form.Label>Województwo
        </Form.Label>
        <Form.Control as="select" name="province"
                      value={props.hotel.province}
                      onChange={props.onChange}>
            {props.context.data.provinces.map(props.prop3
            )}
        </Form.Control>
        <Form.Label>Adres www
        </Form.Label>
        <Form.Control
            type="text"
            placeholder="Wprowadź adrew www...."
            value={props.hotel.www}
            name="www"
            onChange={props.onChange}
        />
        <Form.Label>Opis
        </Form.Label>
        <Form.Control
            type="text"
            placeholder="Wprowadź opis...."
            value={props.hotel.description}
            name="description"
            onChange={props.onChange}
        />
        <Card className="text-center" style={{marginTop: 20}}>

            <div>
                <i className="bi bi-geo-alt" style={{fontSize: 30}}></i><br/>
                {props.gpsLatitude != 0 && props.gpsLongitude != 0 ?
                    <>
                        <div style={{fontSize: 10, color: "grey"}}>szerokość :</div>
                        {props.gpsLatitude}°
                        <div style={{fontSize: 10, color: "grey"}}>długość
                            : </div>{props.gpsLongitude}°</>
                    : "Brak ustalonej pozycji"}

            </div>
        </Card>
        {props.validator !== '' ?
            <Alert variant='danger' style={{marginTop: 10}}>
                {props.validator}
            </Alert> : false
        }
        <div className="float-end" style={{margin: 10}}>
            <Button className="float-end" variant="outline-secondary" style={{width: 80}} size="sm"

                    onClick={props.onClick}>
                Anuluj
            </Button>
            <Button className="float-end" variant="outline-primary" size="sm"
                    style={{width: 80, marginRight: 10}}
                    onClick={props.onClick1}>Ok</Button>
        </div>
    </div>;
}

AddEditHotel.propTypes = {
    hotel: PropTypes.shape({
        zipCode: PropTypes.string,
        province: PropTypes.string,
        city: PropTypes.string,
        phone: PropTypes.string,
        street: PropTypes.string,
        www: PropTypes.string,
        name: PropTypes.string,
        description: PropTypes.string
    }),
    validator: PropTypes.string,
    onChange: PropTypes.func,
    context: PropTypes.any,
    prop3: PropTypes.func,
    gpsLatitude: PropTypes.number,
    gpsLongitude: PropTypes.number,
    onClick: PropTypes.func,
    onClick1: PropTypes.func
};

class MachineHotels extends Component {

    static contextType = AppContext;

    state = {
        hotels: [],
        isShowedModalNewHotel: false,
        isShowedDetailsHotel: false,
        idDevice: this.props.id,
        machineHotels: [],
        hotel: {
            id: '',
            name: '',
            street: '',
            zipCode: '',
            city: '',
            phone: '',
            email: '',
            province: '',
            description: '',
            www: '',
        },
        errorMessage: '',
        showMap: false,
        gpsLatitude: 0,
        gpsLongitude: 0,
        validator: '',
        showDeleteConfirmationModal: false
    }


    showMap = () => {
        this.setState({...this.state, showMap: true})
    }

    componentDidMount() {
        DbUser.getLoggedUser(this.context)

        axios.get(`${this.context.hostname}/api/deviceHotel/getAll`, {
            headers: {"X-Requested-With": "XMLHttpRequest"},
            withCredentials: true,
        }).then(res => {
            if (res.status = 200) {
                this.setState({
                    // ...this.state,
                    isShowedModalNewHotel: false,
                    isShowedDetailsHotel: this.state.isShowedModalNewHotel ? true : false,
                    showDeleteConfirmationModal: false,
                    gpsLatitude: 0,
                    gpsLongitude: 0,
                    hotels: res.data
                })
            }
        })
            .catch((error) => {
                console.log(error)
            });

        axios.get(`${this.context.hostname}/api/device/findById/${this.props.idDevice}`, {
            headers: {"X-Requested-With": "XMLHttpRequest"},
            withCredentials: true,
        }).then(res => {
            if (res.status = 200) {
                let lat = res.data.gpsLatitude;
                let lng = res.data.gpsLongitude
                if (res.data.gpsLatitude == null) {
                    lat = 0
                }
                if (res.data.gpsLongitude == null) {
                    lng = 0
                }

                this.setState({
                    ...this.state,
                    machine: {
                        ...res.data,
                        gpsLatitude: lat,
                        gpsLongitude: lng,
                    },
                })
            }
        })
            .catch((error) => {
                console.log(error)
            });
    }

    handleOnClickEditHotel = (hotel) => {

        this.setState({
            ...this.state,
            isShowedModalNewHotel: true,
            isShowedDetailsHotel: false,
            gpsLatitude: this.state.hotel.latitude,
            gpsLongitude: this.state.hotel.longitude,
            validator: ''
        })
    }

    handleOnClickAddNewHotel = () => {
        if (!this.state.isShowedModalNewHotel) {
            this.setState({
                ...this.state,
                isShowedModalNewHotel: true,
                isShowedDetailsHotel: false,
                gpsLatitude: 0,
                gpsLongitude: 0,
                hotel: {
                    id: '',
                    name: '',
                    street: '',
                    zipCode: '',
                    city: '',
                    phone: '',
                    email: '',
                    province: '',
                    description: '',
                    www: '',
                },
            })
        } else {
            this.setState({
                ...this.state,
                isShowedModalNewHotel: false,
                isShowedDetailsHotel: false,
                gpsLatitude: 0,
                gpsLongitude: 0,
                validator: '',
            })
        }
    }

    handleSaveNewHotel = () => {
        if (this.state.hotel.name === '') {
            this.setState({...this.state, validator: 'Wprowadź nazwę'})
        } else if (this.state.gpsLatitude === 0 && this.state.gpsLongitude === 0) {
            this.setState({...this.state, validator: 'Wybierz lokalizację na mapie'})
        } else {
            axios.post(`${this.context.hostname}/api/deviceHotel/insert`, {
                    headers: {
                        "X-Requested-With": "XMLHttpRequest",
                        "Content-Type": "application/json",
                    },
                    "id": this.state.hotel.id,
                    "name": this.state.hotel.name,
                    "streetAddress": this.state.hotel.streetAddress,
                    "zipCode": this.state.hotel.zipCode,
                    "city": this.state.hotel.city,
                    "phone": this.state.hotel.phone,
                    "province": this.state.hotel.province,
                    "description": this.state.hotel.description,
                    // "device": {id: this.props.idDevice}
                    "www": this.state.hotel.www,
                    "latitude": this.state.gpsLatitude,
                    "longitude": this.state.gpsLongitude
                },
                {withCredentials: true}
            ).then(res => {
                if (res.status === 200) {
                    this.componentDidMount()
                }
            })
                .catch((error) => {
                    console.log({error})
                    this.setState({errorMessage: "Błąd!"})
                });
        }
    }

    handleChange = (e) => {
        this.setState({
            ...this.state,
            hotel: {
                ...this.state.hotel,
                [e.target.name]: e.target.value
            }
        })
    }

    showDeleteConfirmationModal = e => {
        this.setState({...this.state, showDeleteConfirmationModal: true})
    }

    handleOnClickDeleteHotel = (e) => {
        axios.delete(`${this.context.hostname}/api/deviceHotel/deleteHotel/${this.state.hotel.id}`, {
            headers: {"X-Requested-With": "XMLHttpRequest"},
            withCredentials: true,
        }).then(res => {
            if (res.status === 200) {
                console.log(res)
                this.componentDidMount()

            }
        })
            .catch((error) => {
                console.log({error})
                let errorMessage = 'Nieznany błąd.';
                if (error.response.status === 403) {
                    errorMessage = 'Brak uprawnień do tej operacji.'
                }
                this.setState({
                    ...this.state,
                    errorMessage: errorMessage,
                })
            });
    }

    update = (nextState) => {
        this.setState(nextState);
    }

    handleCancel = () => this.setState({
        ...this.state,
        isShowedModalNewHotel: false,
        isShowedDetailsHotel: true,
    })

    handleOnClickMarker = (e, data) => {
        const hotelId = e.target.options.data.id
        const hotel = this.state.hotels.find(hotel => hotel.id === hotelId)

        this.setState({
            ...this.state,
            isShowedModalNewHotel: false,
            isShowedDetailsHotel: true,
            hotel
        })
    }


    render() {


        return (
            <div>
                {this.context.redirectToLoginPage ? <Redirect to="/login"/> : ''}

                <Card style={{margin: '3px'}} border="white">
                    <Card.Body style={{padding: '5px', margin: '3px'}}>
                        <Button
                            className="float-end"
                            variant="outline-dark"
                            onClick={this.handleOnClickAddNewHotel}
                        >+</Button>
                        <i
                            className="bi bi-arrow-clockwise float-start clickableFont"
                            onClick={() => this.componentDidMount()}
                            style={{fontSize: 18}}
                        />
                    </Card.Body>

                </Card>
                {this.state.errorMessage != '' ?
                    <Alert variant='danger' style={{marginTop: 10}}>
                        {this.state.errorMessage}
                    </Alert> : false
                }
                <Row>
                    <Col sm={8}>
                        <Card style={{marginTop: 10, width: '100%', height: '98%'}}>
                            {this.state.showMap ?
                                <MapContainer
                                    center={startPosition}
                                    zoom={6}
                                    scrollWheelZoom={true}
                                >
                                    <SearchField/>

                                    <TileLayer
                                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                    />
                                    {this.state.isShowedModalNewHotel ?
                                        <AddMarkerToClick updateParent={this.update} state={this.state}/> : false}

                                    <>
                                        {this.state.hotels.map(hotel =>
                                            <>
                                                {hotel.latitude !== null && hotel.longitude !== null ?
                                                    <Marker
                                                        key={hotel.id}
                                                        data={hotel}
                                                        position={[hotel.latitude, hotel.longitude]}
                                                        interactive={true}
                                                        eventHandlers={{
                                                            click: this.handleOnClickMarker
                                                        }}
                                                        icon={hotelMarker}
                                                    >
                                                        <Popup id={hotel.id}>{hotel.name}</Popup>
                                                    </Marker>
                                                    : false}
                                            </>
                                        )}
                                        <Marker
                                            position={[this.state.machine.gpsLatitude, this.state.machine.gpsLongitude]}
                                            interactive={true}
                                            icon={machineMarker}
                                        >
                                            <Popup id={this.state.machine.id}>{this.state.machine.name}</Popup>


                                        </Marker>
                                    </>
                                </MapContainer>
                                : false}
                        </Card>
                    </Col>
                    <Col sm={4}>
                        <Card style={{marginTop: 10, width: '100%'}}>
                            {this.state.isShowedModalNewHotel ?
                                <AddEditHotel hotel={this.state.hotel} onChange={this.handleChange}
                                              context={this.context}
                                              validator={this.state.validator}
                                              prop3={province =>
                                                  <option id={province.id}>{province}
                                                  </option>} gpsLatitude={this.state.gpsLatitude}
                                              gpsLongitude={this.state.gpsLongitude} onClick={this.handleCancel}
                                              onClick1={this.handleSaveNewHotel}/>
                                :
                                false}

                            {this.state.isShowedDetailsHotel ?
                                <>
                                    <Card.Header>

                                        Szczegóły hotelu

                                        <i className="bi bi-pencil-square float-end clickableFont"
                                           onClick={this.handleOnClickEditHotel}/>
                                        <i className="bi bi-trash float-end clickableFont"
                                           style={{marginRight: 10}}
                                           onClick={this.showDeleteConfirmationModal}/>
                                        &nbsp;<span style={{color: "lightgray"}}>({this.state.hotel.id})</span>
                                    </Card.Header>
                                    <Card.Body>
                                        <Card.Title>{this.state.hotel.name}</Card.Title>
                                        <Card.Text>
                                            {this.state.hotel.streetAddress}<br/>
                                            {this.state.hotel.zipCode} {this.state.city}<br/>
                                            {this.state.hotel.province}<br/><br/>

                                            <i className="bi bi-telephone"></i> {this.state.hotel.phone}<br/>
                                            <i className="bi bi-envelope"></i> {this.state.hotel.email}<br/>
                                            <a href={`${this.state.hotel.www != null ?
                                                this.state.hotel.www.startsWith('http://' || 'https://') ?
                                                    this.state.hotel.www
                                                    :
                                                    '//' + this.state.hotel.www
                                                : false}`} target='_blank'>{this.state.hotel.www}</a>
                                            <br/><br/>
                                            {this.state.hotel.description}<br/>
                                            <Card className="text-center" style={{marginTop: 20}}>
                                                <i className="bi bi-geo-alt" style={{fontSize: 30}}></i>
                                                {this.state.hotel.latitude != 0 && this.state.hotel.longitude != 0 ?
                                                    <>
                                                        <div style={{fontSize: 10, color: "grey"}}>długość :</div>
                                                        {this.state.hotel.latitude}°
                                                        <div style={{fontSize: 10, color: "grey"}}>szerokość
                                                            : </div>{this.state.hotel.longitude}°
                                                    </>
                                                    : "Brak ustalonej pozycji"}

                                            </Card>
                                        </Card.Text>
                                    </Card.Body></>
                                : false}

                            {!this.state.isShowedDetailsHotel && !this.state.isShowedModalNewHotel ?
                                <>
                                    <Card.Header>
                                        Hotele
                                    </Card.Header>
                                    <Card.Body>
                                        <Card.Text>
                                            Ilość hoteli : {this.state.hotels.length}
                                        </Card.Text>
                                    </Card.Body></>
                                : false}


                        </Card>
                    </Col>
                </Row>

                {this.DeleteHotelModal()}
            </div>
        );
    }

    DeleteHotelModal() {
        return <Modal show={this.state.showDeleteConfirmationModal}
                      onHide={() => this.setState({showDeleteConfirmationModal: false})}>
            <Modal.Header closeButton>
                <Modal.Title>Potwierdzenie</Modal.Title>
            </Modal.Header>
            <Modal.Body>Czy napewno chcesz usunąć hotel?</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary"
                        onClick={() => this.setState({...this.state, showDeleteConfirmationModal: false})}>
                    Anuluj
                </Button>
                <Button variant="primary" onClick={this.handleOnClickDeleteHotel}>
                    Usuń
                </Button>
            </Modal.Footer>
        </Modal>;
    }
}

export default MachineHotels;


const startPosition = [51.055207338584964, 19.1057076459545]


function AddMarkerToClick(props) {
    const [position, setPosition] = useState({
        latitude: props.state.gpsLatitude,
        longitude: props.state.gpsLongitude
    });

    const map = useMapEvents({
        click(event) {
            const {lat, lng} = event.latlng;
            setPosition({
                latitude: lat,
                longitude: lng,
            });
            let hotels = []
            if (props.state.isShowedModalNewHotel) {
                hotels = props.state.hotels.map(hotel => {
                    if (hotel.id === props.state.hotel.id) {
                        return {...hotel, latitude: lat, longitude: lng}
                    } else {
                        return hotel
                    }
                })
            }
            props.updateParent({...props.state, hotels, gpsLatitude: lat, gpsLongitude: lng})
        },
    });

    return (
        position.latitude !== 0 ? (
            <Marker
                position={[position.latitude, position.longitude]}
                interactive={false}
                icon={hotelMarker}

            ><Popup>Opis co to</Popup></Marker>
        ) : null);
}


const SearchField = ({apiKey}) => {
    const provider = new OpenStreetMapProvider({});

    const searchControl = new GeoSearchControl({
        provider: provider,
        searchLabel: 'Wpisz adres aby wyszukać...',
        style: 'bar',
    });

    const map = useMap();
    useEffect(() => {
        // map.addControl(searchControl);
        // return () => map.removeControl(searchControl);
    }, []);

    return null;
};

const hotelMarker = L.icon({
    iconUrl: require("../../../src/static/hotel_pin.svg"),
    iconSize: [40, 40],
    iconAnchor: [20, 40],
    popupAnchor: [0, -40],
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null
});

const machineMarker = L.icon({
    iconUrl: require("../../../src/static/machine_pin.svg"),
    iconSize: [40, 40],
    iconAnchor: [20, 40],
    popupAnchor: [0, -40],
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null
});