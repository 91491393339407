import React, {Component} from 'react';
import {AppContext} from "../AppContext";
import axios from "axios";

class DbMachineInspection extends Component {

    static contextType = AppContext;

    static getDevicesPeriodicInspection (filter, state)  {
        axios.get(`${state.context.hostname}/api/device/periodicInspections/`+filter, {
            headers: {"X-Requested-With": "XMLHttpRequest"},
            withCredentials: true,
        }).then(res => {
            if (res.status = 200) {
                state.setState({
                    ...state.state,
                    machines: res.data,
                    isDataLoaded: true
                })
            }
        })
            .catch((error) => {
                console.log(error)
            });
    }

    render() {
        return (
            <div>

            </div>
        );
    }
}

export default DbMachineInspection;
